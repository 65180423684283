import NavigationButton from './NavigationButton';
import styled, { css } from 'styled-components';

const IconNavigationButton = styled(NavigationButton)`
  font-size: 1.5rem;
  padding: 0;

  ${({ theme }) => css`
    border-color: ${theme.colorDisabled};
    border-radius: ${theme.radiusAvatar}px;
    height: ${theme.iconAvatar}px;
    width: ${theme.iconAvatar}px;
  `}

  svg {
    margin: 0;
    margin-top: 0.2rem;
  }
`;

export default IconNavigationButton;
