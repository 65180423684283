import { DateTime } from "luxon";
import styled, { css } from "styled-components";

import { calculateAge } from "../library-common";
import {
  Avatar,
  Row,
  RowCell,
  RowOptions,
  RowStatus,
} from "../library-components";

import { BookingStatus } from "../constants";
import useEntity from "../hooks/useEntity";
import useNavigation from "../hooks/useNavigation";

const ContainerRow = styled(Row)(({ theme, isCancelled }) => {
  const color = isCancelled ? theme.colorDisabled : "";

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const SessionBookingRow = ({ data: booking = {}, showAvatar = true }) => {
  const { entityId } = useEntity();
  const { navigate } = useNavigation();

  const {
    created,
    id: bookingId,
    status: bookingStatus = BookingStatus.PendingPayment,
    bookedFor: {
      avatar,
      dateOfBirth,
      name: bookeeName = "Unknown",
      id: bookeeId,
    } = {},
    bookedByName,
    updated,
  } = booking;

  const options = [];

  let status = RowStatus.None;
  if (bookingStatus === BookingStatus.Cancelled) status = RowStatus.Error;
  else if (bookingStatus === BookingStatus.Confirmed) status = RowStatus.Ok;

  const dt = updated || created;

  const bookerName =
    bookedByName && bookedByName !== bookeeName ? bookedByName : null;

  const navigateToUser = () => navigate(`/users/${bookeeId}`);

  return (
    <ContainerRow
      id={bookingId}
      isCancelled={bookingStatus === BookingStatus.Cancelled}
      onClick={navigateToUser}
      status={status}
      noMargins
    >
      {showAvatar ? (
        <RowCell pixelWidth={60} primary={() => <Avatar bgSrc={avatar} />} />
      ) : null}
      <RowCell
        primary={bookeeName}
        secondary={dateOfBirth ? `Age: ${calculateAge(dateOfBirth)}` : null}
        warning={bookerName && `Booked by ${bookerName}`}
        flexWidth={2}
      />
      <RowCell
        alignRight
        primary={bookingStatus.charAt(0).toUpperCase() + bookingStatus.slice(1)}
        tertiary={`${dt.toLocaleString(
          DateTime.DATE_SHORT
        )} @ ${dt.toLocaleString(DateTime.TIME_24_SIMPLE)}`}
      />
      <RowOptions entityId={entityId} options={options} />
    </ContainerRow>
  );
};

export default SessionBookingRow;
