import styled, { css } from 'styled-components';

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 1.1rem;
    width: 1.1rem;
  }
`;

const SecondaryContainer = styled.div`
  svg {
    color: #999;
    height: 0.8rem;
    width: 0.8rem;
  }
`;

const NotifyContainer = styled.div(({ theme }) => css`
  font-size: ${theme.fontSizeSmall};
  padding-top: ${theme.paddingDefault}px;
  text-decoration: none;
`);

const Icon = ({
  primary = () => null,
  secondary = () => null,
  notifyCount = 0
}) => (
  <Inner>
    {primary()}
    {notifyCount
      ? <NotifyContainer>{notifyCount}</NotifyContainer>
      : <SecondaryContainer>{secondary()}</SecondaryContainer>}
  </Inner>
);

export default Icon;
