import { DateTime } from "luxon";
import { FaTimes } from "react-icons/fa";
import { OrderStatus } from "../../constants";
import { useAppState, useEntity, useUser } from "../../hooks";
import { formatCurrency } from "../../library-common";
import {
  Icon,
  Row,
  RowCell,
  RowOptions,
  RowStatus,
  addOptionBuilder,
} from "../../library-components";
import UserOrder from "../../models/userOrder";
import { cancelOrder } from "../../userRequests";

export const Order = ({ order = {} }) => {
  const { notifyError } = useAppState();
  const { entityId } = useEntity();
  const user = useUser();

  const itemText = order.debitItemCount > 1 ? "items" : "item";
  const rowStatus =
    order.status === OrderStatus.Failed ? RowStatus.Error : RowStatus.Warn;

  const identityPayload = { orderId: order.id };

  const options = [];
  const addOption = addOptionBuilder(order, options, user);

  addOption(
    UserOrder.canCancelOrder,
    <Icon primary={FaTimes} />,
    cancelOrder(identityPayload)
  );

  return (
    <>
      <Row status={rowStatus}>
        <RowCell
          flexWidth={3}
          primary={order.created.toLocaleString(DateTime.DATETIME_SHORT)}
          secondary={[order.debitItemCount, itemText, order.status].join(" ")}
          tertiary={`ID: ${order.id}`}
        />
        <RowCell
          primary={formatCurrency(order.subTotal)}
          secondary="Total"
          alignRight
          invert
        />
        <RowCell
          primary={formatCurrency(order.net)}
          secondary="Outstanding"
          alignRight
          invert
        />
        <RowOptions
          entityId={entityId}
          options={options}
          onError={notifyError}
        />
      </Row>
    </>
  );
};
