import styled from 'styled-components';

const PageOuter = styled.div`
  display: flex;
  flex: content 0 1;
  flex-direction: column;
  overflow-y: auto;
`;

const PageInner = styled.div`
  flex: content 0 1;
`;

const Page = ({
  children,
  className,
  renderFooter = () => null,
  renderHeader = () => null
}) => (
  <>
    {renderHeader()}
    <PageOuter id="page-outer" className={className}>
      <PageInner id="page-inner">
        {children}
      </PageInner>
    </PageOuter>
    {renderFooter()}
  </>
);

export default Page;
