import { Fragment } from "react";
import { FaChevronRight, FaTimes } from "react-icons/fa";
import styled, { css } from "styled-components";
import { IconButton } from ".";
import useAppState from "../hooks/useAppState";
import useNavigation from "../hooks/useNavigation";
import { Page, Row } from "../library-components";

const MenuPage = styled(Page)(
  ({ theme }) => css`
    background: ${theme.background1};
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 10;
  `
);

const MenuRow = styled(Row)(
  ({ depth = 0, theme }) => css`
    background: ${theme.background0};
    display: flex;
    justify-content: stretch;
    padding-top: ${depth > 0 ? theme.paddingDefault : theme.paddingLarge}px;
    padding-bottom: ${depth > 0 ? theme.paddingDefault : theme.paddingLarge}px;
    padding-left: ${theme.paddingLarge}px;
    padding-right: ${theme.paddingLarge}px;
  `
);

const Divider = styled.div(
  ({ theme }) => css`
    border-bottom: ${theme.borderDefault};
    border-width: 5px;
  `
);

const ArrowContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 0 0 ${theme.buttonSizeDefault}px;
    justify-content: flex-end;
  `
);

const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    padding: ${theme.paddingSmall}px ${theme.paddingDefault}px;
  `
);

const IconContainer = styled.div(
  ({ theme }) => css`
    flex: 0 0 ${theme.buttonSizeDefault}px;
  `
);

const LabelContainer = styled.div`
  flex: auto;
`;

const Label = styled.div``;

const SubText = styled.div(
  ({ theme }) => css`
    font-size: 0.7rem;
    color: ${theme.color2};
  `
);

const FullPageMenu = ({ items = [] }) => {
  const { navigate } = useNavigation();
  const { isMenuShown, setIsMenuShown } = useAppState();

  const allItems = [...items];

  const onMenuNavigate = (link) => {
    setIsMenuShown(false);
    navigate(link);
  };

  const mapItem =
    (baseUrl = "", depth = 0) =>
    (
      {
        children = [],
        icon,
        isDivider = false,
        label,
        link,
        onNavigate,
        subText,
      },
      key
    ) => {
      const hasChildren = children.length > 0;
      const onClick = () =>
        onNavigate ? onNavigate() : onMenuNavigate(baseUrl + link);

      return isDivider ? (
        <Divider key={key} />
      ) : (
        <Fragment key={`${depth}:${key}`}>
          <MenuRow depth={depth} onClick={hasChildren ? () => null : onClick}>
            <IconContainer>{icon}</IconContainer>
            <LabelContainer>
              <Label>{label}</Label>
              {subText && <SubText>{subText}</SubText>}
            </LabelContainer>
            <ArrowContainer>
              {hasChildren ? null : <FaChevronRight />}
            </ArrowContainer>
          </MenuRow>
          {children.map(mapItem(baseUrl + link, depth + 1))}
        </Fragment>
      );
    };

  return (
    <MenuPage>
      <Header>
        <IconButton
          icon={<FaTimes />}
          onClick={() => setIsMenuShown(!isMenuShown)}
        />
      </Header>
      {allItems.map(mapItem())}
    </MenuPage>
  );
};

export default FullPageMenu;
