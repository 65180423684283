import styled, { css } from 'styled-components';
import { Panel, Row, RowCell } from '../../library-components';
import { formatCurrency } from '../../library-common';

const Total = styled.h1(({ theme }) => css`
  font-weight: bold;
  font-size: ${theme.fontSizeXLarge};
`);

const BasketTotalsPayNow = ({ complexCost }) => {
  const { itemCount, lineCount, paynow } = complexCost;

  return (
    <Panel title="Totals">
      <Row>
        <RowCell
          secondary={`You have ${itemCount} items in your basket`}
          warning={itemCount !== lineCount ? `${lineCount} different lines` : null}
        />
        <RowCell
          alignRight
          pixelWidth={80}
          primary={() => <Total>{formatCurrency(paynow)}</Total>}
          secondary="to pay"
        />
      </Row>
    </Panel>
  )
};

export default BasketTotalsPayNow;
