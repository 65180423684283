import { collectionGroup, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

import { Collections, Operators, Properties } from "../constants";
import userOrderModel from "../models/userOrder";
import useEntity from "./useEntity";
import useFirestoreCollection from "./useFirestoreCollection";

export const DefaultDailyTotals = {
  actual: 0,
  forecast: 0,
  overdue: 0,
  fees: 0,
  total: 0,
};

const dailyTotalReducer = (acc, order) => {
  const {
    accountingDate,
    accountingDateISO,
    isComplete,
    isScheduled,
    fees: orderFee = 0,
    payments,
    subTotal,
  } = order;

  const day = acc[accountingDateISO] || {
    ...DefaultDailyTotals,
    accountingDateISO,
    accountingDate,
  };

  const { actual, fees, forecast, overdue, total } = day;

  const net = payments - orderFee;

  return {
    ...acc,
    [accountingDateISO]: {
      ...day,
      actual: isComplete ? actual + net : actual,
      fees: fees + orderFee,
      forecast: isScheduled ? forecast + net : forecast,
      overdue: !isComplete ? overdue + (subTotal - payments) : overdue,
      total: total + payments,
    },
  };
};

const useMonthlySales = () => {
  const { entityId } = useEntity();

  const monthAgo = useMemo(
    () => DateTime.utc().startOf("day").minus({ days: 32 }).toISO(),
    []
  );

  const hydrator = useCallback((raw) => userOrderModel.hydrate(raw), []);

  const paidRefProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.Orders),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.Created, Operators.GreaterThan, monthAgo)
      ),
    [entityId, monthAgo]
  );

  /*const scheduledRefProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.Orders),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.Scheduled, Operators.GreaterThan, monthAgo)
      ),
    [entityId, monthAgo]
  );*/

  const paidOrders = useFirestoreCollection(paidRefProvider, hydrator);
  /*const scheduledOrders = useFirestoreCollection(
    scheduledRefProvider,
    hydrator
  );*/

  const orders = Object.values({
    ...paidOrders.reduce((acc, o) => ({ ...acc, [o.id]: o }), {}),
    //...scheduledOrders.reduce((acc, o) => ({ ...acc, [o.id]: o }), {}),
  });

  const dailyTotals = orders.reduce(dailyTotalReducer, {});
  Object.values(dailyTotals).forEach(userOrderModel.hydrateDailyTotal);

  const reportData = { orders, dailyTotals };

  return reportData;
};

export default useMonthlySales;
