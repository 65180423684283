import { DateTime } from 'luxon';

import commonModel from '../common2';

const hydrate = raw => {
    const hydrated = commonModel.hydrate(raw);

    const { accountingDate } = raw;
    hydrated.accountingDateFormatted = accountingDate
        .toLocaleString(DateTime.DATE_FULL);

    return hydrated;
};

export default hydrate;