import styled, { css } from 'styled-components';
import Button from './Button';
import Heading from './Heading';
import Panel from './Panel';
import Paragraph from './Paragraph';

const Container = styled.div(({ visible }) => css`
  display: ${visible ? 'block' : 'none'};
`);

const Mask = styled.div(({ theme }) => css`
  background-color: ${theme.colorMask};
  flex: 1;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 100;
`);

const Content = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100vh;
  max-width: ${theme.maxWidth}px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 101;
`);

const ModalMessage = (props) => {
  const {
    message,
    lines = [],
    onClose = () => null,
    title,
    visible = true
  } = props;

  const allMessages = [
    ...lines,
    message
  ];

  const onCloseClicked = () => {
    onClose();
  };

  return (
    <Container visible={visible}>
      <Mask />
      <Content>
        <Panel>
          {title && <Heading>{title}</Heading>}
          {allMessages.map((msg, key) => <Paragraph key={key}>{msg}</Paragraph>)}
          <Button label="Close" onClick={onCloseClicked} />
        </Panel>
      </Content>
    </Container>
  );
};

export default ModalMessage;
