import { FaTimes } from "react-icons/fa";
import styled, { css } from "styled-components";
import useUser from "../../hooks/useUser";
import { addOptionBuilder, Icon } from "../../library-components";
import { cancelPurchase } from "../../userRequests";
import BasketItemRow from "./BasketItemRow";

const CreditRow = styled(BasketItemRow)(
  ({ theme }) => css`
    color: ${theme.colorPrimary};
    background: ${theme.background0light};
  `
);

const BasketItemAccountRow = (props) => {
  const user = useUser();

  const { data = {} } = props;
  const { id: orderItemId, isAvailable } = data;

  const identityPayload = { orderItemId };

  const options = [];
  const addOption = addOptionBuilder(data, options, user);
  addOption(
    () => true,
    <Icon primary={FaTimes} />,
    cancelPurchase(identityPayload)
  );

  return (
    <CreditRow {...props} options={options} unavailable={!isAvailable()} />
  );
};

export default BasketItemAccountRow;
