import styled, { css } from 'styled-components';
import { Panel, Row, RowCell } from '../../library-components';
import { formatCurrency } from '../../library-common';

const Total = styled.h1(({ theme }) => css`
  font-weight: bold;
  font-size: ${theme.fontSizeXLarge};
`);

const BasketTotalsFuturePayments = ({ complexCost }) => {
  const { futurePayments } = complexCost;

  return (
    <Panel title="Recurring Payments">
      {futurePayments.map(({
        cost,
        costCadence,
        costCadenceCount,
        costCadenceUnit
      }, key) => (
        <Row key={key}>
          <RowCell
            primary={`${costCadenceCount} payments of ${formatCurrency(cost)}`}
            secondary={`every ${costCadence} ${costCadenceUnit}`}
          />
          <RowCell
            alignRight
            pixelWidth={80}
            primary={() => <Total>{formatCurrency(cost * costCadenceCount)}</Total>}
            secondary="total payable"
          />
        </Row>
      ))}
    </Panel>
  );
};

export default BasketTotalsFuturePayments;
