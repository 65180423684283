import { validateName, validateString } from "../../library-common";
import {
  Form,
  NumberField,
  Panel,
  SideBySide,
  SingleLineField,
} from "../../library-components";

const SessionInformationPanel = ({
  session = {},
  isBusy = false,
  onUpdateField = () => null,
}) => {
  const { capacity, coach, name } = session;

  return (
    <Panel title="Information">
      <Form>
        <SingleLineField
          label="Type or Name"
          validator={validateString(2)}
          value={name}
          disabled={isBusy}
          onChange={onUpdateField("name")}
          required
        />
        <SideBySide fitContent>
          <SingleLineField
            label="Coach"
            validator={validateName}
            value={coach}
            disabled={isBusy}
            onChange={onUpdateField("coach")}
          />
          <NumberField
            label="Capacity"
            min={1}
            value={capacity}
            disabled={isBusy}
            onChange={onUpdateField("capacity")}
            required
          />
        </SideBySide>
      </Form>
    </Panel>
  );
};

export default SessionInformationPanel;
