import styled, { css } from 'styled-components';

const SideBySide = styled.div(({ theme, fitContent = false }) => css`
  display: flex;
  justify-content: ${fitContent ? `stretch` : `space-between`};

  button {
    flex: 1;
  }

  & > * + * {
    margin-top: 0;
    margin-left: ${theme.marginDefault}px;
  }

  & + & {
    margin-top: ${theme.marginDefault}px;
  }
`);

export default SideBySide;
