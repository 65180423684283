import {
  collection,
  collectionGroup,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { createContext, useEffect, useMemo, useReducer } from "react";

import { Collections, TimetabledEventType } from "../../constants";
import useAppState from "../../hooks/useAppState";
import useConfig from "../../hooks/useConfig";
import useUser, { isUserAdmin } from "../../hooks/useUser";
import { hydrateBundle } from "../../models/bundle";
import { hydrateClass } from "../../models/class";
import { getPastCutOff, getReportingCutOff } from "../../models/common";
import { hydrateEntity } from "../../models/entity";
import { hydrateOrder } from "../../models/order";
import { hydrateSession } from "../../models/session";
import {
  hydrateBasketItem,
  hydrateBundleAssignment,
  hydrateUserAccess,
} from "../../models/user";
import mapCollection from "../../tools/mapCollection";
import reducer, { Actions, initialState } from "./reducer";

export const EntityContext = createContext();

const EntityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { wrappedNotifyError } = useAppState();
  const { entityId } = useConfig();
  const user = useUser();

  const update = (type) => (payload) => dispatch({ type, payload });

  const isAdminUser = useMemo(
    () => user.userId && isUserAdmin(user, entityId),
    [entityId, user]
  );

  // List all of the entities that are enabled and discoverable
  useEffect(() => {
    const entitySorter = (a, b) =>
      a.settings.name.localeCompare(b.settings.name);

    const unsubscribers = [];
    const db = getFirestore();

    // subscribe to available entities
    unsubscribers.push(
      onSnapshot(
        query(
          collection(db, Collections.Entities),
          where("isEnabled", "==", true),
          where("settings.isDiscoverable", "==", true)
        ),
        mapCollection(
          update(Actions.UpdateEntities),
          hydrateEntity,
          entitySorter
        ),
        wrappedNotifyError("List all entities")
      )
    );

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [wrappedNotifyError]);

  // Load all of the entity data e.g. classes, sessions etc
  useEffect(() => {
    const bundleSorter = (a, b) => (b.name || "").localeCompare(a.name);
    const classSorter = (a, b) => a.sortOn.localeCompare(b.sortOn);
    const orderSorter = (a, b) => b.created.diff(a.created);
    const sessionSorter = (a, b) => a.starts.diff(b.starts);

    let readyFlags = {};
    const trackedMapCollection = (key, updater, hydrator, sorter) => {
      readyFlags = { ...readyFlags, [key]: true };

      const userList = ["bundles", "sessions"];
      const adminList = [
        ...userList,
        "orders",
        "entityUsers",
        "classes",
        "basketItems",
        "bundleAssignments",
      ];

      const flagReducer = (acc, key) => acc && readyFlags[key];
      const isReady = isAdminUser
        ? adminList.reduce(flagReducer, true)
        : userList.reduce(flagReducer, true);

      if (isReady) {
        update(Actions.UpdateReady)();
      }

      return mapCollection(updater, hydrator, sorter);
    };

    const unsubscribers = [];

    // if we have an entity
    if (entityId) {
      const db = getFirestore();
      const entityRef = doc(db, Collections.Entities, entityId);

      const pastCutOffISO = getPastCutOff().toISO();
      const reportingCutOffISO = getReportingCutOff().toISO();

      // subscribe to current entity's bundles
      unsubscribers.push(
        onSnapshot(
          collection(entityRef, Collections.Bundles),
          trackedMapCollection(
            "bundles",
            update(Actions.UpdateBundles),
            hydrateBundle,
            bundleSorter
          ),
          wrappedNotifyError("Read all entity bundles")
        )
      );

      // subscribe to current entity's sessions
      unsubscribers.push(
        onSnapshot(
          query(
            collection(entityRef, Collections.Sessions),
            where("starts", ">=", pastCutOffISO)
          ),
          trackedMapCollection(
            "sessions",
            update(Actions.UpdateSessions),
            hydrateSession,
            sessionSorter
          ),
          wrappedNotifyError("Read all entity sessions")
        )
      );

      // Some of these requests require an admin user to be signed in
      if (isAdminUser) {
        const pastCutOffISO = getPastCutOff().toISO();

        // subscribe to current entity's timetable
        unsubscribers.push(
          onSnapshot(
            query(
              collection(entityRef, Collections.TimetabledEvents),
              where("type", "==", TimetabledEventType.Class)
            ),
            trackedMapCollection(
              "classes",
              update(Actions.UpdateClasses),
              hydrateClass,
              classSorter
            ),
            wrappedNotifyError("Read all entity classes")
          )
        );

        // subscribe to current entity's users
        unsubscribers.push(
          onSnapshot(
            query(
              collection(entityRef, Collections.UserAccess),
              orderBy("lastAccess", "desc")
            ),
            trackedMapCollection(
              "entityUsers",
              update(Actions.UpdateEntityUsers),
              hydrateUserAccess
            ),
            wrappedNotifyError("Read all entity user access")
          )
        );

        // subscribe to current entity's orders
        unsubscribers.push(
          onSnapshot(
            query(
              collectionGroup(db, Collections.Orders),
              where("entityId", "==", entityId),
              where("created", ">=", reportingCutOffISO)
            ),
            trackedMapCollection(
              "orders",
              update(Actions.UpdateOrders),
              hydrateOrder,
              orderSorter
            ),
            wrappedNotifyError("Read all entity orders")
          )
        );

        // subscribe to all basketItems for this entity
        unsubscribers.push(
          onSnapshot(
            query(
              collectionGroup(db, Collections.OrderItems),
              where("entityId", "==", entityId),
              where("orderId", "==", null)
            ),
            trackedMapCollection(
              "basketItems",
              update(Actions.UpdateBasketItems),
              hydrateBasketItem,
              null,
              { 1: "inBasketOf" }
            ),
            wrappedNotifyError("Read all entity basket items")
          )
        );

        // subscribe to all recent memberships for this entity
        unsubscribers.push(
          onSnapshot(
            query(
              collectionGroup(db, Collections.BundleAssignments),
              where("entityId", "==", entityId),
              where("expires", ">", pastCutOffISO)
            ),
            trackedMapCollection(
              "bundleAssignments",
              update(Actions.UpdateBundleAssignments),
              hydrateBundleAssignment,
              null,
              { 1: "assignedTo" }
            ),
            wrappedNotifyError("Read all entity bundle assignments")
          )
        );
      }
    } else {
      dispatch({ type: Actions.UpdateReady });
    }

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [entityId, wrappedNotifyError, isAdminUser, state.isReady]);

  return (
    <EntityContext.Provider value={[state, dispatch]}>
      {children}
    </EntityContext.Provider>
  );
};

export default EntityProvider;
