import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { formatCurrency } from "../../library-common";
import { Panel, Row, RowCell } from "../../library-components";
import SalesOrderRow from "./SalesOrderRow";

const HeaderRow = styled(Row)`
  margin-bottom: 10px;
`;

const NetRowCell = styled(RowCell)(
  ({ theme }) => css`
    color: ${theme.colorOk};
    font-weight: bold;
  `
);

const SalesToday = ({ data }) => {
  const todayISO = DateTime.local().toISODate();

  const todaysOrders = data.filter(
    ({ accountingDateISO }) => accountingDateISO === todayISO
  );

  const { value, fees, paid } = todaysOrders.reduce(
    (acc, { subTotal, fees, payments }) => ({
      value: acc.value + subTotal,
      fees: acc.fees + fees,
      paid: acc.paid + payments,
    }),
    { value: 0, fees: 0, paid: 0 }
  );

  return (
    <Panel title={`Today's Orders (${todayISO})`}>
      <HeaderRow>
        <RowCell
          primary={formatCurrency(value)}
          secondary="Value"
          invert
          alignCenter
        />
        <RowCell
          primary={formatCurrency(paid)}
          secondary="Paid"
          invert
          alignCenter
        />
        <RowCell
          primary={formatCurrency(fees)}
          secondary="Fees"
          invert
          alignCenter
        />
        <NetRowCell
          primary={formatCurrency(paid - fees)}
          secondary="Net"
          invert
          alignCenter
        />
      </HeaderRow>
      {todaysOrders.map((order, key) => (
        <SalesOrderRow key={key} data={order} />
      ))}
    </Panel>
  );
};

export default SalesToday;
