import { FaShoppingCart, FaUser } from "react-icons/fa";
import styled, { css } from "styled-components";
import { OrderItemType } from "../constants";
import useEntity from "../hooks/useEntity";
import useUser, { isUserAdmin } from "../hooks/useUser";

const Container = styled.div(
  ({ onlyCapacity }) => css`
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: "space-between";
  `
);

const SubStatus = styled.div(
  ({ isWarning, theme }) => css`
    align-items: center;
    color: ${isWarning ? theme.colorWarn : "inherit"};
    display: flex;

    & > span {
      padding-left: ${theme.paddingXSmall}px;
    }
  `
);

const SessionBookingsStatus = ({
  data: session = {},
  onlyCapacity = false,
}) => {
  const { basketItems: allBasketItems, entityId } = useEntity();
  const user = useUser();
  const { capacity, bookings = {}, id: sessionId } = session;
  const { confirmedIds = [] } = bookings;

  const isAdmin = isUserAdmin(user, entityId);
  const basketItems = allBasketItems.filter(
    ({ contextType, contextId }) =>
      contextType === OrderItemType.Session && contextId === sessionId
  );

  return (
    <Container onlyCapacity={onlyCapacity}>
      {isAdmin && !onlyCapacity && (
        <SubStatus isWarning={basketItems.length}>
          <FaShoppingCart />
          <span>{basketItems.length}</span>
        </SubStatus>
      )}
      <SubStatus>
        <FaUser />
        {!onlyCapacity ? (
          <>
            <span>{confirmedIds.length}</span>
            <span>of</span>
          </>
        ) : null}
        <span>{capacity}</span>
      </SubStatus>
    </Container>
  );
};

export default SessionBookingsStatus;
