import { UserMembershipRow } from "../../components";
import { Properties } from "../../constants";
import { useMemberships } from "../../hooks";
import { Page, Panel } from "../../library-components";
import { groupBy } from "../../tools";

const ReportsMemberships = () => {
  const memberships = groupBy(useMemberships(), Properties.BundleId, [
    Properties.Name,
  ]);

  return (
    <Page>
      {Object.values(memberships).map(({ key, name, children }) => (
        <Panel key={key} title={`${name} (${children.length})`}>
          {children.map((membership, subKey) => (
            <UserMembershipRow key={subKey} data={membership} />
          ))}
        </Panel>
      ))}
    </Page>
  );
};

export default ReportsMemberships;
