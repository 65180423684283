import styled, { css } from "styled-components";

import { OrderItemType } from "../../constants";
import BasketItemAccountRow from "./BasketItemAccountRow";
import BasketItemBundleRow from "./BasketItemBundleRow";
import BasketItemOrderRow from "./BasketItemOrderRow";
import BasketItemOtherRow from "./BasketItemOtherRow";
import BasketItemPaymentRow from "./BasketItemPaymentRow";
import BasketItemScheduledPaymentRow from "./BasketItemScheduledPaymentRow";
import BasketItemSessionRow from "./BasketItemSessionRow";

const Centered = styled.div(
  ({ theme }) => css`
    color: ${theme.colorDimmed2};
    padding: ${theme.paddingDefault}px;
    text-align: center;
  `
);

const rowComponents = {
  [OrderItemType.AccountTransaction]: BasketItemAccountRow,
  [OrderItemType.Bundle]: BasketItemBundleRow,
  [OrderItemType.Other]: BasketItemOtherRow,
  [OrderItemType.Payment]: BasketItemPaymentRow,
  [OrderItemType.ScheduledPayment]: BasketItemScheduledPaymentRow,
  [OrderItemType.Session]: BasketItemSessionRow,
};

const defaultIsAvailable = () => true;

export const BasketItemList = ({ items = [] }) => {
  const enrichmentMapper = (item) => {
    const { cost } = item;
    const enriched = { ...item };

    if (typeof cost === "function") {
      enriched.cost = cost(items);
    }

    enriched.isAvailable = item.isAvailable || defaultIsAvailable;

    return enriched;
  };

  const componentMapper = (item) => {
    const ItemRow = rowComponents[item.contextType];

    return item.orderId ? (
      <>
        <ItemRow data={item} key={item.id} />
        <BasketItemOrderRow data={item} key={item.id + "_order"} />
      </>
    ) : (
      <ItemRow data={item} key={item.id} />
    );
  };

  const isEmpty = items.length < 1;

  return isEmpty ? (
    <Centered>There are no items in your basket.</Centered>
  ) : (
    items.map(enrichmentMapper).map(componentMapper)
  );
};
