import { SalesChart, SalesTotals } from "../../components/reportSales";
import useMonthlySales from "../../hooks/useMonthlySales";
import { Page } from "../../library-components";

const ReportsMonth = () => {
  const monthlySales = useMonthlySales();
  const { dailyTotals } = monthlySales;

  return (
    <Page>
      <SalesChart data={dailyTotals} />
      <SalesTotals data={dailyTotals} />
    </Page>
  );
};

export default ReportsMonth;
