import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { useEffect, useState } from "react";
import {
  Collections,
  MaxActivityRows,
  Operators,
  OrderByDirection,
  Properties,
} from "../constants";

const useUserActivity = (userId = null, entityId = null) => {
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    if (entityId) {
      const db = getFirestore();
      const activityQuery = query(
        collection(db, Collections.Users, userId, Collections.UserRequests),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        orderBy(Properties.Created, OrderByDirection.Desc),
        limit(MaxActivityRows)
      );

      const unsubscribe = onSnapshot(activityQuery, (snapshot) => {
        const rawActivity = snapshot.docs.map((doc) => doc.data());
        setActivity(rawActivity);
      });

      return () => unsubscribe();
    }
  }, [entityId, userId]);

  return activity;
};

export default useUserActivity;
