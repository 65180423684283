import {
  FaCalendarCheck,
  FaClock,
  FaIdCard,
  FaMoneyBill,
  FaMoneyCheckAlt,
  FaQuestion,
} from "react-icons/fa";
import styled, { css } from "styled-components";
import { OrderItemType } from "../../constants";
import useEntity from "../../hooks/useEntity";
import { formatCurrency } from "../../library-common";
import { Icon, Row, RowCell, RowOptions } from "../../library-components";

const ContainerRow = styled(Row)(
  ({ disabled, theme }) => css`
    color: ${disabled ? theme.colorDimmed1 : null};
    justify-content: space-between;

    & div {
      color: ${disabled ? theme.colorDimmed1 : null};
    }
  `
);

const contextIcons = {
  [OrderItemType.Bundle]: <Icon primary={FaIdCard} />,
  [OrderItemType.Session]: <Icon primary={FaCalendarCheck} />,
  [OrderItemType.Payment]: <Icon primary={FaMoneyCheckAlt} />,
  [OrderItemType.AccountTransaction]: <Icon primary={FaMoneyBill} />,
  [OrderItemType.ScheduledPayment]: <Icon primary={FaClock} />,
};

const BasketItemRow = ({
  className,
  data: item = {},
  notes = null,
  options = [],
  unavailable = false,
}) => {
  const {
    contextType,
    cost,
    costEach,
    description,
    id: itemId,
    quantity,
  } = item;

  const { entityId } = useEntity();

  return (
    <ContainerRow
      className={className}
      id={itemId}
      disabled={unavailable}
      noMargins
    >
      <RowCell
        pixelWidth={30}
        primary={() =>
          contextIcons[contextType] || <Icon primary={FaQuestion} />
        }
      />
      <RowCell
        secondary={description}
        warning={unavailable ? "This item is no longer available" : notes}
      />
      <RowCell
        alignRight
        pixelWidth={80}
        primary={formatCurrency(cost, true)}
        secondary={
          quantity != null
            ? `${quantity} x ${formatCurrency(costEach, true)}`
            : null
        }
      />
      <RowOptions entityId={entityId} options={options} />
    </ContainerRow>
  );
};

export default BasketItemRow;
