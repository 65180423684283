import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Collections, OrderStatus } from "../constants";
import { hydrateOrder } from "../models/order";
import { hydrateBasketItem } from "../models/user";
import mapCollection from "../tools/mapCollection";
import useAppState from "./useAppState";
import useUser from "./useUser";

const basketItemSorter = (a, b) => a.createdISO.localeCompare(b.createdISO);
const orderSorter = (a, b) => b.createdISO.localeCompare(a.createdISO);

const useBasket = () => {
  const { wrappedNotifyError } = useAppState();
  const { userId } = useUser();

  const [basketItems, setBasketItems] = useState([]);
  const [incompleteOrders, setIncompleteOrders] = useState([]);

  useEffect(() => {
    if (!userId) return;

    const unsubscribers = [];

    const db = getFirestore();
    const userRef = doc(db, Collections.Users, userId);

    unsubscribers.push(
      onSnapshot(
        query(
          collection(userRef, Collections.OrderItems),
          where("orderId", "==", null)
        ),
        mapCollection(setBasketItems, hydrateBasketItem, basketItemSorter),
        wrappedNotifyError("useBasket list items")
      )
    );

    unsubscribers.push(
      onSnapshot(
        query(
          collection(userRef, Collections.Orders),
          where("status", "not-in", [
            OrderStatus.Complete,
            OrderStatus.Cancelled,
            OrderStatus.Scheduled,
          ])
        ),
        mapCollection(setIncompleteOrders, hydrateOrder, orderSorter),
        wrappedNotifyError("useBasket list orders")
      )
    );

    return () => unsubscribers.forEach((unsubscribe) => unsubscribe());
  }, [userId, wrappedNotifyError]);

  return {
    basketItems,
    incompleteOrders,
  };
};

export default useBasket;
