import useEntity from '../hooks/useEntity';

const HasEntity = ({ children, entityId }) => {
  const { current } = useEntity(entityId);
  const entity = current || {};

  const { id } = entity;

  return id ? children : null;
}

export default HasEntity;
