import styled, { css } from "styled-components";

export const RowStatus = {
  Undefined: "undefined",
  None: "none",
  Ok: "ok",
  OkDimmed: "okdimmed",
  Info: "info",
  InfoDimmed: "infodimmed",
  Warn: "warn",
  WarnDimmed: "warndimmed",
  Error: "error",
  ErrorDimmed: "errordimmed",
  Dimmed: "dimmed",
  Disabled: "disabled",
};

export const statusColor = (theme, status) => {
  const statusColors = {
    [RowStatus.Undefined]: theme.background0,
    [RowStatus.None]: theme.color0,
    [RowStatus.Ok]: theme.colorOk,
    [RowStatus.OkDimmed]: theme.colorOkBg,
    [RowStatus.Info]: theme.colorNeutral,
    [RowStatus.InfoDimmed]: theme.colorNeutralBg,
    [RowStatus.Warn]: theme.colorWarn,
    [RowStatus.WarnDimmed]: theme.colorWarnBg,
    [RowStatus.Error]: theme.colorError,
    [RowStatus.ErrorDimmed]: theme.colorErrorBg,
    [RowStatus.Dimmed]: theme.colorDimmed1,
    [RowStatus.Disabled]: theme.colorDisabled,
  };

  return status && statusColors[status];
};

const Container = styled.div(({ isSlim, status, theme }) => {
  const statusBorder = `solid 5px ${statusColor(theme, status)}`;

  return css`
    align-items: center;
    align-self: stretch;
    border-left: ${status ? statusBorder : "none"};
    display: flex;
    flex: 1;
    max-height: ${isSlim ? theme.slimRowHeight + "px" : "none"};
    overflow-y: hidden;

    & + & {
      border-top: ${theme.borderDefault};
    }

    & > div:first-child {
      padding-left: ${theme.paddingSmall}px;
    }

    & > div:last-child {
      padding-right: ${theme.paddingSmall}px;
    }
  `;
});

const Row = (props) => {
  const {
    children,
    className,
    id,
    isSlim = false,
    noMargins = false,
    status,
    onClick = () => null,
  } = props;

  return (
    <Container
      className={className}
      id={id}
      isSlim={isSlim}
      onClick={onClick}
      noMargins={noMargins}
      status={status}
    >
      {children}
    </Container>
  );
};

export default Row;
