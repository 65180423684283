import { Form, NumberField, Panel, SideBySide, ToggleField } from '../../library-components';

const RestrictionsPanel = ({
  data = {},
  isBusy = false,
  onUpdateField = () => null
}) => {
  const { isAgeRestricted = false, minAge, maxAge } = data;

  return (
    <Panel title="Restrictions">
      <Form>
        <ToggleField
          label="Restricted by Age"
          value={isAgeRestricted}
          disabled={isBusy}
          onChange={onUpdateField('isAgeRestricted')}
          required
        />
        {isAgeRestricted ? (
          <SideBySide fitContent>
            <NumberField
              label="Min.Age"
              min={1}
              max={maxAge}
              value={minAge}
              disabled={isBusy}
              onChange={onUpdateField('minAge')}
              required
            />
            <NumberField
              label="Max. Age"
              min={minAge}
              value={maxAge}
              disabled={isBusy}
              onChange={onUpdateField('maxAge')}
            />
          </SideBySide>
        ) : null}
      </Form>
    </Panel>
  );
};

export default RestrictionsPanel;
