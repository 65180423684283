import styled, { css } from "styled-components";
import { EntityTitlePanel, NavigationButton } from ".";
import useUser from "../hooks/useUser";
import { Panel } from "../library-components";

const SpacedPanel = styled(Panel)(
  ({ theme }) => css`
    * + * {
      margin-top: ${theme.marginLarge}px;
    }
  `
);

const NoUserPanel = () => {
  const { userId } = useUser();

  return userId ? null : (
    <>
      <EntityTitlePanel />
      <SpacedPanel title="Welcome">
        <p>
          If you want to book or join, you'll need to 'Sign Up' first. If you've
          already signed up, you can 'Sign In' below.
        </p>
        <p>
          To view the memberships and sessions available you can use the menu at
          the base of the App or the buttons below.
        </p>
        <NavigationButton isPrimary link="/sessions" label="View Sessions" />
        <NavigationButton link="/bundles" label="View Memberships" />
      </SpacedPanel>
    </>
  );
};

export default NoUserPanel;
