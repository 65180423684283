import { FaTimes } from "react-icons/fa";
import useUser from "../../hooks/useUser";
import { addOptionBuilder, Icon } from "../../library-components";
import { cancelPurchase } from "../../userRequests";
import BasketItemRow from "./BasketItemRow";

const BasketItemScheduledPaymentRow = (props) => {
  const user = useUser();

  const { data = {} } = props;
  const { id: orderItemId } = data;

  const identityPayload = { orderItemId };

  const options = [];
  const addOption = addOptionBuilder(data, options, user);
  addOption(
    () => true,
    <Icon primary={FaTimes} />,
    cancelPurchase(identityPayload)
  );

  return <BasketItemRow {...props} options={options} />;
};

export default BasketItemScheduledPaymentRow;
