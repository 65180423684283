import { EntityLogo, EntityName } from '.';
import { Panel } from '../library-components';

const EntityTitlePanel = () => (
  <Panel isCentered isBorderless>
    <EntityLogo isRounded />
    <EntityName />
  </Panel>
);

export default EntityTitlePanel;
