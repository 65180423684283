const cancellablePromise = (asyncFunc, onResult, onError) => {
  let cancelled = false;

  asyncFunc()
    .then(result => {
      if (!cancelled) {
        onResult(result);
      }
    })
    .catch(onError);

  return () => {
    cancelled = true;
  };
};

export default cancellablePromise;