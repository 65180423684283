import { Page } from '../../library-components';
import { EntityTitlePanel } from '../../components';
import SignInWithIdpPanel from './SignInWithIdpPanel';
import SignInWithPasswordPanel from './SignInWithPasswordPanel';
import useNavigation from '../../hooks/useNavigation';
import useUser from '../../hooks/useUser';

const SignIn = () => {
  const { userId } = useUser();
  const { back } = useNavigation();

  if (userId) back();

  return (
    <Page>
      <EntityTitlePanel />
      <SignInWithPasswordPanel />
      <SignInWithIdpPanel />
    </Page>
  );
};

export default SignIn;
