const dehydrate = hydrated => {
    if (!hydrated) return hydrated;

    const raw = { ...hydrated };

    if (raw.createdISO) {
        raw.created = hydrated.created.toISO();
        delete raw.createdISO;
    }

    if (raw.lastUpdatedISO) {
        raw.lastUpdated = hydrated.lastUpdated.toISO();
        delete raw.lastUpdatedISO;
    }

    return raw;
};

export default dehydrate;