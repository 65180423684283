import { DateTime } from 'luxon';
import SingleLineField, { RawFieldType } from './SingleLineField';

const TimeField = (props) => {
  const { defaultValue, onChange = () => null } = props;

  const defaultTime = defaultValue && defaultValue.toFormat('HH:mm');
  const onTimeChange = (newValue, result) => {
    const dt = DateTime.fromISO(newValue);
    onChange(dt, result);
  };

  return (
    <SingleLineField
      {...props}
      defaultValue={defaultTime}
      onChange={onTimeChange}
      rawType={RawFieldType.Time}
    />
  );
};
export default TimeField;
