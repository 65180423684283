import styled, { css } from 'styled-components';
import { formatDate } from '../library-common';
import { Row, RowCell } from '../library-components';
import { statusFromExpiry } from '../models/common';

const ContainerRow = styled(Row)(({ theme, isCancelled }) => {

  const color = isCancelled ? theme.colorDisabled : '';

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const BundleAssignedToRow = ({
  data: assignment = {},
  status
}) => {
  const { expires, id: friendId, name } = assignment;

  const rowStatus = statusFromExpiry(expires, status);

  return (
    <ContainerRow
      id={friendId}
      isSlim
      noMargins
      status={rowStatus}
    >
      <RowCell
        alignRight
        pixelWidth={60}
        secondary="for:"
      />
      <RowCell
        primary={name}
        warning={`Expires: ${formatDate(expires)}`}
      />
    </ContainerRow>
  );
}

export default BundleAssignedToRow;
