import "firebase/analytics";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import React, { createContext, useEffect, useReducer, useState } from "react";

import apiFetch from "../../tools/apiFetch";
import cancellablePromise from "../../tools/cancellablePromise";
import log from "../../tools/log";
import reducer, { Actions, initialState } from "./reducer";

export const ConfigContext = createContext();

const ConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [initialising, setInitialising] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const queryIndex = url.indexOf("?");
    const urlQuery = queryIndex >= 0 ? url.substring(queryIndex) : "";

    const initalise = async (result) => {
      if (!initialising) {
        setInitialising(true);

        const firestoreSettings = {
          ignoreUndefinedProperties: true,
          merge: true,
        };

        initializeApp(result.firebase, firestoreSettings);
      }
      return result;
    };

    const loadConfig = () =>
      apiFetch(`/config${urlQuery}`)
        .then((res) => res.json())
        .then(initalise);

    const onError = (error) => {
      log.error(error);
    };

    const onResult = (result) => {
      dispatch({
        type: Actions.UpdateConfig,
        payload: result,
      });
    };

    return cancellablePromise(loadConfig, onResult, onError);
  }, [dispatch, initialising]);

  return (
    <ConfigContext.Provider value={[state, dispatch]}>
      {state.isInitialised ? children : null}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
