import { DateTime } from "luxon";
import { formatCurrency } from "../../library-common";
import { Row, RowCell } from "../../library-components";

const SalesTotalRow = ({ data }) => {
  const { actual, fees, overdue, total, accountingDate } = data;

  const actualFormatted = formatCurrency(actual);
  const feesFormatted = formatCurrency(fees);
  const overdueFormatted = formatCurrency(overdue);
  const totalFormatted = formatCurrency(total);

  const dateFormatted = accountingDate.toLocaleString(DateTime.DATE_FULL);

  return (
    <Row>
      <RowCell
        primary={totalFormatted}
        secondary={dateFormatted}
        flexWidth={2}
        invert
      />
      <RowCell
        secondary={overdueFormatted}
        tertiary="Incomplete"
        alignRight
        invert
      />
      <RowCell secondary={feesFormatted} tertiary="Fees" alignRight invert />
      <RowCell primary={actualFormatted} tertiary="Net" alignRight invert />
    </Row>
  );
};

export default SalesTotalRow;
