import useUser from '../hooks/useUser';
import { Panel } from '../library-components';

const RequireUserPanel = (props) => {
  const { children } = props;
  const { userId } = useUser();

  return userId ? <Panel {...props}>{children}</Panel> : null;
};

export default RequireUserPanel;
