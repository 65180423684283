const RequestTypes = {
  AddBundle: {
    key: "add-bundle",
    label: "Add Bundle",
  },
  AddClass: {
    key: "add-class",
    label: "Add Class",
  },
  AddFriend: {
    key: "add-friend",
    label: "Add Friend/Child",
  },
  AddSession: {
    key: "add-session",
    label: "Add Session",
  },
  CancelBooking: {
    key: "cancel-booking",
    label: "Cancel Booking",
  },
  CancelOrder: {
    key: "cancel-order",
    label: "Cancel Order",
  },
  CancelPurchase: {
    key: "cancel-purchase",
    label: "Cancel Purchase",
  },
  CreateClassSessions: {
    key: "create-class-sessions",
    label: "Create Timetable Sessions",
  },
  PayOverdueItems: {
    key: "pay-overdue-items",
    label: "Pay Overdue Items",
  },
  PayWithAccount: {
    key: "pay-with-account",
    label: "Pay using Account",
  },
  PayWithStripe: {
    key: "pay-with-stripe",
    label: "Pay using Card",
  },
  PurchaseBundle: {
    key: "purchase-bundle",
    label: "Purchase Bundle",
  },
  PurchaseSession: {
    key: "purchase-session",
    label: "Purchase Session",
  },
  RegisterEntity: {
    key: "register-entity",
    label: "Register Gym",
  },
  RemoveBundle: {
    key: "remove-bundle",
    label: "Remove Bundle",
  },
  RemoveClass: {
    key: "remove-class",
    label: "Remove Class",
  },
  RemoveSession: {
    key: "remove-session",
    label: "Remove Session",
  },
  TakeScheduledPayment: {
    key: "take-scheduled-payment",
    label: "Take Shecduled Payment",
  },
  UpdateBundle: {
    key: "update-bundle",
    label: "Update Bundle",
  },
  UpdateProfile: {
    key: "update-profile",
    label: "Update Profile",
  },
  UpdateSession: {
    key: "update-session",
    label: "Update Session",
  },
  UpdateTimetabledEvent: {
    key: "update-timetabled-event",
    label: "Update Class",
  },
};

const makeReducer =
  (field, keyField) =>
  (acc, [key, value]) => ({
    ...acc,
    [keyField ? value[keyField] : key]: value[field],
  });

export const RequestTypeLabels = Object.entries(RequestTypes).reduce(
  makeReducer("label", "key"),
  {}
);

export default Object.entries(RequestTypes).reduce(makeReducer("key"), {});
