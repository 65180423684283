import SessionRow from './SessionRow';

const SessionList = ({
  sessions = [],
  showDates = false
}) => {

  const uniqueSessions = Object.values(sessions
    .reduce((acc, session) => ({
      ...acc,
      [session.id]: session
    }), {}));

  return uniqueSessions.map(session => (
    <SessionRow
      data={session}
      key={session.id}
      showDate={showDates}
    />
  ));
};

export default SessionList;
