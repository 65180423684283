import { collectionGroup, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import { useCallback } from "react";

import { Collections, Operators, Properties } from "../constants";
import UserBundle from "../models/userBundle";
import useEntity from "./useEntity";
import useFirestoreCollection from "./useFirestoreCollection";

const expiresSorter = (a, b) => a.expires.diff(b.expires);

const useMemberships = () => {
  const { entityId } = useEntity();

  const today = DateTime.local().startOf("day").toISO();

  const refProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.BundleAssignments),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.Expires, Operators.GreaterThan, today)
      ),
    [entityId, today]
  );

  const hydrator = useCallback((raw) => UserBundle.hydrate(raw), []);

  const memberships = useFirestoreCollection(entityId && refProvider, hydrator);

  return memberships.sort(expiresSorter);
};

export default useMemberships;
