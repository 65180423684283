import { DateTime } from "luxon";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationButton } from "../../components";
import useAppState from "../../hooks/useAppState";
import useEntity from "../../hooks/useEntity";
import useNavigation from "../../hooks/useNavigation";
import useUpdatableFields from "../../hooks/useUpdatableFields";
import useUser from "../../hooks/useUser";
import { Button, Page, Panel, SideBySide } from "../../library-components";
import { addBundle, updateBundle } from "../../userRequests";
import BundleInformationPanel from "./BundleInformationPanel";
import PricingPanel from "./PricingPanel";
import RestrictionsPanel from "./RestrictionsPanel";

import {
  BundleType,
  BundleTypeLabels,
  defaultModel as defaultBundle,
  dehydrateBundle,
} from "../../models/bundle";
import asLookup from "../../tools/asLookup";

const BundleManagerEdit = ({ bundleId: bundleIdArg }) => {
  const { isBusy } = useAppState();
  const { bundleId: bundleIdParam } = useParams();
  const { entityId, bundles } = useEntity();
  const { back } = useNavigation();
  const { userId } = useUser();
  const [errors, setErrors] = useState([]);
  const [updates, updateField] = useUpdatableFields({}, setErrors);

  const bundleId = bundleIdArg || bundleIdParam;
  const bundlesLookup = asLookup(bundles);
  const bundle = bundleId ? bundlesLookup[bundleId] : {};

  const updated = {
    ...defaultBundle,
    ...bundle,
    ...updates,
    lastUpdated: DateTime.utc(),
  };

  const noUpdates =
    Object.entries(updates).filter(([, val]) => val != null).length === 0;

  const hasErrors = errors && errors.length;

  const payload = {};
  const { type } = updated;

  if (bundleId) {
    payload.bundleId = bundleId;
    payload.updates = dehydrateBundle(updated);
  } else {
    payload.type = type;
    payload.details = dehydrateBundle(updated);
  }

  const typeLabel = BundleTypeLabels[type];
  const title = bundleId ? `Editing ${typeLabel}` : `Adding ${typeLabel}`;
  const buttonLabel = bundleId ? "Save Changes" : `Add ${typeLabel}`;
  const buttonAction = bundleId ? updateBundle : addBundle;

  const isRecurringCost = type === BundleType.Subscription;

  return (
    <Page>
      <Panel isBorderless>
        <h3>{title}</h3>
      </Panel>
      <BundleInformationPanel bundle={updated} onUpdateField={updateField} />
      <PricingPanel
        data={updated}
        onUpdateField={updateField}
        isRecurringCost={isRecurringCost}
        isEach
      />
      <RestrictionsPanel data={updated} onUpdateField={updateField} />
      <Panel isBorderless>
        <SideBySide fitContent>
          <Button
            asyncAction={buttonAction(payload)}
            disabled={noUpdates || hasErrors || isBusy}
            label={buttonLabel}
            onComplete={back}
            userId={userId}
            entityId={entityId}
            isPrimary
          />
          <NavigationButton label="Cancel" isBackButton disabled={isBusy} />
        </SideBySide>
      </Panel>
    </Page>
  );
};

export default BundleManagerEdit;
