import { DateTime } from "luxon";
import { Row, RowCell, RowStatus } from "../../library-components";
import { UserRequestResult } from "../common";

const ActivityRow = ({ data = {}, isSummary = false }) => {
  const {
    actioned: actionedISO,
    created: createdISO,
    error,
    status,
    type,
  } = data;

  const rowStatus = status === "complete" ? RowStatus.None : RowStatus.Disabled;

  const now = DateTime.utc();
  const actioned = DateTime.fromISO(actionedISO);
  const created = DateTime.fromISO(createdISO);

  const createdSince = created.toRelativeCalendar({
    base: now,
    unit: "days",
  });

  const executionTime = actioned.diff(created).as("seconds").toFixed(2);

  return (
    <>
      <Row status={rowStatus}>
        <RowCell
          primary={<UserRequestResult result={{ type, outcome: status }} />}
          secondary={`Triggered ${createdSince}`}
          tertiary={`Execution time ${executionTime} seconds`}
        />
        <RowCell
          alignRight
          primary={created.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
          secondary={created.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
        />
      </Row>
      {error && (
        <Row status={rowStatus}>
          <RowCell error={error.message || error} />
        </Row>
      )}
    </>
  );
};

export default ActivityRow;
