import { getAuth, getIdTokenResult, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  collectionGroup,
  doc,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { createContext, useEffect, useReducer } from "react";

import { Collections } from "../../constants";
import useAppState from "../../hooks/useAppState";
import { getPastCutOff } from "../../models/common";
import { hydrateSessionBooking } from "../../models/session";
import {
  hydrateAccountTransaction,
  hydrateBasketItem,
  hydrateBundleAssignment,
  hydrateUserClaims,
  hydrateUserOrder,
  hydrateUserProfile,
} from "../../models/user";
import mapCollection from "../../tools/mapCollection";
import reducer, { Actions, initialState } from "./reducer";

export const UserContext = createContext();

const orderItemSorter = (a, b) => b.createdISO.localeCompare(a.createdISO);

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { wrappedNotifyError } = useAppState();
  const { claims } = state;
  const { user_id } = claims || {};

  useEffect(() => {
    const signIn = (claims) =>
      dispatch({
        type: Actions.SignIn,
        payload: hydrateUserClaims(claims),
      });

    const signOut = () =>
      dispatch({
        type: Actions.SignOut,
      });

    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        getIdTokenResult(user)
          .then(({ claims }) => signIn(claims))
          .catch(signOut);
      } else signOut();
    });
  }, []);

  useEffect(() => {
    if (!user_id) return;

    const updateProfile = (doc) => {
      const profile = doc.data();

      dispatch({
        type: Actions.UpdateProfile,
        payload: hydrateUserProfile(profile),
      });
    };

    const update = (type) => (payload) => dispatch({ type, payload });

    const db = getFirestore();
    const pastCutOffISO = getPastCutOff().toISO();

    const userRef = doc(db, Collections.Users, user_id);

    const unsubscribers = [];

    // subscribe to the user details
    unsubscribers.push(
      onSnapshot(
        userRef,
        updateProfile,
        wrappedNotifyError("subscribe to the user details")
      )
    );

    // subscribe to the user account
    unsubscribers.push(
      onSnapshot(
        collection(userRef, Collections.AccountTransactions),
        mapCollection(update(Actions.UpdateAccount), hydrateAccountTransaction),
        wrappedNotifyError("subscribe to the user account")
      )
    );

    // subscribe to the user basket
    unsubscribers.push(
      onSnapshot(
        query(
          collection(userRef, Collections.OrderItems),
          where("orderId", "==", null)
        ),
        mapCollection(
          update(Actions.UpdateBasket),
          hydrateBasketItem,
          orderItemSorter
        ),
        wrappedNotifyError("subscribe to the user basket")
      )
    );

    // subscribe to the user orders
    unsubscribers.push(
      onSnapshot(
        collection(userRef, Collections.Orders),
        mapCollection(update(Actions.UpdateOrders), hydrateUserOrder),
        wrappedNotifyError("subscribe to the user orders")
      )
    );

    // subscribe to the users friends
    // [TODO: we need to load user bundles here so we can figure out the cost for friends]
    unsubscribers.push(
      onSnapshot(
        query(
          collection(db, Collections.Users),
          where("introducerId", "==", user_id)
        ),
        mapCollection(
          update(Actions.UpdateFriends),
          hydrateUserProfile,
          null,
          {},
          (friend) => friend.id
        ),
        wrappedNotifyError("subscribe to the users friends")
      )
    );

    // subscribe to the users bookings
    unsubscribers.push(
      onSnapshot(
        query(
          collectionGroup(db, Collections.Bookings),
          where("bookedById", "==", user_id)
        ),
        mapCollection(
          update(Actions.UpdateBookings),
          hydrateSessionBooking,
          null,
          { 1: "entityId", 3: "sessionId" }
        ),
        wrappedNotifyError("subscribe to the users bookings account")
      )
    );

    // subscribe to the user bundleAssignments
    unsubscribers.push(
      onSnapshot(
        query(
          collectionGroup(db, Collections.BundleAssignments),
          where("expires", ">", pastCutOffISO),
          where("orderedBy", "==", user_id)
        ),
        mapCollection(
          update(Actions.UpdateBundleAssigments),
          hydrateBundleAssignment,
          null,
          { 1: "assignedTo" }
        ),
        wrappedNotifyError("subscribe to the user bundle assignments")
      )
    );

    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [user_id, wrappedNotifyError]);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
