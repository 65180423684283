import { NoContent } from '../../library-components';
import { SessionList, RequireUserPanel } from '../../components';

import useEntity from '../../hooks/useEntity';
import useUser from '../../hooks/useUser';
import asLookup from '../../tools/asLookup';

import { BookingStatus } from '../../constants';

const UserBookingHistoryPanel = () => {
  const { sessions } = useEntity();
  const { bookings } = useUser();

  const sessionLookup = asLookup(sessions);
  const bookedSessions = bookings
    .filter(({ status }) => status === BookingStatus.Confirmed)
    .map(({ sessionId }) => sessionLookup[sessionId])
    .filter(session => !!session)
    .filter(({ isInPast }) => isInPast());

  return (
    <RequireUserPanel title="Recent Bookings">
      {bookedSessions.length > 0
        ? <SessionList sessions={bookedSessions} showDates />
        : <NoContent message="You have no recent bookings" />}
    </RequireUserPanel>
  );
};

export default UserBookingHistoryPanel;
