import styled, { css } from "styled-components";

const Container = styled.div(
  ({ alignCenter, alignRight, flexWidth, invert, pixelWidth, theme }) => css`
    display: flex;
    flex: ${flexWidth ? flexWidth : "1"};
    flex-direction: ${invert ? "column-reverse" : "column"};
    max-width: ${pixelWidth ? pixelWidth + "px" : "1"};
    min-width: ${pixelWidth ? pixelWidth + "px" : "1"};
    padding: ${theme.paddingSmall}px;

    & > div {
      overflow-wrap: break-word;
      text-align: ${alignCenter ? "center" : alignRight ? "right" : "left"};
    }
  `
);

const Primary = styled.div`
  font-size: ${({ theme }) => theme.fontSizeDefault};
`;

const Secondary = styled.div(
  ({ error, theme }) => css`
    color: ${error ? theme.colorError : theme.colorDimmed0};
    font-size: ${theme.fontSizeSmall};
  `
);

const Tertiary = styled.div(
  ({ warning, theme }) => css`
    color: ${warning ? theme.colorWarn : theme.colorDimmed0};
    font-size: ${theme.fontSizeXSmall};
  `
);

const isFunction = (functionToCheck) =>
  functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";

const RowCell = ({
  alignCenter,
  alignRight,
  className,
  flexWidth,
  invert = false,
  pixelWidth,
  primary,
  secondary,
  tertiary,
  error,
  warning,
}) => {
  const renderContent = (content) =>
    isFunction(content) ? content() : content;

  const secondRow = error || secondary;
  const thirdRow = warning || tertiary;

  return (
    <Container
      alignCenter={alignCenter}
      alignRight={alignRight}
      className={className}
      flexWidth={flexWidth}
      invert={invert}
      pixelWidth={pixelWidth}
    >
      {primary != null && <Primary>{renderContent(primary)}</Primary>}
      {secondRow != null && (
        <Secondary error={!!error}>{renderContent(secondRow)}</Secondary>
      )}
      {thirdRow != null && (
        <Tertiary warning={!!warning}>{renderContent(thirdRow)}</Tertiary>
      )}
    </Container>
  );
};

export default RowCell;
