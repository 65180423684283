import { collection, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { SessionBasketRow, SessionBookingRow } from "../../components";
import { BookingStatus, Collections, OrderItemType } from "../../constants";
import useEntity from "../../hooks/useEntity";
import useUser, { isUserAdmin } from "../../hooks/useUser";
import { formatCurrency } from "../../library-common";
import {
  NoContent,
  Panel,
  Row,
  RowCell,
  Spinner,
} from "../../library-components";
import { hydrateSessionBooking } from "../../models/session";
import asLookup from "../../tools/asLookup";
import mapCollection from "../../tools/mapCollection";

const SessionAdminInfo = () => {
  const { basketItems: allBasketItems, entityId, sessions } = useEntity();
  const user = useUser();
  const { sessionId } = useParams();
  const [allBookings, setAllBookings] = useState([]);

  const sessionLookup = asLookup(sessions);
  const session = sessionLookup[sessionId];

  const isAdmin = isUserAdmin(user, entityId);

  const nameSorter = (
    { bookedFor: { name: a = "" } = {} },
    { bookedFor: { name: b = "" } = {} }
  ) => a.localeCompare(b);

  const timeSorter = ({ created: a }, { created: b }) =>
    a.diff(b).as("minutes");

  const bookings = Object.values(
    allBookings.reduce((acc, booking) => {
      const { bookedFor: { id: bookedForId } = {} } = booking;

      const existing = acc[bookedForId] || { latest: null, history: [] };

      const updated = {
        ...existing,
        latest: booking,
        history: [...existing.history, booking],
      };

      return { ...acc, [bookedForId]: updated };
    }, {})
  )
    .map((booking) => booking.latest)
    .sort(nameSorter);

  const confirmed = bookings.filter(
    ({ status }) => status === BookingStatus.Confirmed
  ).length;

  useEffect(() => {
    if (!sessionId || !isAdmin) return;
    const db = getFirestore();

    const bookingsRef = collection(
      db,
      Collections.Entities,
      entityId,
      Collections.Sessions,
      sessionId,
      Collections.Bookings
    );

    return onSnapshot(
      bookingsRef,
      mapCollection(setAllBookings, hydrateSessionBooking, timeSorter)
    );
  }, [entityId, isAdmin, sessionId]);

  if (!session) return <Spinner />;

  const { capacity, cost } = session;

  const inUsersBasket = allBasketItems.filter(
    ({ contextType, contextId }) =>
      (contextType === OrderItemType.Session ||
        contextType === OrderItemType.SessionOnBehalfOf) &&
      contextId === sessionId
  );

  return isAdmin ? (
    <>
      <Panel title="Revenue">
        <Row>
          <RowCell alignCenter invert primary={capacity} secondary="Capacity" />
          <RowCell
            alignCenter
            invert
            primary={formatCurrency(cost, true)}
            secondary="Cost"
          />
          <RowCell
            alignCenter
            invert
            primary={confirmed}
            secondary="Confirmed"
          />
          <RowCell
            alignCenter
            invert
            primary={formatCurrency(confirmed * cost, false)}
            secondary="Gross"
          />
        </Row>
      </Panel>
      <Panel title="Bookings">
        {bookings.length ? (
          bookings.map((booking) => (
            <SessionBookingRow
              key={booking.id}
              data={booking}
              showAvatar={false}
            />
          ))
        ) : (
          <NoContent message="There are no bookings for this session" />
        )}
      </Panel>
      {inUsersBasket.length > 0 ? (
        <Panel title="Potential Bookings (In Baskets)">
          {inUsersBasket.map((basketItem) => (
            <SessionBasketRow
              key={basketItem.id}
              data={basketItem}
              showAvatar={false}
            />
          ))}
        </Panel>
      ) : null}
    </>
  ) : null;
};

export default SessionAdminInfo;
