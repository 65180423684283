import { DateTime } from 'luxon';

const hydrate = raw => {
    if (!raw) return raw;

    const hydrated = { ...raw };

    if (raw.created) {
        hydrated.createdISO = raw.created;
        hydrated.created = DateTime.fromISO(raw.created);
    }

    if (raw.lastUpdated) {
        hydrated.lastUpdatedISO = raw.lastUpdated;
        hydrated.lastUpdated = DateTime.fromISO(raw.lastUpdated);
    }

    return hydrated;
};

export default hydrate;