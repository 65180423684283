import { useEntity, useUser } from "../../hooks";
import PaymentMethodsAccount from "./PaymentMethodsAccount";
import PaymentMethodsStripe, { StripeWrapper } from "./PaymentMethodsStripe";

export const PaymentMethods = () => {
  const { sessions } = useEntity();
  const { basket } = useUser(sessions);

  return (
    <StripeWrapper isHidden={basket.length <= 0}>
      <PaymentMethodsAccount />
      <PaymentMethodsStripe />
    </StripeWrapper>
  );
};
