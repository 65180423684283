import styled, { css } from "styled-components";
import { FullScreenPage } from ".";
import useAppState from "../hooks/useAppState";
import { Spinner } from "../library-components";

const WaitContent = styled.div(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.color0};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.paddingXLarge}px;

    & > * {
      margin: ${theme.marginDefault}px;
    }
  `
);

const FullPageWait = () => {
  const { isBusy, busyMessage } = useAppState();

  return isBusy ? (
    <FullScreenPage>
      <WaitContent>
        <Spinner />
        {busyMessage && <p>{busyMessage}</p>}
      </WaitContent>
    </FullScreenPage>
  ) : null;
};

export default FullPageWait;
