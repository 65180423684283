import {
  getAuth,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { useState } from 'react';

import {
  Button,
  EmailField,
  Form,
  ModalMessage,
  Panel,
  Paragraph,
  PasswordField,
  SideBySide
} from '../../library-components';
import { NavigationButton } from '../../components';
import { validateEmail, validatePassword } from '../../library-common';
import useAppState from '../../hooks/useAppState';
import useNavigation from '../../hooks/useNavigation';

const defaultState = {
  emailSent: false,
  errors: []
};

const RegistrationPanel = () => {
  const [state, setState] = useState(defaultState);

  const { isBusy, setIsBusy, notifyError } = useAppState();
  const { navigate } = useNavigation();

  const { checkPassword, email, errors = [], emailSent, password } = state;

  const updateState = fieldName => (newValue, err) => setState({
    ...state,
    [fieldName]: newValue,
    errors: err ? err : []
  });

  const clearState = () => setState(defaultState);
  const auth = getAuth();

  const register = () => {
    setIsBusy(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsBusy(false);
        navigate('/');
      })
      .catch(error => {
        setIsBusy(false);
        notifyError(error);
      });
  };

  const disableSignUp = isBusy
    || !email
    || !password
    || !checkPassword
    || errors.length
    || password !== checkPassword;

  return (
    <Panel title="Sign Up">
      <Paragraph>
        Please enter your details below to create an account.
      </Paragraph>
      <Form>
        <ModalMessage
          visible={emailSent}
          lines={[
            `An email has been sent to ${email}.`,
            `Please follow the instructions in the email to set your password.`
          ]}
          onClose={clearState}
          title="Verification Email Sent"
        />
        <EmailField
          label="Email Address"
          validator={validateEmail}
          value={email}
          disabled={isBusy}
          onChange={updateState('email')}
        />
        <PasswordField
          label="Password"
          validator={validatePassword}
          value={password}
          disabled={isBusy}
          onChange={updateState('password')}
        />
        <PasswordField
          label="Confirm Password"
          validator={check => validatePassword(password, check)}
          value={checkPassword}
          disabled={isBusy}
          onChange={updateState('checkPassword')}
        />
        <SideBySide>
          <Button
            isPrimary
            label="Sign Up Now"
            onClick={register}
            disabled={disableSignUp}
          />
          <NavigationButton
            label="Cancel"
            link="/"
            disabled={isBusy}
          />
        </SideBySide>
      </Form>
    </Panel>
  );
};

export default RegistrationPanel;
