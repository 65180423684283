import { DateTime } from "luxon";

import { calculateAge } from "../../library-common";
import commonModel from "../common2";

const OldAvatarPath = "/icon-user.png";

const hydrate = (raw) => {
  const hydrated = commonModel.hydrate(raw);

  const now = DateTime.local();

  // If we have a date of birth
  if (raw.dateOfBirth) {
    hydrated.dateOfBirthISO = raw.dateOfBirth;
    hydrated.dateOfBirth = DateTime.fromISO(raw.dateOfBirth);

    const { dateOfBirth } = hydrated;
    hydrated.age = calculateAge(hydrated.dateOfBirth);
    hydrated.birthday = dateOfBirth.toLocaleString({
      day: "numeric",
      month: "short",
    });

    const { age } = hydrated;
    hydrated.ageMessage = age > 0 ? `${age} years` : "";
  }

  // Last access hydration
  if (raw.lastRequestTime) {
    hydrated.lastRequestTimeISO = raw.lastRequestTime;
    hydrated.lastRequestTime = DateTime.fromISO(raw.lastRequestTime);

    const { lastRequestTime } = hydrated;
    hydrated.lastSeenMessage = `Seen ${lastRequestTime.toRelativeCalendar({
      base: now,
      unit: "days",
    })}`;
  }

  // Legacy: Strip off old data
  const { avatar } = hydrated;
  if (avatar === OldAvatarPath) {
    delete hydrated.avatar;
  }

  return hydrated;
};

export default hydrate;
