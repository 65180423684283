import { DateTime } from "luxon";
import useNavigation from "../../hooks/useNavigation";
import { calculateAge } from "../../library-common";
import { Avatar, Row, RowCell, RowStatus } from "../../library-components";

const UserRow = ({ data, renderMore = () => null }) => {
  const {
    avatar,
    dateOfBirth,
    id: userId,
    name,
    lastRequestTime,
    lastRequestType,
    status = RowStatus.Undefined,
    icon = null,
  } = data;

  const { navigate } = useNavigation();

  const now = DateTime.utc();
  const lastAccess = DateTime.fromISO(lastRequestTime);
  const lastOpenedApp = `Seen ${lastAccess.toRelativeCalendar({
    base: now,
    unit: "days",
  })}`;

  const navigateToUser = () => navigate(`/users/${userId}`);

  const dtDateOfBirth = DateTime.fromISO(dateOfBirth);
  const age = dtDateOfBirth ? calculateAge(dtDateOfBirth) : "N/A";

  return (
    <Row id={userId} onClick={navigateToUser} status={status}>
      <RowCell pixelWidth={60} primary={() => <Avatar bgSrc={avatar} />} />
      <RowCell primary={name} secondary={`Aged ${age}`} />
      <RowCell
        alignRight
        secondary={lastOpenedApp}
        tertiary={`Action: ${lastRequestType}`}
      />
      {icon && <RowCell pixelWidth={60} primary={icon} />}
    </Row>
  );
};

export default UserRow;
