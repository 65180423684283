import { useContext } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import styled, { css, ThemeContext } from 'styled-components';
import { FullScreenPage } from '.';

const ErrorContent = styled.div(({ theme }) => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.paddingXLarge}px;

  & > * {
    margin: ${theme.marginDefault}px;
  }
`);

const FullPageError = ({
  onCancel = () => null,
  type: errorType,
  message: errorMessage
}) => {
  const theme = useContext(ThemeContext);

  return (
    <FullScreenPage onClose={onCancel}>
      <ErrorContent>
        <FaExclamationTriangle size={`${theme.iconAvatarLarge}px`} />
        <h1>{errorType}</h1>
        <p>{errorMessage}</p>
      </ErrorContent>
    </FullScreenPage>
  );
};

export default FullPageError;
