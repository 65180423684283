import { Panel } from '../library-components';
import { EntityRow } from '../components';
import useUser from '../hooks/useUser';

const EntitiesPanel = () => {
  const { entities = [] } = useUser();

  return entities.length ? (
    <Panel
      title="Your Gyms"
      isCentered
    >
      {entities.map(entityId => (
        <EntityRow entityId={entityId} key={entityId} />
      ))}
    </Panel>
  ) : null;
};

export default EntitiesPanel;
