import dehydrate from './dehydrate';
import hydrate from './hydrate';
import logic from './logic';

const model = {
    ...logic,
    dehydrate,
    hydrate
};

export default model;
