import styled from "styled-components";
import useEntity from "../hooks/useEntity";
import { calculateAge, formatDate } from "../library-common";
import { Row, RowCell, RowOptions } from "../library-components";

const ContainerRow = styled(Row)`
  justify-content: space-between;
`;

const FriendRow = ({ data: friend = {}, options = [] }) => {
  const { dateOfBirth, id: friendId, name } = friend;

  const { entityId } = useEntity();

  const age = calculateAge(dateOfBirth);
  const dobText = dateOfBirth ? `DoB: ${formatDate(dateOfBirth)}` : "";

  return (
    <ContainerRow id={friendId} noMargins>
      <RowCell primary={name} />
      <RowCell primary={`Age: ${age}`} secondary={dobText} />
      <RowOptions entityId={entityId} options={options} />
    </ContainerRow>
  );
};

export default FriendRow;
