import { FaPlus } from 'react-icons/fa';
import { ActionBar, BundleBrowser } from '../../components';
import { Page } from '../../library-components';
import useEntity from '../../hooks/useEntity';

const BundleManager = () => {
  const { bundles } = useEntity();

  const actions = [
    {
      label: 'Add Block or Subscription',
      icon: <FaPlus />,
      link: '/bundlemanager/add'
    }
  ];

  return (
    <Page renderHeader={() => <ActionBar actions={actions} />}>
      <BundleBrowser bundles={bundles} allowAdminActions />
    </Page>
  );
};

export default BundleManager;
