import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { useEffect, useState } from "react";
import {
  Collections,
  MaxOrdersRows,
  NewOrderFormatSince,
  Operators,
  OrderByDirection,
  Properties,
} from "../constants";

const useUserOrders = (userId = null, entityId = null) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (entityId) {
      const db = getFirestore();
      const ordersQuery = query(
        collection(db, Collections.Users, userId, Collections.Orders),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        orderBy(Properties.Created, OrderByDirection.Desc),
        limit(MaxOrdersRows)
      );

      const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
        const rawOrders = snapshot.docs.map((doc) => doc.data());
        setOrders(rawOrders);
      });

      return () => unsubscribe();
    }
  }, [entityId, userId]);

  const historicOrders = orders.filter(
    ({ created }) => created.localeCompare(NewOrderFormatSince) < 0
  );
  const newOrders = orders.filter(
    ({ created }) => created.localeCompare(NewOrderFormatSince) >= 0
  );

  return { historicOrders, orders: newOrders };
};

export default useUserOrders;
