import { NotificationHiddenInterval } from '../../constants';
import React, { createContext, useEffect, useReducer } from "react";
import reducer, { initialState } from './reducer';

export const AppStateContext = createContext();

const AppStateProvider = ({ children }) => {
  useEffect(() => {
    const clearNotifications = () => {

    };

    const tidyUpTimers = () => {
      clearInterval(interval);
    };

    // This interval timer updates the state of notifications and removes them once stale
    const interval = setInterval(
      clearNotifications,
      NotificationHiddenInterval
    );

    return tidyUpTimers;
  }, []);

  return (
    <AppStateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;