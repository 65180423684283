import { formatDate } from '../../library-common';
import {
  Avatar,
  Detail,
  DetailSection,
  DetailTitle,
  SideBySide
} from '../../library-components';
import { RequireUserPanel } from '../../components';

import useNavigation from '../../hooks/useNavigation';
import useUser from '../../hooks/useUser';

const UserSummaryPanel = () => {
  const user = useUser();
  const { navigate } = useNavigation();

  const { age, avatar, dateOfBirth, name } = user;

  return (
    <RequireUserPanel onClick={() => navigate('/profile')}>
      <SideBySide fitContent>
        <Avatar bgSrc={avatar} isMedium />
        <DetailSection>
          <DetailTitle title={name} />
          {dateOfBirth && <>
            <Detail
              formatter={formatDate}
              label="Date of Birth"
              value={dateOfBirth}
            />
            <Detail
              label="Age"
              suffix="years"
              value={age}
            />
          </>}
        </DetailSection>
      </SideBySide>
    </RequireUserPanel>
  );
};

export default UserSummaryPanel;
