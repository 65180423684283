import { collectionGroup, query, where } from "firebase/firestore";
import { useCallback } from "react";

import { Collections, Operators, Properties } from "../constants";
import userBundleModel from "../models/userBundle";
import useEntity from "./useEntity";
import useFirestoreCollection from "./useFirestoreCollection";

const useUserMemberships = (userId) => {
  const { entityId } = useEntity();

  const refMyProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.BundleAssignments),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.OrderedBy, Operators.EqualTo, userId)
      ),
    [entityId, userId]
  );

  const refFriendProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.BundleAssignments),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.AssignedToId, Operators.EqualTo, userId)
      ),
    [entityId, userId]
  );

  const hydrator = useCallback((raw) => userBundleModel.hydrate(raw), []);

  const hasParams = userId && entityId;

  const myMemberships = useFirestoreCollection(
    hasParams && refMyProvider,
    hydrator
  );
  const friendMemberships = useFirestoreCollection(
    hasParams && refFriendProvider,
    hydrator
  );

  const allMemberships = [...myMemberships, ...friendMemberships];

  return [...new Map(allMemberships.map((v) => [v.id, v])).values()];
};

export default useUserMemberships;
