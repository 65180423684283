import { DateTime } from 'luxon';
import styled, { css } from 'styled-components';

import { Row, RowCell, RowStatus } from '../library-components';
import { formatCurrency } from '../library-common';
import { HistoricCutOffDays } from '../constants';

import useNavigation from '../hooks/useNavigation';
import useUser from '../hooks/useUser';

const OverdueRowCell = styled(RowCell)(({ theme }) => css`
  color: ${theme.colorError};
`);

const PaymentsTitle = styled.h2(({ theme }) => css`
  font-size: ${theme.fontSizeSmall};
  margin ${theme.marginDefault}px 0;
`);

const BundleAssignmentRow = ({
  data: assignment = {},
  showPayments = false
}) => {
  const { navigate } = useNavigation();
  const { friends = {} } = useUser();

  const {
    assignedTo, createdBy, bundleId, expires,
    paymentSchedule, totalOverdue
  } = assignment;

  const navigateToBundle = () => navigate(`/bundles/${bundleId}`);

  const today = DateTime.utc().endOf('day');

  const isActive = expires > today;
  const isExpiring = expires.minus({ days: HistoricCutOffDays }) < today;
  const isOverdue = totalOverdue > 0;
  const expiresPrefix = isActive ? 'Expires' : 'Expired';

  const rowStatus = isActive
    ? (isOverdue
      ? RowStatus.Error
      : (isExpiring
        ? RowStatus.Warn
        : RowStatus.Ok))
    : RowStatus.None;

  const hasPayments = showPayments && paymentSchedule;

  let assigneeMessage = null;
  if (createdBy !== assignedTo) {
    assigneeMessage = `Assigned to ${friends[assignedTo] && friends[assignedTo].name}`;
  }

  return (
    <>
      <Row
        id={bundleId}
        onClick={navigateToBundle}
        status={rowStatus}
        noMargins
      >
        <RowCell
          primary={`${expiresPrefix} ${expires.toLocaleString(DateTime.DATE_FULL)}`}
          secondary={assigneeMessage}
        />
        <OverdueRowCell
          primary={totalOverdue
            ? `${formatCurrency(totalOverdue)} overdue`
            : null}
          alignRight
        />
      </Row>
      {hasPayments ? <>
        <PaymentsTitle>Payments</PaymentsTitle>
        {paymentSchedule
          .sort((a, b) => a.due.localeCompare(b.due))
          .map(({ due, outstanding }, key) => {

            const dt = DateTime.fromISO(due);

            const isPaid = outstanding <= 0;
            const isOverdue = !isPaid && dt < today;

            const paymentStatus = isPaid
              ? RowStatus.Ok
              : (isOverdue ? RowStatus.Error : RowStatus.None);

            const paymentDate = dt.toLocaleString(DateTime.DATE_FULL);
            const statusMessage = isPaid ? 'Paid' : (isOverdue ? 'Overdue' : 'Due');

            return (
              <Row key={key} status={paymentStatus} noMargins>
                <RowCell pixelWidth={80} primary={statusMessage} />
                <RowCell primary={paymentDate} alignRight />
                {isOverdue ? <OverdueRowCell
                  primary={formatCurrency(outstanding)}
                  pixelWidth={80}
                  alignRight
                /> : <RowCell
                  primary={isPaid ? '' : formatCurrency(outstanding)}
                  pixelWidth={80}
                  alignRight
                />}
              </Row>
            );
          })}
      </> : null}
    </>
  );
}

export default BundleAssignmentRow;
