import { Page } from '../../library-components';
import { BundleBrowser } from '../../components';
import useEntity from '../../hooks/useEntity';

const Bundles = () => {
  const { bundles: allBundles } = useEntity();

  // do not show published sessions
  const bundles = allBundles.filter(({ isPublished }) => isPublished);

  return (
    <Page>
      <BundleBrowser bundles={bundles} />
    </Page>
  );
};

export default Bundles;
