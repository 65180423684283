import { DateTime } from "luxon";
import { OrderStatus } from "../../constants";
import { useEntity, useUsers } from "../../hooks";
import { Row, RowCell, RowStatus } from "../../library-components";
import asLookup from "../../tools/asLookup";

const SalesOrderRow = ({ data }) => {
  const {
    scheduled,
    created,
    payments,
    subTotal,
    subTotalFormatted,
    feesFormatted,
    paymentsFormatted,
    debitItemCount,
    status,
    userId,
  } = data;

  const { entityId } = useEntity();
  const users = asLookup(useUsers(entityId));
  const { name: userName = "N/A" } = users[userId] || {};

  const timeFormatted = (scheduled || created).toLocaleString(
    DateTime.TIME_24_WITH_SECONDS
  );

  const rowStatus = payments < subTotal ? RowStatus.Warn : RowStatus.Undefined;
  const orderWarning = status !== OrderStatus.Complete ? status : null;

  return (
    <Row status={rowStatus}>
      <RowCell
        secondary={userName}
        tertiary={timeFormatted}
        flexWidth={2}
        invert
      />
      <RowCell secondary={debitItemCount} tertiary="Items" invert alignCenter />
      <RowCell
        secondary={paymentsFormatted || subTotalFormatted}
        tertiary={orderWarning ? null : "Value"}
        warning={orderWarning}
        invert
        flexWidth={2}
        alignRight
      />
      <RowCell
        secondary={feesFormatted || "None"}
        tertiary="Fees"
        alignRight
        invert
      />
    </Row>
  );
};

export default SalesOrderRow;
