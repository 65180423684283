import { useRef, useState } from "react";
import { FaExclamation } from "react-icons/fa";
import styled, { css } from "styled-components";
import Spinner from "./Spinner";

const StandardButton = styled.button(
  ({ hasError, isBorderless, isWarning, theme }) => css`
    background-color: ${hasError
      ? `${theme.colorError} !important`
      : isWarning
      ? theme.colorWarn
      : theme.background0};
    border: ${isBorderless
      ? "none"
      : `solid 1px ${isWarning ? theme.colorWarn : theme.colorPrimary}`};
    border-radius: ${theme.radiusSmall}px;
    color: ${isWarning ? theme.colorWarnBg : theme.color0};
    padding: ${theme.paddingDefault}px;
    outline: none;

    :hover {
      background-color: ${hasError ? theme.colorError : theme.background0light};
      color: ${theme.colorPrimary};
      cursor: pointer;
    }

    :disabled,
    :disabled:hover {
      background-color: ${hasError ? theme.colorError : theme.background0};
      border-color: ${theme.colorDisabled};
      color: ${theme.colorDisabled};
      cursor: not-allowed;
    }

    svg {
      margin-left: ${theme.paddingDefault}px;
    }
  `
);

const PrimaryButton = styled(StandardButton)(
  ({ isWarning, theme }) => css`
    background: ${isWarning ? theme.colorWarn : theme.colorPrimary};
    color: ${isWarning ? theme.colorWarnBg : theme.colorPrimaryInverse};

    :hover {
      background-color: ${theme.colorPrimary};
      color: ${theme.colorPrimaryInverse};
    }
  `
);

const Image = styled.img(
  ({ theme }) => css`
    height: ${theme.iconTiny}px;
    margin: ${`-${theme.paddingDefault}px ${theme.paddingDefault}`}px;
  `
);

const ThemedExclamation = styled(FaExclamation)(
  ({ theme }) => css`
    color: ${theme.background0};
  `
);

const Button = (props) => {
  const {
    asyncAction,
    entityId,
    icon,
    image,
    isPrimary = false,
    label,
    onClick = () => null,
    onComplete = () => null,
    onError = () => null,
    userId,
  } = props;
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState();
  const buttonRef = useRef();

  let content = (
    <>
      {image && <Image alt={label} src={image} />}
      {label}
      {icon}
    </>
  );

  if (error) {
    content = <ThemedExclamation />;
  } else if (isBusy) {
    content = <Spinner isTiny />;
  }

  const onInternalClick = async (ev) => {
    ev.stopPropagation();

    if (!error && asyncAction) {
      setIsBusy(true);

      let actionResult;
      try {
        actionResult = (await asyncAction(userId, entityId)) || {};
      } catch (err) {
        actionResult = err;
      }

      // I component is still mounted
      if (buttonRef.current != null) {
        const { error, success } = actionResult;

        if (success) {
          onComplete(actionResult);
        } else {
          onError(error);
          setError(error);
        }

        setIsBusy(false);
      }
    } else {
      onClick();
    }
  };

  const propagatedProps = { ...props };
  delete propagatedProps.onClick;
  delete propagatedProps.onComplete;
  delete propagatedProps.onError;

  const RenderedButton = isPrimary ? PrimaryButton : StandardButton;

  return (
    <>
      <RenderedButton
        {...propagatedProps}
        hasError={!!error}
        ref={buttonRef}
        onClick={onInternalClick}
      >
        {content}
      </RenderedButton>
    </>
  );
};

export default Button;
