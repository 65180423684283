import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaChartBar,
  FaIdCard /*, FaCog*/,
  FaUser,
} from "react-icons/fa";
import { FullPageMenu } from "../../components";

const Settings = () => {
  const menuItems = [
    {
      icon: <FaCalendarAlt />,
      label: "Manage Timetable",
      subText: "Create or Update weekly classes",
      link: "/timetablemanager",
    },
    {
      icon: <FaCalendarCheck />,
      label: "Manage Sessions",
      subText: "Create or Update a one off class or session",
      link: "/sessionmanager",
    },
    {
      icon: <FaIdCard />,
      label: "Memberships",
      subText: "Create or Update memberships",
      link: "/bundlemanager",
    },
    {
      icon: <FaUser />,
      label: "Users & Members",
      subText: "Show all users and expired or expiring memberships",
      link: "/users",
    },
    {
      isDivider: true,
    },
    {
      icon: <FaChartBar />,
      label: "Reports",
      link: "/reports",
      children: [
        {
          label: "Todays Payments",
          subText: "Individual order items for today",
          link: "/today",
        },
        {
          label: "Last 28 days Payments",
          subText: "Aggregate orders for the last 28 days",
          link: "/month",
        },
        {
          label: "Overdue Payments",
          subText: "Outstanding Membership Payments",
          link: "/overdue",
        },
        {
          label: "Active Memberships",
          subText: "All non-expired memberships",
          link: "/memberships",
        },
      ],
    } /*,
    {
      icon: <FaCog />,
      label: 'Settings',
      link: '/settings'
    }*/,
  ];

  return <FullPageMenu items={menuItems} />;
};

export default Settings;
