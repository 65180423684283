import ActivityInfo from "./ActivityInfo";
import BasicInfo from "./BasicInfo";
import MembershipInfo from "./MembershipInfo";
import OrderInfo from "./OrderInfo";

const LayoutFull = ({ data = {} }) => {
  return (
    <>
      <BasicInfo data={data} />
      <MembershipInfo data={data} showPayButton={false} />
      <OrderInfo data={data} />
      <ActivityInfo data={data} />
    </>
  );
};

export default LayoutFull;
