import { useParams } from 'react-router';
import { Spinner } from '../../library-components';
import useEntity from '../../hooks/useEntity';
import asLookup from '../../tools/asLookup';
import useUser, { isUserAdmin } from '../../hooks/useUser';

const BundleAdminInfo = () => {
  const { entityId, bundles } = useEntity();
  const user = useUser();
  const { bundleId } = useParams();

  const bundleLookup = asLookup(bundles);
  const bundle = bundleLookup[bundleId];

  const isAdmin = isUserAdmin(user, entityId);

  if (!bundle) return <Spinner />;

  return isAdmin ?
    <>
    </>
    : null;
};

export default BundleAdminInfo;
