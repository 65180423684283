import styled, { css } from 'styled-components';

const Header = styled.div(({ theme }) => css`
  background: ${theme.background2};
  color: ${theme.color2};
  flex: content 0 1;
`);

const Content = styled.div(({ theme }) => css`
  background: ${theme.background0};
  color: ${theme.color0};
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow-y: hidden;

  a {
    color: ${theme.colorPrimary};
  }
`);

const Footer = styled.div(({ theme }) => css`
  background: ${theme.background1};
  color: ${theme.color1};
  flex: content 0 1;
`);

const Framework = styled.div(({ theme }) => css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  max-width: ${theme.maxWidth}px;
  top: 0;
  width: 100vw;
`);

const MobileFramework = ({
  renderContent = () => null,
  renderFooter = () => null,
  renderHeader = () => null
}) => (
  <Framework id="framework">
    <Header id="header">{renderHeader()}</Header>
    <Content id="content">{renderContent()}</Content>
    <Footer id="footer">{renderFooter()}</Footer>
  </Framework>
);

export default MobileFramework;
