const validateEmail = email => {
  const simpleRegex = /^\S+@\S+\.\S+$/;

  const errors = simpleRegex.test(email)
    ? []
    : ['There may be a typo in your email address'];

  return { errors };
};

export default validateEmail;
