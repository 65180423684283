import { BrowserRouter as Router } from "react-router-dom";
import { FullPageWait } from "./components";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import Framework from "./library-mobile-framework";
import {
  AppStateProvider,
  ConfigProvider,
  EntityProvider,
  ThemeProvider,
  UserProvider,
} from "./providers";

const App = () => (
  <div className="im-going-app">
    <ConfigProvider>
      <AppStateProvider>
        <UserProvider>
          <EntityProvider>
            <ThemeProvider>
              <FullPageWait />
              <Router>
                <Framework
                  renderHeader={() => <Header />}
                  renderContent={() => <Content />}
                  renderFooter={() => <Footer />}
                />
              </Router>
            </ThemeProvider>
          </EntityProvider>
        </UserProvider>
      </AppStateProvider>
    </ConfigProvider>
  </div>
);

export default App;
