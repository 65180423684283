import { getAuth, signOut } from 'firebase/auth';
import { FaEdit } from 'react-icons/fa';

import {
  Avatar,
  Button,
  Detail,
  DetailSection,
  DetailTitle,
  SideBySide
} from '../library-components';
import { NavigationButton, RequireUserPanel } from '.';
import { formatDate } from '../library-common';
import useNavigation from '../hooks/useNavigation';
import useUser from '../hooks/useUser';

const UserPanel = () => {
  const { navigate } = useNavigation();
  const user = useUser();

  const { age, avatar, dateOfBirth, name } = user;
  const auth = getAuth();

  const onSignOut = () => {
    signOut(auth).then(() => navigate('/sessions'));
  };

  return (
    <RequireUserPanel>
      <SideBySide fitContent>
        <Avatar bgSrc={avatar} isLarge />
        <DetailSection>
          <DetailTitle title={name} />
          {dateOfBirth && <>
            <Detail
              formatter={formatDate}
              label="Date of Birth"
              value={dateOfBirth}
            />
            <Detail
              label="Age"
              suffix="years"
              value={age}
            />
          </>}
        </DetailSection>
      </SideBySide>
      <SideBySide>
        <NavigationButton
          icon={<FaEdit />}
          isPrimary
          label="Edit"
          link="/profile-edit"
        />
        <Button label="Sign Out" onClick={onSignOut} />
      </SideBySide>
    </RequireUserPanel>
  );
};

export default UserPanel;
