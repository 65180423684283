import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { formatCurrency } from "../../library-common";
import { Panel, Row, RowCell } from "../../library-components";
import SalesTotalRow from "./SalesTotalRow";

const HeaderRow = styled(Row)`
  margin-bottom: 10px;
`;

const NetRowCell = styled(RowCell)(
  ({ theme }) => css`
    color: ${theme.colorOk};
    font-weight: bold;
  `
);

const daysInPast = 28;
const daysInFuture = 7;

const accountingDateSorter = (a, b) => b.accountingDate.diff(a.accountingDate);

const SalesTotals = ({ data }) => {
  const now = DateTime.utc();
  const last = now.endOf("day").plus({ days: daysInFuture });
  const first = now.startOf("day").minus({ days: daysInPast });

  const totals = Object.values(data).filter(
    ({ accountingDate }) => accountingDate > first && accountingDate < last
  );

  const { total, fees } = totals.reduce(
    (acc, { total, fees }) => ({
      total: acc.total + total,
      fees: acc.fees + fees,
    }),
    { total: 0, fees: 0 }
  );

  return (
    <Panel
      title={`Totals (Last ${daysInPast} Days) / Forecast (Next ${daysInFuture} days)`}
    >
      <HeaderRow>
        <RowCell
          primary={formatCurrency(total)}
          secondary="Total"
          invert
          alignCenter
        />
        <RowCell
          primary={formatCurrency(fees)}
          secondary="Fees"
          invert
          alignCenter
        />
        <NetRowCell
          primary={formatCurrency(total - fees)}
          secondary="Net"
          invert
          alignCenter
        />
      </HeaderRow>
      {totals.sort(accountingDateSorter).map((total) => (
        <SalesTotalRow data={total} key={total.accountingDateISO} />
      ))}
    </Panel>
  );
};

export default SalesTotals;
