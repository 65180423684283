import { SalesToday } from "../../components/reportSales";
import useMonthlySales from "../../hooks/useMonthlySales";
import { Page } from "../../library-components";

const ReportsToday = () => {
  const monthlySales = useMonthlySales();
  const { orders } = monthlySales;

  return (
    <Page>
      <SalesToday data={orders} />
    </Page>
  );
};

export default ReportsToday;
