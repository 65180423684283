import { collectionGroup, query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import { useCallback } from "react";

import {
  Collections,
  Operators,
  PaymentStatus,
  Properties,
} from "../constants";
import useEntity from "./useEntity";
import useFirestoreCollection from "./useFirestoreCollection";

const dueDateSorter = (a, b) => a.dueDate.localeCompare(b.dueDate);

const useOverduePayments = () => {
  const { entityId } = useEntity();

  const today = DateTime.local().toISODate();

  const pendingRefProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.ScheduledPayments),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.DueDate, Operators.LessThan, today),
        where(Properties.Status, Operators.EqualTo, PaymentStatus.Pending)
      ),
    [entityId, today]
  );

  const failedRefProvider = useCallback(
    (db) =>
      query(
        collectionGroup(db, Collections.ScheduledPayments),
        where(Properties.EntityId, Operators.EqualTo, entityId),
        where(Properties.DueDate, Operators.LessThan, today),
        where(Properties.Status, Operators.EqualTo, PaymentStatus.Failed)
      ),
    [entityId, today]
  );

  const hydrator = useCallback((raw) => raw, []);
  const pathMappings = { bundleAssignmentId: 3 };

  const pending = useFirestoreCollection(
    entityId && pendingRefProvider,
    hydrator,
    "useOverduePayments",
    pathMappings
  );
  const failed = useFirestoreCollection(
    entityId && failedRefProvider,
    hydrator,
    "useOverduePayments",
    pathMappings
  );

  return [...pending, ...failed].sort(dueDateSorter);
};

export default useOverduePayments;
