import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled, { css } from "styled-components";
import { Shadows } from "./constants";
import Heading from "./Heading";

const Container = styled.div(
  ({ isHidden }) => css`
    display: ${isHidden ? "none" : "block"};
  `
);

const PanelOuter = styled.div(
  ({ theme, height = 0, isBorderless, isMarginless }) => css`
    background: ${theme.background0};
    border-radius: ${theme.radiusSmall}px;
    box-shadow: ${isBorderless ? "" : Shadows[height]};
    margin: ${isMarginless ? 0 : theme.marginDefault}px;
    padding: ${isBorderless ? 0 : theme.paddingDefault}px;
  `
);

const PanelInner = styled.div(
  ({ isCentered = false }) => css`
    align-items: ${isCentered ? "center" : "stretch"};
    display: flex;
    flex-direction: column;
  `
);

const Title = styled(Heading)(
  ({ theme, useSubTitle }) => css`
    font-size: ${useSubTitle ? 0.65 : 0.75}rem;
    margin: 0 ${useSubTitle ? theme.radiusSmall : theme.marginDefault}px;
    margin-top: ${useSubTitle ? theme.radiusSmall : theme.marginDefault}px;
    padding: 0 ${theme.radiusSmall}px;
  `
);

const ExpandableTitle = styled(Title)(
  ({ theme, useSubTitle }) => css`
    padding: ${useSubTitle ? theme.paddingSmall : theme.paddingDefault}px
      ${theme.radiusSmall}px;
    border-bottom: ${theme.borderDefault};
  `
);

const Panel = ({
  children,
  className,
  height,
  isBorderless = false,
  isCentered = false,
  isCollapsable,
  isExpandable,
  isHidden = false,
  isMarginless = false,
  onClick = () => null,
  title,
  useSubTitle = false,
}) => {
  const isResizable = isCollapsable != null || isExpandable != null;
  const [expanded, setExpanded] = useState(isCollapsable && !isExpandable);

  return (
    <Container isHidden={isHidden}>
      {title &&
        (isResizable ? (
          <ExpandableTitle
            icon={expanded ? <FaChevronUp /> : <FaChevronDown />}
            isExpanded={expanded}
            onClick={() => setExpanded(!expanded)}
            useSubTitle={useSubTitle}
          >
            {title}
          </ExpandableTitle>
        ) : (
          <Title useSubTitle={useSubTitle}>{title}</Title>
        ))}
      {((isResizable && expanded) || !isResizable) && (
        <PanelOuter
          className={className}
          height={height}
          isBorderless={isBorderless}
          isMarginless={isMarginless}
          onClick={onClick}
        >
          <PanelInner isCentered={isCentered}>{children}</PanelInner>
        </PanelOuter>
      )}
    </Container>
  );
};

export default Panel;
