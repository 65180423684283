import { DateTime } from 'luxon';
import { Row, RowCell } from '../library-components';
import styled, { css } from 'styled-components';

const ContainerRow = styled(Row)(({ theme }) => css`
  color: ${theme.colorPrimary};
  justify-content: center;
  padding-top: ${theme.paddingDefault}px;

  &:first-child {
    padding-top: 0;
  }
`);

const JaggedBreak = styled.div(({ theme }) => css`
  border-bottom: dotted 5px ${theme.colorDimmed1};
  border-top: ${theme.borderDefault};
  margin-top: 0;
  padding-top: ${theme.marginDefault}px;
  width: 100%;
`);

const SessionDateRow = ({ date = DateTime.utc(), lastOffset = 0 }) => {

  const jaggedBreak = lastOffset > 1 ? (
    <JaggedBreak />
  ) : null;

  return lastOffset !== 0 ? (
    <>
      {jaggedBreak}
      <ContainerRow>
        <RowCell
          primary={date.toLocaleString(DateTime.DATE_HUGE)}
        />
      </ContainerRow>
    </>
  ) : null;
};

export default SessionDateRow;
