import { DateTime } from "luxon";
import {
  FaCartPlus,
  FaChild,
  FaEdit,
  FaPlus,
  FaRegEye,
  FaRegEyeSlash,
  FaShoppingCart,
  FaTrash,
} from "react-icons/fa";
import styled, { css } from "styled-components";
import useEntity from "../hooks/useEntity";
import useNavigation from "../hooks/useNavigation";
import useUser, { isUserAdmin } from "../hooks/useUser";
import { formatCurrency, formatDate } from "../library-common";
import {
  addOptionBuilder,
  Icon,
  Row,
  RowCell,
  RowOptions,
  RowStatus,
} from "../library-components";
import {
  canPurchaseForChild,
  canPurchaseForSelf,
  isInBasket as funcIsInBasket,
} from "../models/bundle";
import {
  isAgeSuitable,
  qualificationMessage,
  statusFromExpiry,
} from "../models/common";
import { purchaseBundle, updateBundle } from "../userRequests";
import BundleAssignedToRow from "./BundleAssignedToRow";

const ContainerRow = styled(Row)(({ theme, isPublished }) => {
  const color = isPublished ? "" : theme.colorDisabled;

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const BundleRow = ({
  allowAdminActions = false,
  data: bundle = {},
  isDetailed = false,
}) => {
  const { entityId } = useEntity();
  const user = useUser();
  const { navigate } = useNavigation();

  const {
    costEach,
    durationMessage,
    recurranceMessage,
    id: bundleId,
    isPublished,
    name,
  } = bundle;

  const { basket, bundleAssignments = [], friends = {} } = user;

  const relevantAssignments = bundleAssignments.filter(
    ({ bundleId: id }) => id === bundleId
  );

  const assignmentExpiry = relevantAssignments
    .filter(({ assignedTo, orderedBy }) => assignedTo === orderedBy)
    .map(({ expires }) => expires)[0];

  const assignees = relevantAssignments
    .filter(({ assignedTo, orderedBy }) => assignedTo !== orderedBy)
    .map(({ assignedTo, expires }) => ({
      ...friends[assignedTo],
      assignedTo,
      expires,
    }));

  const isAdmin = isUserAdmin(user, entityId) && allowAdminActions;
  const isInBasket = funcIsInBasket(bundle, basket);

  const identityPayload = { bundleId };

  const options = [];
  const addOption = addOptionBuilder(bundle, options, user);

  const publish = (published) =>
    updateBundle({
      ...identityPayload,
      updates: { isPublished: published },
    });

  const navigateToBundle = () => navigate(`/bundles/${bundleId}`);

  const softDelete = () =>
    updateBundle({
      ...identityPayload,
      updates: { isDeleted: true },
    });

  const canEditOrDelete = () => isAdmin;
  const canPublish = () => isAdmin && !isPublished;
  const canUnPublish = () => isAdmin && isPublished;
  const canGoToBasket = () => isInBasket;
  const canDelete = () => isAdmin && !isPublished;

  addOption(
    canPurchaseForSelf(basket),
    costEach ? <Icon primary={FaCartPlus} /> : <Icon primary={FaPlus} />,
    purchaseBundle(identityPayload)
  );
  addOption(
    canPurchaseForChild,
    <Icon primary={FaChild} />,
    null,
    `/bundles/${bundleId}/purchaseOnBehalf`
  );
  addOption(
    canGoToBasket,
    <Icon primary={FaShoppingCart} />,
    null,
    "/basket",
    RowStatus.Warn
  );
  addOption(
    canEditOrDelete,
    <Icon primary={FaEdit} />,
    null,
    `/bundlemanager/edit/${bundleId}`
  );

  addOption(canPublish, <Icon primary={FaRegEye} />, publish(true));
  addOption(canUnPublish, <Icon primary={FaRegEyeSlash} />, publish(false));
  addOption(canDelete, <Icon primary={FaTrash} />, softDelete());

  let status;
  if (isInBasket) status = isPublished ? RowStatus.Warn : RowStatus.WarnDimmed;

  const minExpiry = assignees.reduce((acc, { expires }) => {
    if (!acc) return expires;
    return acc < expires ? acc : expires;
  }, assignmentExpiry);

  const rowStatus = statusFromExpiry(minExpiry, status);

  let qualification;
  if (!isAgeSuitable(bundle, user)) {
    qualification = qualificationMessage(bundle);
  }

  const now = DateTime.local();
  const expiresText = assignmentExpiry < now ? "Expired" : "Expires";

  return (
    <>
      <ContainerRow
        id={bundleId}
        isPublished={isPublished}
        onClick={navigateToBundle}
        status={rowStatus}
        noMargins
      >
        <RowCell
          primary={name}
          secondary={durationMessage}
          warning={
            assignmentExpiry
              ? `${expiresText}: ${formatDate(assignmentExpiry)}`
              : qualification
          }
        />
        <RowCell
          alignRight
          pixelWidth={80}
          primary={formatCurrency(costEach, true)}
          secondary={recurranceMessage}
        />
        <RowOptions entityId={entityId} options={options} />
      </ContainerRow>
      {assignees.map((assignment) => (
        <BundleAssignedToRow
          key={assignment.id}
          data={assignment}
          status={status}
        />
      ))}
      {isDetailed ? (
        <>
          <Row>
            <RowCell primary="Payments" />
          </Row>
        </>
      ) : null}
    </>
  );
};

export default BundleRow;
