import { Navigate, Route, Routes } from "react-router-dom";
import useAppState from "./hooks/useAppState";
import useUser from "./hooks/useUser";
import {
  BundleManager,
  BundleManagerEdit,
  ReportsMemberships,
  ReportsMonth,
  ReportsOverdue,
  ReportsToday,
  SessionManager,
  SessionManagerEdit,
  Settings,
  SettingsMenu,
  TimetableManager,
  TimetableManagerEdit,
  UserManager,
  UserPage,
} from "./pages/admin";
import { Basket } from "./pages/basket";
import { BundlePage, BundlePurchaseOnBehalf, Bundles } from "./pages/bundles";
import Home from "./pages/home";
import { Register, SignIn } from "./pages/onboarding";
import { Profile, ProfileEdit } from "./pages/profile";
import { SessionBookOnBehalf, SessionPage, Sessions } from "./pages/sessions";

const Content = () => {
  const user = useUser();
  const { isMenuShown, setIsMenuShown } = useAppState();
  const { name, userId } = user || {};

  const requireInfo = !name;

  const renderProfile = (isEditing = false) =>
    userId ? (
      requireInfo || isEditing ? (
        <ProfileEdit />
      ) : (
        <Profile />
      )
    ) : (
      <Navigate to="" />
    );

  let appStateContent;
  if (isMenuShown) {
    appStateContent = <SettingsMenu onClose={() => setIsMenuShown(false)} />;
  }

  const standardContent = (
    <>
      <Routes>
        <Route path="/register/*" exact element={<Register />} />
        <Route path="/signin/*" exact element={<SignIn />} />
        <Route path="/profile-edit/*" exact element={renderProfile(true)} />
        <Route path="/profile/*" exact element={renderProfile()} />

        <Route path="/bundlemanager" exact element={<BundleManager />} />
        <Route
          path="/bundlemanager/add"
          exact
          element={<BundleManagerEdit />}
        />
        <Route
          path="/bundlemanager/edit/:bundleId"
          exact
          element={<BundleManagerEdit />}
        />
        <Route path="/sessionmanager/*" exact element={<SessionManager />} />
        <Route
          path="/sessionmanager/add"
          exact
          element={<SessionManagerEdit />}
        />
        <Route
          path="/sessionmanager/edit/:sessionId"
          exact
          element={<SessionManagerEdit />}
        />
        <Route
          path="/timetablemanager/*"
          exact
          element={<TimetableManager />}
        />
        <Route
          path="/timetablemanager/add"
          exact
          element={<TimetableManagerEdit />}
        />
        <Route
          path="/timetablemanager/edit/:classId"
          exact
          element={<TimetableManagerEdit />}
        />
        <Route path="/users/*" exact element={<UserManager />} />
        <Route path="/users/:userId" exact element={<UserPage />} />

        <Route
          path="/reports/memberships"
          exact
          element={<ReportsMemberships />}
        />
        <Route path="/reports/month" exact element={<ReportsMonth />} />
        <Route path="/reports/today" exact element={<ReportsToday />} />
        <Route path="/reports/overdue" exact element={<ReportsOverdue />} />

        <Route path="/admin/*" exact element={<Settings />} />

        <Route path="/basket/*" exact element={<Basket />} />

        <Route path="/bundles/*" exact element={<Bundles />} />
        <Route path="/bundles/:bundleId" exact element={<BundlePage />} />
        <Route
          path="/bundles/:bundleId/purchaseOnBehalf"
          exact
          element={<BundlePurchaseOnBehalf />}
        />
        <Route path="/sessions/*" exact element={<Sessions />} />
        <Route path="/sessions/:sessionId" exact element={<SessionPage />} />
        <Route
          path="/sessions/:sessionId/bookOnBehalf"
          exact
          element={<SessionBookOnBehalf />}
        />
        <Route path="/" element={<Home />} />
      </Routes>
      {appStateContent}
    </>
  );

  return userId && requireInfo ? <ProfileEdit /> : standardContent;
};

export default Content;
