import { FaCheck, FaTimes, FaHourglass, FaQuestion } from "react-icons/fa";
import styled, { css } from "styled-components";

import { RequestTypeLabels } from "../../userRequests/RequestType";
import RequestStatus from "../../userRequests/RequestStatus";

const UnknownOutcomeIcon = <FaQuestion />;

const RequestOutcomeIcons = {
  [RequestStatus.None]: null,
  [RequestStatus.Busy]: <FaHourglass />,
  [RequestStatus.Complete]: <FaCheck />,
  [RequestStatus.Error]: <FaTimes />,
};

const Container = styled.div(({ outcome, theme }) => {
  const colors = {
    [RequestStatus.None]: theme.colorNeutral,
    [RequestStatus.Busy]: theme.colorWarn,
    [RequestStatus.Complete]: theme.colorOk,
    [RequestStatus.Error]: theme.colorError,
  };

  return css`
    & > svg {
      color: ${colors[outcome]};
      margin-right: ${theme.marginXSmall}px;
    }
  `;
});

const UserRequestResult = ({ result = {} }) => {
  const { type, outcome } = result;

  return (
    <Container outcome={outcome}>
      {RequestOutcomeIcons[outcome] || UnknownOutcomeIcon}
      {RequestTypeLabels[type] || type}
    </Container>
  );
};

export default UserRequestResult;
