import {
  addDoc,
  collection,
  doc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { DateTime } from "luxon";

import { Collections } from "../constants";
import RequestStatus from "./RequestStatus";

const executeUserRequest =
  (requestType) =>
  (payload = {}) =>
  (userId, entityId = null) =>
    new Promise(async (resolve, reject) => {
      const completeRequest = (response) =>
        resolve({
          ...response,
          success: true,
          isPartial: false,
        });

      const failRequest = (response) => {
        console.warn(response);

        reject({
          ...response,
          success: false,
        });
      };

      if (!requestType)
        return failRequest({
          error: {
            message: `No request type set`,
          },
        });

      const now = DateTime.utc();

      const newRequest = {
        entityId,
        type: requestType,
        requestData: payload,
        status: RequestStatus.None,
        created: now.toISO(),
      };

      try {
        let unsubscribe;

        const doUnsubscribe = () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };

        const handleSnapshot = (snap) => {
          const response = snap.data();
          const { status = RequestStatus.None } = response;

          if (status === RequestStatus.Complete) {
            doUnsubscribe();
            completeRequest(response);
          } else if (response.status === RequestStatus.Error) {
            doUnsubscribe();
            failRequest(response);
          }
        };

        const db = getFirestore();
        const docRef = collection(
          db,
          Collections.Users,
          userId,
          Collections.UserRequests
        );

        const requestDoc = await addDoc(docRef, newRequest);

        unsubscribe = onSnapshot(doc(docRef, requestDoc.id), handleSnapshot);
      } catch (error) {
        failRequest(error);
      }
    });

export default executeUserRequest;
