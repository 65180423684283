import styled, { css } from 'styled-components';
import { Shadows } from './constants';

const DrawerOuter = styled.div`
  bottom: 0;
  position: fixed;

  ${({ theme, height = 0, isBorderless }) => css`
    background: ${theme.background0};
    border-radius: ${theme.radiusDefault}px;
    box-shadow: ${isBorderless ? '' : Shadows[height]};
    margin: ${theme.marginDefault}px;
  `}
`;

const DrawerInner = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme, isCentered = false }) => css`
    align-items: ${isCentered ? 'center' : 'stretch'};
    padding: ${theme.paddingDefault}px;
  `}
`;

const Drawer = ({
  children,
  isCentered
}) => (
  <DrawerOuter>
    <DrawerInner isCentered={isCentered}>
      {children}
    </DrawerInner>
  </DrawerOuter>
);

export default Drawer;
