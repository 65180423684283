import { DateTime } from "luxon";
import { Area, AreaChart, Tooltip, XAxis } from "recharts";
import { useTheme } from "styled-components";

import { DefaultDailyTotals } from "../../hooks/useMonthlySales";
import { formatCurrency } from "../../library-common";
import { Panel } from "../../library-components";

const daysInPast = 28;
const daysInFuture = 7;

const SalesChart = ({ data }) => {
  const theme = useTheme();

  const from = DateTime.local().minus({ days: daysInPast }).startOf("day");

  const totalDays = daysInPast + 1 + daysInFuture;

  const days = Array(totalDays)
    .fill(null)
    .map((_, i) => {
      const thisDate = from.plus({ days: i });
      const dateISO = thisDate.toISODate();

      const thisData = data[dateISO] || DefaultDailyTotals;

      return {
        dateFormatted: from.plus({ days: i }).toFormat("dd LLL"),
        ...thisData,
      };
    });

  return (
    <Panel isCentered>
      <AreaChart width={350} height={150} data={days}>
        <XAxis
          dataKey="dateFormatted"
          tick={{
            fontSize: theme.fontSizeSmall,
          }}
        />
        <Tooltip
          contentStyle={{
            backgroundColor: theme.background0,
            borderColor: theme.background0light,
            fontSize: theme.fontSizeSmall,
          }}
          formatter={(val) => formatCurrency(val, false)}
        />
        <Area
          type="monotone"
          dataKey="actual"
          stackId="1"
          stroke={theme.colorPrimary}
          fill={theme.colorPrimaryLight}
          name="Net Sales"
        />
        <Area
          type="monotone"
          dataKey="fees"
          stackId="2"
          stroke={theme.colorDimmed0}
          fill={theme.colorDimmed1}
          name="Fees"
        />
        <Area
          type="monotone"
          dataKey="overdue"
          stackId="4"
          stroke={theme.colorError}
          fill={theme.colorError}
          name="Incomplete"
        />
      </AreaChart>
    </Panel>
  );
};

export default SalesChart;
