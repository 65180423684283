import styled from "styled-components";
import { Panel, Row, RowCell } from "../../library-components";
import { UserRequestResult } from "../common";

const TinyText = styled.p`
  font-size: 6pt;
`;

const BasicInfo = ({ data = {}, isSummary = false }) => {
  const {
    id: userId,
    ageMessage,
    birthday,
    lastError,
    lastRequestType,
    lastResult,
    lastSeenMessage,
    name,
  } = data;

  const summarise = (val) => (isSummary ? null : val);

  return (
    <>
      <Panel isBorderless>
        <TinyText>ID: {userId}</TinyText>
      </Panel>
      <Panel
        title={isSummary ? "" : "Basic Information"}
        isBorderless={isSummary}
        isMarginless={isSummary}
      >
        <Row>
          <RowCell
            primary={name}
            secondary={summarise("Name")}
            flexWidth={3}
            invert
          />
          {summarise(
            <RowCell primary={birthday} secondary="Birthday" invert />
          )}
          <RowCell
            primary={ageMessage}
            secondary={summarise("Age")}
            invert
            alignRight
          />
        </Row>
        <Row>
          <RowCell
            secondary={lastSeenMessage}
            tertiary={summarise("Last seen")}
            invert
          />
          <RowCell
            alignRight
            secondary={() => (
              <UserRequestResult
                result={{ type: lastRequestType, outcome: lastResult }}
              />
            )}
            warning={lastError}
            tertiary="Last Request"
            invert
          />
        </Row>
      </Panel>
    </>
  );
};

export default BasicInfo;
