import { FaEdit, FaTrash } from "react-icons/fa";
import styled from "styled-components";
import useEntity from "../hooks/useEntity";
import useUser, { isUserAdmin } from "../hooks/useUser";
import { formatCurrency, formatDuration, formatTime } from "../library-common";
import {
  addOptionBuilder,
  Icon,
  Row,
  RowCell,
  RowOptions,
} from "../library-components";
import { qualificationMessage } from "../models/common";
import { removeClass } from "../userRequests";
import SessionBookingsStatus from "./SessionBookingsStatus";

const ContainerRow = styled(Row)`
  justify-content: space-between;
`;

const ClassRow = ({ allowAdminActions = false, data: timetableClass = {} }) => {
  const { entityId } = useEntity();
  const user = useUser();

  const {
    coach,
    cost,
    dayOfTheWeek,
    duration,
    id: classId,
    starts,
    name,
  } = timetableClass;

  const isAdmin = isUserAdmin(user, entityId) && allowAdminActions;

  const identityPayload = { timetabledEventId: classId };

  const options = [];
  const addOption = addOptionBuilder(timetableClass, options, user);

  addOption(
    () => isAdmin,
    <Icon primary={FaEdit} />,
    null,
    `/timetablemanager/edit/${classId}`
  );
  addOption(
    () => isAdmin,
    <Icon primary={FaTrash} />,
    removeClass(identityPayload)
  );

  const qualification = qualificationMessage(timetableClass);

  return (
    <ContainerRow id={classId} noMargins>
      <RowCell
        pixelWidth={60}
        primary={formatTime(starts, dayOfTheWeek)}
        secondary={formatDuration(duration)}
      />
      <RowCell
        primary={name}
        secondary={`Coach: ${coach || "TBC"}`}
        warning={qualification}
      />
      <RowCell
        alignRight
        pixelWidth={80}
        primary={formatCurrency(cost, true)}
        secondary={() => (
          <SessionBookingsStatus data={timetableClass} onlyCapacity />
        )}
      />
      <RowOptions entityId={entityId} options={options} />
    </ContainerRow>
  );
};

export default ClassRow;
