import Image from './Image';
import styled, { css } from 'styled-components';

const Avatar = styled(Image)`

  ${({ theme, isLarge, isMedium }) => isLarge
    ? css`
      border: ${theme.borderDefault};
      border-radius: ${theme.radiusAvatarLarge}px;
      height: ${theme.iconAvatarLarge}px;
      width: ${theme.iconAvatarLarge}px;
    `
    : (isMedium
      ? css`
      border: ${theme.borderDefault};
      border-radius: ${theme.radiusAvatarMedium}px;
      height: ${theme.iconAvatarMedium}px;
      width: ${theme.iconAvatarMedium}px;
    `
      : css`
      border: ${theme.borderDefault};
      border-radius: ${theme.radiusAvatar}px;
      height: ${theme.iconAvatar}px;
      width: ${theme.iconAvatar}px;
    `)}
`;

export default Avatar
