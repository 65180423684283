import { DateTime } from "luxon";

import {
  useAppState,
  useEntity,
  useUser,
  useUserMemberships,
} from "../../hooks";
import { Button, Panel } from "../../library-components";
import { payOverdueItems } from "../../userRequests";
import MembershipRow from "./MembershipRow";
import MembershipSummaryRow from "./MembershipSummaryRow";

const MembershipInfo = ({
  data = {},
  isSummary = false,
  showPayButton = true,
}) => {
  const { notifyError } = useAppState();
  const { entityId } = useEntity();
  const { userId: currentUserId } = useUser();
  const userId = data.id || currentUserId;

  const memberships = useUserMemberships(userId);

  const expiresSorter = (a, b) => a.expires.diff(b.expires);

  const expired = memberships.filter(({ hasExpired }) => hasExpired);
  const active = memberships.filter(({ hasExpired }) => !hasExpired);

  const todayDate = DateTime.local().toISODate();
  const hasOverduePayments = active.reduce(
    (acc, { nextPaymentDate }) => acc || nextPaymentDate < todayDate,
    false
  );

  return (
    <>
      {memberships.length > 0 ? (
        <Panel
          title={isSummary ? "" : "Memberships"}
          isBorderless={isSummary}
          isMarginless={isSummary}
        >
          {active
            .sort(expiresSorter)
            .map((membership) =>
              isSummary ? (
                <MembershipSummaryRow key={membership.id} data={membership} />
              ) : (
                <MembershipRow key={membership.id} data={membership} />
              )
            )}
          {expired
            .sort(expiresSorter)
            .map((membership) =>
              isSummary ? (
                <MembershipSummaryRow
                  key={membership.id}
                  data={membership}
                  isDimmed
                />
              ) : (
                <MembershipRow key={membership.id} data={membership} isDimmed />
              )
            )}
        </Panel>
      ) : null}
      {showPayButton && hasOverduePayments && (
        <Panel isBorderless>
          <Button
            asyncAction={payOverdueItems()}
            isPrimary
            isWarning
            label="Pay overdue subscriptions"
            userId={userId}
            entityId={entityId}
            onError={notifyError}
          />
        </Panel>
      )}
    </>
  );
};

export default MembershipInfo;
