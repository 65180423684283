import { getAuth, signOut } from "firebase/auth";
import { DateTime } from "luxon";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { MinUserAge } from "../../constants";
import useNavigation from "../../hooks/useNavigation";
import useUpdatableFields from "../../hooks/useUpdatableFields";
import useUser from "../../hooks/useUser";
import { validateDateOfBirth, validateName } from "../../library-common";
import {
  Button,
  DateField,
  Form,
  Page,
  Panel,
  Paragraph,
  SelectionField,
  SideBySide,
  SingleLineField,
} from "../../library-components";
import { dehydrateUserProfile } from "../../models/user";
import { transformDate } from "../../tools/conversions";
import { updateProfile } from "../../userRequests";

const PrimaryParagraph = styled(Paragraph)(
  ({ theme }) => css`
    margin-top: ${theme.marginDefault}px;
  `
);

const SecondaryParagraph = styled(Paragraph)(
  ({ theme }) => css`
    color: ${theme.colorSecondary};
    font-size: ${theme.fontSizeXSmall};
    padding: ${theme.paddingXSmall}px 0;
  `
);

const ProfileEdit = () => {
  const [isBusy] = useState(false);
  const [errors, setErrors] = useState([]);
  const [updates, updateField, resetUpdates] = useUpdatableFields(
    {},
    setErrors
  );
  const { navigate } = useNavigation();
  const user = useUser();

  const genderOptions = {
    male: "Male",
    female: "Female",
    other: "Other",
    optOut: "I prefer not to say",
  };

  const dateOfBirthSubtext = `
    We'll never share your date of birth, we use it to calculate
    your age so we know which sessions you can join. Coaches and 
    Gym Administrators will be able to see your age, but we won't
    share this with other members or attendees.
  `;

  const genderSubtext = `
    Some of the gyms we support have classes which are gender specific,
    we need this information to determine your elegibility.
    If you'd rather not provide us with this information then some
    gender specific sessions may not be available to book.
  `;

  const onSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => navigate("/sessions"));
  };

  const { dateOfBirth, /*emergencyTelephone,*/ gender, name /*telephone*/ } = {
    ...user,
    ...updates,
  };

  const noUpdates =
    Object.entries(updates).filter(([, val]) => !!val).length === 0;

  const hasErrors = errors && errors.length > 0;

  const canChangeGender = false;
  const maxDateOfBirth = DateTime.utc()
    .startOf("day")
    .minus({ years: MinUserAge });

  return (
    <Page>
      <Panel title="Update your Profile">
        <Form>
          <Paragraph>
            In order to improve your experience and filter out sessions that may
            or may not be suitable, we need a few details from you.
          </Paragraph>
          <SingleLineField
            label="Name"
            validator={validateName}
            value={name}
            disabled={isBusy}
            onChange={updateField("name")}
            required
          />
          <PrimaryParagraph>
            We don't need your Date of Birth, however some age restricted
            sessions may be unavailable if you don't provide it.
          </PrimaryParagraph>
          <DateField
            label="Date of Birth"
            validator={validateDateOfBirth}
            value={dateOfBirth && dateOfBirth.toISODate()}
            disabled={isBusy}
            onChange={updateField("dateOfBirth", transformDate(dateOfBirth))}
            subText={dateOfBirthSubtext}
            maxDate={maxDateOfBirth}
          />
          {canChangeGender && (
            <SelectionField
              label="Gender"
              options={genderOptions}
              value={gender}
              disabled={isBusy}
              onChange={updateField("gender")}
              subText={genderSubtext}
            />
          )}
          <SecondaryParagraph>* denotes a required field</SecondaryParagraph>
          <SideBySide>
            <Button
              asyncAction={updateProfile({
                updates: dehydrateUserProfile(updates),
              })}
              disabled={noUpdates || hasErrors || isBusy}
              isPrimary
              label="Update"
              onComplete={resetUpdates}
              userId={user.userId}
            />
          </SideBySide>
          <Link to="/privacy-policy">View our Privacy Policy</Link>
          <SideBySide>
            <Button label="Sign Out" onClick={() => onSignOut()} />
          </SideBySide>
        </Form>
      </Panel>
    </Page>
  );
};

export default ProfileEdit;
