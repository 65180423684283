import styled, { css } from "styled-components";
import { Row, RowCell } from "../../library-components";

const ContainerRow = styled(Row)(
  ({ disabled, theme }) => css`
    color: ${disabled ? theme.colorDimmed1 : null};
    justify-content: space-between;

    & div {
      color: ${disabled ? theme.colorDimmed1 : null};
    }
  `
);

const BasketItemPaymentRow = ({
  className,
  data: item = {},
  unavailable = false,
}) => {
  const { id: itemId, orderId, orderCreated } = item;

  return (
    <ContainerRow
      className={className}
      id={itemId}
      disabled={unavailable}
      noMargins
    >
      <RowCell tertiary={orderId} />
      <RowCell tertiary={orderCreated.toLocaleString()} />
    </ContainerRow>
  );
};

export default BasketItemPaymentRow;
