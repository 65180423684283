import { OrderStatus } from "../../constants";
import { useBasket } from "../../hooks";
import { NoContent } from "../../library-components";
import { calculateComplexCost } from "../../models/common";
import BasketTotalsFuturePayments from "./BasketTotalsFuturePayments";
import BasketTotalsPayNow from "./BasketTotalsPayNow";

export const BasketTotals = () => {
  const { basketItems, incompleteOrders } = useBasket();

  const complexCost = calculateComplexCost(
    basketItems.filter(({ isAvailable = () => true }) => isAvailable()),
    incompleteOrders.filter(
      ({ status }) => status !== OrderStatus.AwaitingPayment
    )
  );

  const { futurePayments } = complexCost;
  const rowCount = basketItems.length + incompleteOrders.length;

  return (
    <>
      {rowCount > 0 ? (
        <BasketTotalsPayNow complexCost={complexCost} />
      ) : (
        <NoContent message="You have no items in your basket" />
      )}
      {futurePayments.length > 0 && (
        <BasketTotalsFuturePayments complexCost={complexCost} />
      )}
    </>
  );
};
