import { Panel } from '../../library-components';
import GoogleSignInButton from './GoogleSignInButton';

const SignInWithIdpPanel = () => (
  <Panel title="or...">
    <GoogleSignInButton />
  </Panel>
);

export default SignInWithIdpPanel;
