import styled, { css } from 'styled-components';

const Container = styled.div(({ theme }) => css`
  & > div {
    margin-top: ${theme.marginSmall}px
  }
`);

const DetailSection = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default DetailSection;
