import executeUserRequest from "./executeUserRequest";
import RequestType from "./RequestType";

export const addBundle = executeUserRequest(RequestType.AddBundle);
export const updateBundle = executeUserRequest(RequestType.UpdateBundle);

export const addClass = executeUserRequest(RequestType.AddClass);
export const updateClass = executeUserRequest(
  RequestType.UpdateTimetabledEvent
);
export const removeClass = executeUserRequest(RequestType.RemoveClass);
export const createClassSessions = executeUserRequest(
  RequestType.CreateClassSessions
);

export const addSession = executeUserRequest(RequestType.AddSession);
export const updateSession = executeUserRequest(RequestType.UpdateSession);
export const removeSession = executeUserRequest(RequestType.RemoveSession);

export const purchaseBundle = executeUserRequest(RequestType.PurchaseBundle);
export const purchaseSession = executeUserRequest(RequestType.PurchaseSession);
export const cancelBooking = executeUserRequest(RequestType.CancelBooking);

export const payOverdueItems = executeUserRequest(RequestType.PayOverdueItems);

export const payWithStripe = executeUserRequest(RequestType.PayWithStripe);
export const payWithAccount = executeUserRequest(RequestType.PayWithAccount);

export const takeScheduledPayment = executeUserRequest(
  RequestType.TakeScheduledPayment
);

export const updateProfile = executeUserRequest(RequestType.UpdateProfile);
export const cancelOrder = executeUserRequest(RequestType.CancelOrder);
export const cancelPurchase = executeUserRequest(RequestType.CancelPurchase);

export const addFriend = executeUserRequest(RequestType.AddFriend);

export const registerEntity = executeUserRequest(RequestType.RegisterEntity);
