import { DateTime } from 'luxon';
import { MinAge } from '../constants';

export const calculateAge = dt => dt
  ? Math.floor(DateTime.utc()
    .diff(dt)
    .as('years'))
  : 0;

const validateDateOfBirth = dateOfBirth => {
  const errors = [];

  if (dateOfBirth) {
    const dt = DateTime.fromISO(dateOfBirth);
    const age = calculateAge(dt);

    if (age < MinAge) {
      errors.push(`You have to be at least ${MinAge} years old to attend sessions.`);
    }
  }

  return { errors };
};

export default validateDateOfBirth;
