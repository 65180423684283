import { DateTime } from "luxon";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationButton } from "../../components";
import useAppState from "../../hooks/useAppState";
import useEntity from "../../hooks/useEntity";
import useNavigation from "../../hooks/useNavigation";
import useUpdatableFields from "../../hooks/useUpdatableFields";
import useUser from "../../hooks/useUser";
import { Button, Page, Panel, SideBySide } from "../../library-components";
import { addClass, updateClass } from "../../userRequests";
import ClassSchedulePanel from "./ClassSchedulePanel";
import PricingPanel from "./PricingPanel";
import RestrictionsPanel from "./RestrictionsPanel";
import SessionInformationPanel from "./SessionInformationPanel";

import {
  defaultModel as defaultClass,
  dehydrateClass,
} from "../../models/class";
import asLookup from "../../tools/asLookup";

const TimetableManagerEdit = ({ classId: classIdArg }) => {
  const { isBusy } = useAppState();
  const { classId: classIdParam } = useParams();
  const { entityId, classes } = useEntity();
  const { back } = useNavigation();
  const { userId } = useUser();
  const [errors, setErrors] = useState([]);
  const [updates, updateField] = useUpdatableFields({}, setErrors);

  const classId = classIdArg || classIdParam;
  const classesLookup = asLookup(classes);
  const timetableClass = classId ? classesLookup[classId] : {};

  const updated = {
    ...defaultClass,
    ...timetableClass,
    ...updates,
    lastUpdated: DateTime.utc(),
  };

  const noUpdates =
    Object.entries(updates).filter(([, val]) => val != null).length === 0;

  const hasErrors = errors && errors.length;

  const payload = {};

  if (classId) {
    payload.timetabledEventId = classId;
    payload.updates = dehydrateClass(updated);
  } else payload.details = dehydrateClass(updated);

  const title = classId
    ? "Editing Timetabled Class"
    : "Adding Timetabled Class";
  const buttonLabel = classId ? "Save Changes" : "Add Class";
  const buttonAction = classId ? updateClass : addClass;

  return (
    <Page>
      <Panel isBorderless>
        <h3>{title}</h3>
      </Panel>
      <SessionInformationPanel session={updated} onUpdateField={updateField} />
      <ClassSchedulePanel
        timetableClass={updated}
        onUpdateField={updateField}
      />
      <PricingPanel
        data={updated}
        onUpdateField={updateField}
        allowMembershipPricing
      />
      <RestrictionsPanel data={updated} onUpdateField={updateField} />
      <Panel isBorderless>
        <SideBySide fitContent>
          <Button
            asyncAction={buttonAction(payload)}
            disabled={noUpdates || hasErrors || isBusy}
            entityId={entityId}
            label={buttonLabel}
            onComplete={back}
            userId={userId}
            isPrimary
          />
          <NavigationButton label="Cancel" isBackButton disabled={isBusy} />
        </SideBySide>
      </Panel>
    </Page>
  );
};

export default TimetableManagerEdit;
