import { FaTimes } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { Page } from '../library-components';
import { IconButton } from '.';

const FullScreenContainer = styled(Page)(({ theme }) => css`
  background: ${theme.background1};
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  & > div {
    display: flex;
    flex: auto;
    flex-direction: column;
  }
`);

const Header = styled.div(({ theme }) => css`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.paddingSmall}px ${theme.paddingDefault}px;
`);

const Content = styled.div(({ theme }) => css`
  background: ${theme.background0};
  flex: auto;
`);

const FullScreenPage = ({ children, onClose }) => (
  <FullScreenContainer>
    {onClose && <Header>
      <IconButton
        icon={<FaTimes />}
        onClick={onClose}
      />
    </Header>}
    <Content>
      {children}
    </Content>
  </FullScreenContainer>
);

export default FullScreenPage;
