import { useParams } from 'react-router';
import styled, { css } from 'styled-components';
import { BundleTypeNames } from '../../constants';
import { Panel, Spinner } from '../../library-components';
import useEntity from '../../hooks/useEntity';
import asLookup from '../../tools/asLookup';
import { qualificationMessage } from '../../models/common';

const Name = styled.h2(({ theme }) => css`
  color: ${theme.colorPrimary};
`);

const Duration = styled.h3(({ theme }) => css`
  font-size: ${theme.fontSizeDefault};
`);

const Qualification = styled.p(({ theme }) => css`
  padding: ${theme.paddingDefault}px 0;
  font-size: ${theme.fontSizeSmall};
  color: ${theme.colorSecondary};
`);

const BundleInfo = () => {
  const { bundles } = useEntity();
  const { bundleId } = useParams();

  const bundleLookup = asLookup(bundles);
  const bundle = bundleLookup[bundleId];

  if (!bundle) return <Spinner />;

  const {
    name,
    durationMessage,
    recurranceMessage,
    type
  } = bundle;

  let qualification = qualificationMessage(bundle);

  return <>
    <Panel title={`${BundleTypeNames[type]} Details`}>
      <Name>{name}</Name>
      <Duration>{durationMessage}</Duration>
      {recurranceMessage && <Duration>Payments: {recurranceMessage}</Duration>}
      <Qualification>{qualification}</Qualification>
    </Panel>
  </>;
};

export default BundleInfo;
