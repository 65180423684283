const mapCollection = (
  onComplete = () => null,
  hydrate = item => item,
  sortFunc,
  pathExtractions = {},
  keyAccessor
) => (snapshot) => {
  const mapper = doc => {
    let data = { ...doc.data(), id: doc.id };

    // extract path params
    const pathParts = doc.ref.path.split('/');
    data = Object.entries(pathExtractions).reduce(
      (acc, [idx, propName]) => ({ ...acc, [propName]: pathParts[idx] }),
      data
    );

    return data;
  };

  const mapped = snapshot.docs
    .map(mapper)
    .map(hydrate);

  if (keyAccessor) {
    onComplete(mapped.reduce((acc, entry) => ({
      ...acc,
      [keyAccessor(entry)]: entry
    }), {}));
  } else {
    if (sortFunc) {
      mapped.sort(sortFunc);
    }
    onComplete(mapped);
  }
};

export default mapCollection;
