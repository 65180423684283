import { OrderItemType } from "../../constants";
import useEntity from "../../hooks/useEntity";
import useNavigation from "../../hooks/useNavigation";
import useUser from "../../hooks/useUser";
import { formatCurrency } from "../../library-common";
import { Button, Panel } from "../../library-components";
import { payWithAccount } from "../../userRequests";

const PaymentMethodsAccount = () => {
  const { entityId } = useEntity();
  const { navigate } = useNavigation();
  const { balance, basket, userId } = useUser();
  const availableFunds = balance[entityId];

  const message = `Use account credit, balance is ${formatCurrency(
    availableFunds
  )}`;

  const isAlreadyUsed =
    basket.filter(
      ({ contextType }) => contextType === OrderItemType.AccountTransaction
    ).length > 0;
  const canUseCredit = availableFunds > 0 && !isAlreadyUsed;

  const onCompletePayment = ({
    result: { order: { id: orderId } = {} } = {},
  }) => {
    if (orderId) {
      navigate("/");
    }
  };

  return (
    canUseCredit && (
      <Panel isBorderless>
        <Button
          asyncAction={payWithAccount({ entityId })}
          onComplete={onCompletePayment}
          entityId={entityId}
          isPrimary
          label={message}
          userId={userId}
        />
      </Panel>
    )
  );
};

export default PaymentMethodsAccount;
