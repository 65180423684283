import { useEffect, useState } from 'react';
import getCountries from '../tools/countries';

const useReferenceData = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let cancelled = false;

    getCountries()
      .then(countries => {
        if (!cancelled) {
          setCountries(countries);
        }
      })
      .catch(console.error);

    return () => cancelled = true;
  }, []);

  return {
    countries
  };
};

export default useReferenceData;