import { DateTime } from "luxon";
import { useContext } from "react";
import { EntityContext } from "../providers/entityProvider";
import asLookup from "../tools/asLookup";
import useConfig from "./useConfig";

const useEntity = (passedEntityId) => {
  const entityContext = useContext(EntityContext);
  const { entityId: tenantId } = useConfig();

  if (!entityContext) return {};
  const [state] = entityContext;

  const { entities, sessions } = state;

  const now = DateTime.utc();
  const entitiesLookup = asLookup(entities);

  const current = entitiesLookup[passedEntityId || tenantId];
  const futureSessions = sessions.filter(
    ({ starts, duration }) => now <= starts.plus({ minutes: duration })
  );
  const pastSessions = sessions.filter(
    ({ starts, duration }) => now > starts.plus({ minutes: duration })
  );

  return {
    ...state,
    current,
    entityId: passedEntityId || tenantId,
    futureSessions,
    pastSessions,
  };
};

export const useEntityUser = (userId) => {
  const { entityUsers } = useEntity();
  return entityUsers[userId] || {};
};

export default useEntity;
