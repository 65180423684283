import styled, { css } from 'styled-components';

const Container = styled.div(({ theme }) => css`
  font-size: ${theme.fontSizeDefault};
  padding: ${theme.paddingLarge}px ${theme.paddingXLarge}px;
  text-align: center;
`);

const NoContent = ({ message }) => (
  <Container>
    {message}
  </Container>
);

export default NoContent;
