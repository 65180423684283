import { DateTime } from "luxon";
import styled, { css } from "styled-components";
import { useUser } from "../../hooks";
import { formatCurrency } from "../../library-common";
import { Row, RowCell, RowStatus } from "../../library-components";

const StyledRow = styled(Row)(
  ({ isDimmed = false, theme }) => css`
    font-size: ${isDimmed ? theme.fontSizeSmall : "inherit"} !important;

    div {
      color: ${isDimmed ? theme.colorDimmed1 : "inherit"} !important;
    }
  `
);

const MembershipRow = ({ data, isDimmed = false }) => {
  const {
    assignedToId,
    durationMessage,
    expiresMessage,
    expiresSoon,
    hasExpired,
    id,
    isSuspended,
    suspendedReason,
    name,
    nextPaymentAmount,
    nextPaymentDate,
  } = data;

  const { friends = {} } = useUser();

  const now = DateTime.utc();
  const nextPaymentOverdue = DateTime.fromISO(nextPaymentDate) < now;

  const rowStatus =
    isSuspended || hasExpired
      ? RowStatus.Error
      : expiresSoon || nextPaymentOverdue
      ? RowStatus.Warn
      : RowStatus.Ok;

  const warningMessage = !hasExpired && expiresSoon ? expiresMessage : null;
  const nextPaymentMessage = nextPaymentDate
    ? nextPaymentOverdue
      ? `Overdue amount ${formatCurrency(
          nextPaymentAmount
        )}, due ${nextPaymentDate}`
      : `Next Payment ${formatCurrency(
          nextPaymentAmount
        )} on ${nextPaymentDate}`
    : null;

  let errorMessage = null;
  let nameLine = name;
  if (hasExpired) errorMessage = expiresMessage;
  else if (isSuspended) {
    errorMessage = suspendedReason;
    nameLine += " (Suspended)";
  }

  const friendName = friends[assignedToId]?.name;

  return (
    <StyledRow key={id} status={rowStatus} isDimmed={isDimmed}>
      <RowCell
        primary={nameLine}
        secondary={nextPaymentMessage}
        tertiary={hasExpired || expiresSoon ? null : expiresMessage}
        warning={warningMessage}
        error={errorMessage}
        flexWidth={3}
      />
      <RowCell
        primary={durationMessage}
        warning={friendName}
        secondary={"Duration"}
        alignRight
        invert
      />
    </StyledRow>
  );
};

export default MembershipRow;
