import { DateTime } from "luxon";
import { OrderItemType } from "../constants";
import { isAdult, isAgeSuitable, isGenderSuitable } from "./common";

export const hydrateSession = (raw) => {
  const hydrated = { ...raw };
  const { bookings: { confirmedIds = [] } = {} } = hydrated;

  hydrated.createdISO = raw.created;
  hydrated.created = DateTime.fromISO(raw.created);

  hydrated.lastUpdatedISO = raw.lastUpdated;
  hydrated.lastUpdated = DateTime.fromISO(raw.lastUpdated);

  hydrated.startsISO = raw.starts;
  hydrated.starts = DateTime.fromISO(raw.starts);

  hydrated.isInPast = (dt = DateTime.utc()) =>
    hydrated.starts + raw.duration <= dt;
  hydrated.isFull = raw.capacity <= confirmedIds.length;
  hydrated.isCancellable = raw.isCancellable || true;
  hydrated.isAvailable = () => !hydrated.isInPast() && raw.isPublished;

  return hydrated;
};

export const dehydrateSession = (hydrated) => {
  const raw = { ...hydrated };

  raw.created = hydrated.created.toISO();
  raw.lastUpdated = hydrated.lastUpdated.toISO();
  raw.starts = hydrated.starts.toISO();

  delete raw.createdISO;
  delete raw.lastUpdatedISO;
  delete raw.startsISO;

  delete raw.isInPast;
  delete raw.isFull;
  delete raw.isCancellable;
  delete raw.isAvailable;

  return raw;
};

export const hydrateSessionBooking = (raw) => {
  const hydrated = { ...raw };

  hydrated.created = DateTime.fromISO(raw.created);
  if (raw.bookedFor && raw.bookedFor.dateOfBirth) {
    hydrated.bookedFor.dateOfBirth = DateTime.fromISO(
      raw.bookedFor.dateOfBirth
    );
  }

  return hydrated;
};

export const defaultModel = hydrateSession({
  capacity: 20,
  duration: 60,
  cost: 0,
  starts: DateTime.local().plus({ hours: 1 }).startOf("hour"),
});

export const isBooked = (session, user, includeFriends = false) => {
  const { bookings: { confirmedIds = [] } = {} } = session;
  const { friends = {}, id } = user;

  if (confirmedIds.includes(id)) return true;

  if (includeFriends) {
    return Object.values(friends).reduce(
      (acc, { id }) => acc || confirmedIds.includes(id),
      false
    );
  }

  return false;
};

export const isInBasket = (session, basketItems = []) =>
  basketItems.filter(
    ({ contextType, contextId }) =>
      contextType === OrderItemType.Session && contextId === session.id
  ).length > 0;

export const costForUser = (session, user) => {
  const { bundleAssignments = [] } = user;
  const { cost: rrp, hasMembershipPricing, membershipPricing = {} } = session;

  const now = DateTime.utc();

  let cost = rrp;
  if (hasMembershipPricing) {
    const bundleIds = bundleAssignments
      .filter(({ expires }) => expires && expires > now)
      .map(({ bundleId }) => bundleId);

    cost = Object.entries(membershipPricing)
      .filter(([, price]) => price !== null) // This will make the item free if not filtered
      .reduce(
        (acc, [bundleId, price]) =>
          bundleIds.indexOf(bundleId) >= 0 ? (price < acc ? price : acc) : acc,
        rrp
      );
  }

  return cost;
};

export const canBookForSelf = (basket) => (session, user) => {
  const { isFull, isInPast, isPublished } = session;

  if (!isPublished) return false;
  if (isInPast()) return false;
  if (isFull) return false;

  if (isBooked(session, user)) return false;
  if (isInBasket(session, basket)) return false;

  if (!isAgeSuitable(session, user)) return false;
  if (!isGenderSuitable(session, user)) return false;

  return true;
};

export const canBookForFriend = (session) => (friend, user) => {
  const { allowGuests = true, isFull, isInPast, isPublished } = session;

  if (!isPublished) return false;
  if (isInPast()) return false;
  if (isFull) return false;

  if (isAgeSuitable(session, friend)) return false;

  return allowGuests;
};

export const canBookForChild = (session, user) => {
  const { allowGuests = true, isFull, isInPast, isPublished } = session;

  if (!isPublished) return false;
  if (isInPast()) return false;
  if (isFull) return false;

  if (isAgeSuitable(session, user)) return false;
  if (!isAdult(user)) return false;

  return allowGuests;
};

export const canCancelBooking = (session, user) => {
  const { isCancellable, isInPast } = session;

  if (isInPast()) return false;
  if (!isCancellable) return false;

  if (!isBooked(session, user)) return false;

  return true;
};
