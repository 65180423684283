import dehydrate from './dehydrate';
import hydrate from './hydrate';
import logic from './logic';

import hydrateDailyTotal from './hydrateDailyTotal';

const model = {
    ...logic,
    dehydrate,
    hydrate,
    hydrateDailyTotal
};

export default model;
