import styled, { css } from 'styled-components';

const Container = styled.div(({ theme }) => css`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: ${theme.paddingDefault}px;
`);

const Number = styled.div(({ theme }) => css`
  color: ${theme.colorPrimary};
  font-size: ${theme.fontSizeXLarge};
`);

const Label = styled.label(({ theme }) => css`
  font-size: ${theme.fontSizeXSmall};
  text-align: center;
`);

const BigNumber = ({
  formatter = val => val,
  label,
  value
}) => (
  <Container>
    <Number>{formatter(value)}</Number>
    <Label>{label}</Label>
  </Container>
);

export default BigNumber;
