import styled, { css } from "styled-components";
import { IconButton, IconNavigationButton } from ".";
import useEntity from "../hooks/useEntity";
import useUser from "../hooks/useUser";

const Container = styled.div(
  ({ theme }) => css`
    align-self: stretch;
    background: ${theme.background1};
    display: flex;
    justify-content: flex-end;
    padding: ${theme.marginXSmall}px ${theme.marginDefault}px;

    button + button {
      margin-left: ${theme.marginSmall}px;
    }
  `
);

const ActionBar = ({ actions = [] }) => {
  const { userId } = useUser();
  const { entityId } = useEntity();

  return (
    <Container id="action-bar">
      {actions.map(({ action, icon, link }, key) =>
        link ? (
          <IconNavigationButton
            key={key}
            icon={icon}
            isPrimary
            link={link}
            entityId={entityId}
          />
        ) : (
          <IconButton
            asyncAction={action}
            key={key}
            icon={icon}
            isPrimary
            userId={userId}
            entityId={entityId}
          />
        )
      )}
    </Container>
  );
};

export default ActionBar;
