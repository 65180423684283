import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import styled from 'styled-components';

import { Button } from '../../library-components';
import googleLogo from './assets/google.png';
import googleLogoDimmed from './assets/google-dimmed.png';
import useAppState from '../../hooks/useAppState';
import useNavigation from '../../hooks/useNavigation';

const GoogleButton = styled(Button)`
  border-color: #de5246;
  color: #de5246;

  :hover {
    background-color: #de5246;
    color: #fff;
  }

  :disabled {
    background-color: ${({ theme }) => theme.background0};
    color: ${({ theme }) => theme.colorDisabled};
  }
`;

const GoogleSignInButton = () => {
  const { isBusy, setIsBusy, notifyError } = useAppState();
  const { navigate } = useNavigation();

  const auth = getAuth();

  const doSignIn = () => {
    setIsBusy(true);

    const provider = new GoogleAuthProvider(auth);
    signInWithPopup(auth, provider)
      .then(() => {
        setIsBusy(false);
        navigate('/');
      })
      .catch(error => {
        notifyError(error);
        setIsBusy(false);
      });
  };

  return (
    <GoogleButton
      image={isBusy ? googleLogoDimmed : googleLogo}
      label="Sign in with Google"
      onClick={doSignIn}
      disabled={isBusy}
    />
  );
};

export default GoogleSignInButton;
