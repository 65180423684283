import { FaCartPlus, FaPlus, FaUserPlus } from "react-icons/fa";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { FriendList } from "../../components";
import useAppState from "../../hooks/useAppState";
import useEntity from "../../hooks/useEntity";
import useNavigation from "../../hooks/useNavigation";
import useUser from "../../hooks/useUser";
import { Icon, Page, Panel, Spinner } from "../../library-components";
import { isAgeSuitable } from "../../models/common";
import { costForUser as funcCostForUser } from "../../models/session";
import asLookup from "../../tools/asLookup";
import { purchaseSession } from "../../userRequests";
import SessionInfo from "./SessionInfo";

const SmallerText = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fontSizeSmall};
  `
);

const SessionBookOnBehalf = ({ isChild = true }) => {
  const { notifyError } = useAppState();
  const { entityId, sessions } = useEntity();
  const { back, navigate } = useNavigation();
  const { sessionId } = useParams();
  const { userId } = useUser();

  const sessionLookup = asLookup(sessions);
  const session = sessionLookup[sessionId];

  if (!session) return <Spinner />;

  const onSelectFriend = async (friend) => {
    try {
      const purchaseFunc = purchaseSession({
        sessionId,
        onBehalfOfId: friend.id,
      });

      const { success, resultData } = await purchaseFunc(userId, entityId);
      if (success) {
        const { orderItemId } = resultData;
        if (orderItemId) navigate("/basket");
        else back();
      }
    } catch ({ error }) {
      notifyError(error);
    }
  };

  const onConfigureOptions = (friend) => {
    const isSuitable = isAgeSuitable(session, friend);

    const options = [];
    if (isSuitable) {
      const costForUser = funcCostForUser(session, friend);

      // [TODO: This will always be 0 for friends as we don't load bundle assignments for friends]
      let icon =
        costForUser > 0 ? (
          <Icon primary={FaCartPlus} />
        ) : (
          <Icon primary={FaUserPlus} />
        );

      icon = <FaPlus />;

      options.push({ icon, action: () => onSelectFriend(friend) });
    }

    return options;
  };

  const headMessage = isChild
    ? "You are booking this session on behalf of a child. Please select the child below or add an new Child to the list. We will require their name and date of birth (to calculate their age)."
    : "You are booking on behalf of a friend.";

  return (
    <Page>
      <SessionInfo />
      <Panel title="Booking on behalf of another">
        <SmallerText>{headMessage}</SmallerText>
      </Panel>
      <FriendList
        isChild={isChild}
        onConfigureOptions={onConfigureOptions}
        onSelectFriend={onSelectFriend}
      />
    </Page>
  );
};

export default SessionBookOnBehalf;
