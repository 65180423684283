import styled from "styled-components";
import { Page, Panel } from "../../library-components";
import UserList from "./UserList";

import { useEntity, useUsers } from "../../hooks";

const StyledPanel = styled(Panel)`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const UserManager = () => {
  const { entityId } = useEntity();
  const users = useUsers(entityId);

  const userLists = [
    {
      isExpanded: true,
      title: `Users with Recent Activity (${users.length})`,
      users,
      showEmpty: true,
    },
  ];

  return (
    <Page>
      {userLists.map(
        (
          {
            getStatus,
            renderIcon,
            renderMore,
            users = [],
            showEmpty,
            title,
            isExpanded = false,
          },
          key
        ) =>
          users.length > 0 || showEmpty ? (
            <StyledPanel
              title={title}
              key={key}
              isExpandable={!isExpanded}
              isCollapsable={isExpanded}
            >
              <UserList
                users={users}
                getStatus={getStatus}
                renderIcon={renderIcon}
                renderMore={renderMore}
                allowAdminActions
              />
            </StyledPanel>
          ) : null
      )}
    </Page>
  );
};

export default UserManager;
