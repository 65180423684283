import { OverdueScheduledPayments } from "../../components/reportSales";
import { useOverduePayments } from "../../hooks";
import { Page } from "../../library-components";

const ReportsOverdue = () => {
  const overduePayments = useOverduePayments();

  return (
    <Page>
      <OverdueScheduledPayments data={overduePayments} />
    </Page>
  );
};

export default ReportsOverdue;
