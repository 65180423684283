import { DateTime } from "luxon";
import { CadenceOptions, OrderItemType } from "../constants";
import { isAdult, isAgeSuitable, isGenderSuitable } from "./common";

export const BundleType = {
  //BlockBooking: 'block-booking',
  Membership: "membership",
  Subscription: "subscription",
};

export const BundleTypeLabels = {
  //[BundleType.BlockBooking]: 'Block Booking',
  [BundleType.Membership]: "Membership",
  [BundleType.Subscription]: "Subscription",
};

export const hydrateBundle = (raw) => {
  const hydrated = { ...raw };

  hydrated.createdISO = raw.created;
  hydrated.created = DateTime.fromISO(raw.created);

  hydrated.lastUpdatedISO = raw.lastUpdated;
  hydrated.lastUpdated = DateTime.fromISO(raw.lastUpdated);

  hydrated.durationMessage = raw.duration
    ? [raw.duration, raw.durationUnit, BundleTypeLabels[raw.type]].join(" ")
    : BundleTypeLabels[raw.type];

  hydrated.recurranceMessage =
    raw.costCadenceCount > 1
      ? [raw.costCadenceCount, "x", raw.costCadence, raw.costCadenceUnit].join(
          " "
        )
      : "One off";

  return hydrated;
};

export const dehydrateBundle = (hydrated) => {
  const raw = { ...hydrated };

  raw.created = hydrated.created.toISO();
  raw.lastUpdated = hydrated.lastUpdated.toISO();

  delete raw.createdISO;
  delete raw.lastUpdatedISO;
  delete raw.startsISO;
  delete raw.durationMessage;
  delete raw.recurranceMessage;

  return raw;
};

export const defaultModel = hydrateBundle({
  type: BundleType.Membership,
  costEach: 0,
  costCadenceCount: 1,
  costCadenceUnit: CadenceOptions.Months,
  durationUnit: CadenceOptions.Months,
});

const filterActiveById =
  (bundle) =>
  ({ bundleId, expires }) =>
    bundleId === bundle.id && expires > DateTime.utc();

export const assignmentExpiry = (bundle, user) => {
  const { bundleAssignments = [] } = user;

  return bundleAssignments
    .filter(filterActiveById(bundle))
    .reduce(
      (acc, { expires }) => (!acc || expires > acc ? expires : null),
      null
    );
};

export const isInBasket = (bundle, basketItems = []) =>
  basketItems.filter(
    ({ contextType, contextId }) =>
      contextType === OrderItemType.Bundle && contextId === bundle.id
  ).length > 0;

export const isActive = (bundle, { bundleAssignments = [] }) =>
  bundleAssignments.filter(filterActiveById(bundle)).length > 0;

export const canPurchaseForChild = (bundle, user) => {
  const { isFull = false, isPublished } = bundle;

  if (!isPublished) return false;
  if (isFull) return false;

  if (isAgeSuitable(bundle, user)) return false;
  if (!isAdult(user)) return false;

  return true;
};

export const canPurchaseForSelf = (basket) => (bundle, user) => {
  const { isFull = false } = bundle;

  if (isFull) return false;

  if (isActive(bundle, user)) return false;
  if (isInBasket(bundle, basket)) return false;

  if (!isAgeSuitable(bundle, user)) return false;
  if (!isGenderSuitable(bundle, user)) return false;

  return true;
};
