import styled, { css, keyframes } from 'styled-components';

const stretchDelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
`;

const SpinnerOuter = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinnerInner = styled.div(({ isTiny, theme }) => css`
  margin: ${isTiny ? '0' : '100px auto'};
  width: ${isTiny ? '40px' : '50px'};
  height: ${isTiny ? '20px' : '40px'};
  text-align: center;
  font-size: 10px;

  & > ${Rect} {
    background-color: ${isTiny ? theme.color0 : theme.background2};
    width: ${isTiny ? '3px' : '6px'};
  }
`);

const Rect = styled.div`
  height: 100%;
  margin: 0 1px;
  display: inline-block;

  -webkit-animation: ${stretchDelay} 1.2s infinite ease-in-out;
  animation: ${stretchDelay} 1.2s infinite ease-in-out;
`;

const Rect1 = styled(Rect)`
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
`;

const Rect2 = styled(Rect)`
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
`;

const Rect3 = styled(Rect)`
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
`;

const Rect4 = styled(Rect)`
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
`;

const Rect5 = styled(Rect)`
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
`;

const Spinner = ({ isTiny = false }) => (
  <SpinnerOuter>
    <SpinnerInner isTiny={isTiny}>
      <Rect1 />
      <Rect2 />
      <Rect3 />
      <Rect4 />
      <Rect5 />
    </SpinnerInner>
  </SpinnerOuter>
);

export default Spinner;