import EntityLogo from './EntityLogo';
import { Row, RowCell } from '../library-components';
import styled from 'styled-components';
import useEntity from '../hooks/useEntity';

const ContainerRow = styled(Row)`
  justify-content: space-between;
`;

const EntityRow = ({ data, entityId }) => {
  const { current } = useEntity(entityId);
  const entity = data || current || {};

  const { aliases = [], id, settings: { city, country, name } = {} } = entity;

  const onNavigate = () => {
    window.location.href = `/?alias=${aliases[0] || id}`;
  };

  return (
    <ContainerRow id={id} onClick={() => onNavigate()}>
      <RowCell
        primary={name}
        secondary={[city, country].join(', ')}
      />
      <RowCell
        alignRight
        pixelWidth={50}
        primary={() => <EntityLogo
          entity={entity}
          isAvatar
        />}
      />
    </ContainerRow>
  );
}

export default EntityRow;
