import styled, { css } from "styled-components";
import Button from "./Button";

const CriticalButton = styled(Button)(
  ({ theme }) => css`
    background: ${theme.colorError};
    border-color: ${theme.colorError};
    color: ${theme.background0};
    width: 100%;
  `
);

export default CriticalButton;
