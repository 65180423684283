import styled from "styled-components";
import { RequireFeaturePanel } from "../../components";
import { FeatureSwitches } from "../../constants";
import { useEntity, useUserOrders } from "../../hooks";
import OrderRow from "./OrderRow";

const StyledPanel = styled(RequireFeaturePanel)`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const OrderInfo = ({ data = {}, isSummary = false }) => {
  const { id } = data;
  const { entityId } = useEntity();
  const { historicOrders, orders } = useUserOrders(id, entityId);

  return (
    <StyledPanel
      feature={FeatureSwitches.MemberOrders}
      title={isSummary ? "" : "Orders"}
      isBorderless={isSummary}
      isMarginless={isSummary}
    >
      {orders.map((order, key) => (
        <OrderRow data={order} isSummary={isSummary} key={key} />
      ))}
      <pre>{JSON.stringify(historicOrders, null, 2)}</pre>
    </StyledPanel>
  );
};

export default OrderInfo;
