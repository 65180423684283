import { useCallback, useContext } from "react";
import { Actions, AppStateContext } from "../providers/appStateProvider";

const useAppState = () => {
  const [state, dispatch] = useContext(AppStateContext);

  const clearNotification = useCallback(
    (index) => {
      dispatch({
        type: Actions.ClearNotification,
        payload: index,
      });
    },
    [dispatch]
  );

  const notifyError = useCallback(
    (error) => {
      dispatch({
        type: Actions.NotifyError,
        payload: error,
      });
    },
    [dispatch]
  );

  const setIsBusy = useCallback(
    (isBusy, busyMessage) => {
      dispatch({
        type: Actions.SetIsBusy,
        payload: { isBusy, busyMessage },
      });
    },
    [dispatch]
  );

  const setIsMenuShown = useCallback(
    (isMenuShown) => {
      dispatch({
        type: Actions.SetIsMenuShown,
        payload: isMenuShown,
      });
    },
    [dispatch]
  );

  const wrappedNotifyError = useCallback(
    (errorContext) => (error) =>
      notifyError({
        error,
        errorContext,
      }),
    [notifyError]
  );

  return {
    ...state,
    clearNotification,
    notifyError,
    setIsBusy,
    setIsMenuShown,
    wrappedNotifyError,
  };
};

export default useAppState;
