import { DefaultEntityName } from '../constants';
import styled from 'styled-components';
import useEntity from '../hooks/useEntity';

const Container = styled.div`
  padding: ${({ theme }) => theme.paddingDefault}px;
`;

const EntityName = ({ entity }) => {
  const { current } = useEntity();
  const { settings: { name } = {} } = entity || current || {};

  return (
    <Container>
      {name || DefaultEntityName}
    </Container>
  );
}

export default EntityName;
