import { doc } from "firebase/firestore";
import { useCallback } from "react";

import { Collections } from "../constants";
import useEntity from "./useEntity";
import useFirestoreDocument from "./useFirestoreDocument";
import userProfileModel from "../models/userProfile";

const useUserProfile = (userId) => {
  const { entityId } = useEntity();

  const refProvider = useCallback(
    (db) =>
      userId && entityId
        ? doc(
            db,
            Collections.Entities,
            entityId,
            Collections.UserAccess,
            userId
          )
        : null,
    [entityId, userId]
  );

  const hydrator = useCallback((raw) => userProfileModel.hydrate(raw), []);

  return useFirestoreDocument(refProvider, hydrator);
};

export default useUserProfile;
