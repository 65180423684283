import { Button } from '../library-components';
import useNavigation from '../hooks/useNavigation';

const NavigationButton = (props) => {
  const { back, navigate } = useNavigation();
  const { isBackButton, link } = props;

  const onNavigate = () => {
    if (isBackButton) back();
    else navigate(link);
  };

  return (
    <Button {...props} onClick={() => onNavigate()} />
  );
};

export default NavigationButton;
