import { Panel, Paragraph, SideBySide } from '../library-components';
import { NavigationButton } from '.';
import useUser from '../hooks/useUser';

const SignUp = () => {
  const { userId } = useUser();

  return userId ? null : (
    <Panel>
      <SideBySide>
        <NavigationButton
          isPrimary
          label="Sign In"
          link="/signin"
        />
        <NavigationButton
          label="Sign Up"
          link="/register"
        />
      </SideBySide>
      <Paragraph>
        You're not currently signed in. You can browse,
        but you'll need to sign in to book sessions.
      </Paragraph>
    </Panel>
  );
};

export default SignUp;
