import { DateTime } from "luxon";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationButton } from "../../components";
import useAppState from "../../hooks/useAppState";
import useEntity from "../../hooks/useEntity";
import useNavigation from "../../hooks/useNavigation";
import useUpdatableFields from "../../hooks/useUpdatableFields";
import useUser from "../../hooks/useUser";
import { Button, Page, Panel, SideBySide } from "../../library-components";
import { addSession, updateSession } from "../../userRequests";
import PricingPanel from "./PricingPanel";
import RestrictionsPanel from "./RestrictionsPanel";
import SessionInformationPanel from "./SessionInformationPanel";
import SessionSchedulePanel from "./SessionSchedulePanel";

import {
  defaultModel as defaultSession,
  dehydrateSession,
} from "../../models/session";
import asLookup from "../../tools/asLookup";

const SessionManagerEdit = ({ sessionId: sessionIdArg }) => {
  const { isBusy } = useAppState();
  const { sessionId: sessionIdParam } = useParams();
  const { entityId, sessions } = useEntity();
  const { back } = useNavigation();
  const { userId } = useUser();
  const [errors, setErrors] = useState([]);
  const [updates, updateField] = useUpdatableFields({}, setErrors);

  const sessionId = sessionIdArg || sessionIdParam;
  const sessionsLookup = asLookup(sessions);
  const session = sessionId ? sessionsLookup[sessionId] : {};

  const updated = {
    ...defaultSession,
    ...session,
    ...updates,
    lastUpdated: DateTime.utc(),
  };

  const noUpdates =
    Object.entries(updates).filter(([, val]) => val != null).length === 0;

  const hasErrors = errors && errors.length;

  const payload = {};

  if (sessionId) {
    payload.sessionId = sessionId;
    payload.updates = dehydrateSession(updated);
  } else payload.details = dehydrateSession(updated);

  const title = sessionId ? "Editing Session" : "Adding Session";
  const buttonLabel = sessionId ? "Save Changes" : "Add Session";
  const buttonAction = sessionId ? updateSession : addSession;

  return (
    <Page>
      <Panel isBorderless>
        <h3>{title}</h3>
      </Panel>
      <SessionInformationPanel session={updated} onUpdateField={updateField} />
      <SessionSchedulePanel session={updated} onUpdateField={updateField} />
      <PricingPanel
        data={updated}
        onUpdateField={updateField}
        allowMembershipPricing
      />
      <RestrictionsPanel data={updated} onUpdateField={updateField} />
      <Panel isBorderless>
        <SideBySide fitContent>
          <Button
            asyncAction={buttonAction(payload)}
            disabled={noUpdates || hasErrors || isBusy}
            label={buttonLabel}
            onComplete={back}
            entityId={entityId}
            userId={userId}
            isPrimary
          />
          <NavigationButton label="Cancel" isBackButton disabled={isBusy} />
        </SideBySide>
      </Panel>
    </Page>
  );
};

export default SessionManagerEdit;
