import { FaCartPlus, FaUserPlus } from "react-icons/fa";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { FriendList } from "../../components";
import useAppState from "../../hooks/useAppState";
import useEntity from "../../hooks/useEntity";
import useNavigation from "../../hooks/useNavigation";
import useUser from "../../hooks/useUser";
import { Icon, Page, Panel, Spinner } from "../../library-components";
import { isAgeSuitable } from "../../models/common";
import asLookup from "../../tools/asLookup";
import { purchaseBundle } from "../../userRequests";
import BundleInfo from "./BundleInfo";

const SmallerText = styled.p(
  ({ theme }) => css`
    font-size: ${theme.fontSizeSmall};
  `
);

const BundlePurchaseOnBehalf = ({ isChild = true }) => {
  const { notifyError } = useAppState();
  const { entityId, bundles } = useEntity();
  const { back, navigate } = useNavigation();
  const { bundleId } = useParams();
  const { userId } = useUser();

  const bundleLookup = asLookup(bundles);
  const bundle = bundleLookup[bundleId];

  if (!bundle) return <Spinner />;

  const onSelectFriend = async (friend) => {
    try {
      const purchaseFunc = purchaseBundle({
        bundleId,
        onBehalfOfId: friend.id,
      });

      const { success, resultData } = await purchaseFunc(userId, entityId);
      if (success) {
        const { orderItemId } = resultData;
        if (orderItemId) navigate("/basket");
        else back();
      }
    } catch ({ error }) {
      notifyError(error);
    }
  };

  const { costEach: bundleCost } = bundle;

  const onConfigureOptions = (friend) =>
    isAgeSuitable(bundle, friend)
      ? [
          {
            icon: bundleCost ? (
              <Icon primary={FaCartPlus} />
            ) : (
              <Icon primary={FaUserPlus} />
            ),
            action: () => onSelectFriend(friend),
          },
        ]
      : [];

  const headMessage = isChild
    ? "You are adding this bundle on behalf of a child. Please select the child below or add an new Child to the list. We will require their name and date of birth (to calculate their age)."
    : "You are adding this bundle on behalf of a friend.";

  return (
    <Page>
      <BundleInfo />
      <Panel title="Adding on behalf of another">
        <SmallerText>{headMessage}</SmallerText>
      </Panel>
      <FriendList
        isChild={isChild}
        onConfigureOptions={onConfigureOptions}
        onSelectFriend={onSelectFriend}
      />
    </Page>
  );
};

export default BundlePurchaseOnBehalf;
