import { collection, getFirestore, onSnapshot } from "firebase/firestore";

import { useEffect, useState } from "react";
import { Collections } from "../constants";

const nameSorter = (a, b) =>
  a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());

const useUsers = (entityId = null) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (entityId) {
      const db = getFirestore();
      const userQuery = collection(
        db,
        Collections.Entities,
        entityId,
        Collections.UserAccess
      );

      const unsubscribe = onSnapshot(userQuery, (snapshot) => {
        const rawUsers = snapshot.docs.map((doc) => doc.data());
        setUsers(rawUsers);
      });

      return () => unsubscribe();
    }
  }, [entityId]);

  return users.sort(nameSorter);
};

export default useUsers;
