import { formatCurrency } from '../../library-common';
import { BigNumber, SideBySide } from '../../library-components';
import { RequireUserPanel } from '../../components';

import useEntity from '../../hooks/useEntity';
import useUser from '../../hooks/useUser';

const UserAccountPanel = () => {
  const { balance } = useUser();
  const { current } = useEntity();

  const { settings: { name }, id } = current || {};

  const entityBalance = balance[id];
  const otherBalance = Object.entries(balance)
    .filter(([key]) => key !== id)
    .reduce((acc, [, value]) => acc + value, 0);

  return (entityBalance || otherBalance)
    ? (
      <RequireUserPanel title="Your Credit Balance" expandable>
        <SideBySide>
          {entityBalance ? <BigNumber
            formatter={formatCurrency}
            label={`${name} Balance`}
            value={entityBalance}
          /> : null}
          {otherBalance ? <BigNumber
            formatter={formatCurrency}
            label="Other Balance"
            value={otherBalance}
          /> : null}
        </SideBySide>
      </RequireUserPanel>
    )
    : null;
};

export default UserAccountPanel;
