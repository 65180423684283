import {
  FaCalendarCheck,
  FaHome,
  FaIdCard,
  FaShoppingCart,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { useBasket, useEntity, useUser } from "../hooks";
import { Icon } from "../library-components";

const Container = styled.div`
  display: flex;
  justify-content: stretch;
  padding: 0;
`;

const ThemedLink = styled(NavLink)(
  ({ theme }) => css`
    color: ${theme.colorPrimary};
    background: ${theme.background2};
    display: flex;
    flex: 1;
    justify-content: center;
    padding: ${theme.paddingDefault}px;
    padding-bottom: ${theme.paddingDefault + theme.paddingSmall}px;
    text-decoration: none;

    & + & {
      margin-left: ${theme.marginXSmall}px;
    }

    &.selected {
      color: ${theme.colorPrimaryInverse};
      background: ${theme.colorPrimary};
    }

    &.warning {
      color: ${theme.colorPrimaryInverse};
      background: ${theme.colorWarn};
    }

    &.disabled {
      color: ${theme.colorDimmed2};
      background: ${theme.background2};
    }
  `
);

const EntityFooterMenu = () => {
  const { entityId } = useEntity();
  const { userId } = useUser();
  const { basketItems, incompleteOrders } = useBasket();

  const items = [
    { icon: <Icon primary={FaHome} />, link: "/", disableIf: () => !userId },
    {
      icon: <Icon primary={FaCalendarCheck} />,
      link: "/sessions",
      disableIf: () => !entityId,
    },
    {
      icon: <Icon primary={FaIdCard} />,
      link: "/bundles",
      disableIf: () => !entityId,
    },
    {
      icon: <Icon primary={FaShoppingCart} />,
      link: "/basket",
      disableIf: () => !entityId || !userId,
      getNotifyCount: () => basketItems.length + incompleteOrders.length,
    },
  ];

  const onLinkClicked = (conditionFunc) => (ev) => {
    if (conditionFunc()) {
      ev.preventDefault();
    }
  };

  return (
    <Container>
      {items.map(
        (
          { disableIf = () => false, getNotifyCount = () => 0, icon, link },
          key
        ) => {
          const notifyCount = getNotifyCount();

          const classes = [];
          if (disableIf()) classes.push("disabled");
          if (notifyCount > 0) classes.push("warning");

          return (
            <ThemedLink
              activeclassname="selected"
              className={classes.join(" ")}
              key={key}
              onClick={onLinkClicked(disableIf)}
              to={{
                pathname: link,
                search: window.location.search,
              }}
            >
              <Icon primary={() => icon} notifyCount={notifyCount} />
            </ThemedLink>
          );
        }
      )}
    </Container>
  );
};

export default EntityFooterMenu;
