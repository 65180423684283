import SingleLineField, { RawFieldType } from './SingleLineField';

const PasswordField = (props) => (
  <SingleLineField
    {...props}
    rawType={RawFieldType.Password}
  />
);

export default PasswordField;
