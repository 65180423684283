import { Page } from '../../library-components';
import { BundleAdminInfo, BundleAssignmentInfo, BundleInfo } from '.';

const BundlePage = () => (
  <Page>
    <BundleInfo />
    <BundleAssignmentInfo />
    <BundleAdminInfo />
  </Page>
);

export default BundlePage;
