import zxcvbn from 'zxcvbn';

const validatePassword = (password, passwordCheck) => {
  const { feedback, score } = zxcvbn(password);
  const { suggestions } = feedback || {};

  const errors = [];

  if (passwordCheck) {
    if (password !== passwordCheck) {
      return {
        errors: [
          'The passwords you entered do not match'
        ]
      };
    }
  } else if (score <= 2) {
    suggestions.forEach(suggestion => errors.push(suggestion));
  }

  return { errors, score };
};

export default validatePassword;
