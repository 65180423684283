import { useState } from "react";
import { BundleRow, FullPageError } from ".";
import { NoContent, Panel } from "../library-components";

const BundleBrowser = ({
  allowAdminActions = false,
  bundles: allBundles = [],
}) => {
  const [error, setError] = useState();

  const { name: errorType, message: errorMessage } = error || {};

  const errorContent = error ? (
    <FullPageError
      onCancel={() => setError()}
      message={errorMessage}
      type={errorType}
    />
  ) : null;

  const bundles = allBundles
    .filter(({ isDeleted }) => !isDeleted)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      {errorContent}
      <Panel title="Memberships">
        {bundles.length > 0 ? (
          bundles.map((sub) => (
            <BundleRow
              key={sub.id}
              data={sub}
              allowAdminActions={allowAdminActions}
            />
          ))
        ) : (
          <NoContent message={`There aren't any memberships to view.`} />
        )}
      </Panel>
    </>
  );
};

export default BundleBrowser;
