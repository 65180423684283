import { useParams } from 'react-router-dom';
import { NoContent, Page } from '../../library-components';

import { UserDetails } from '../../components/user';

import useUserProfile from '../../hooks/useUserProfile';

const UserPage = () => {
    const { userId } = useParams();
    const userProfile = useUserProfile(userId);

    return userProfile
        ? <Page><UserDetails data={userProfile} /></Page>
        : <NoContent message="The specified user was not found" />;
};

export default UserPage;