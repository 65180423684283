import { CadenceOptions } from "../../constants";
import {
  Form,
  NumberField,
  Panel,
  SelectionField,
  SideBySide,
  ToggleField,
} from "../../library-components";
import MembershipPricingPanel from "./MembershipPricingPanel";

const PricingPanel = ({
  data = {},
  isBusy = false,
  isEach = false,
  isRecurringCost = false,
  allowMembershipPricing = false,
  onUpdateField = () => null,
}) => {
  const {
    cost,
    costEach,
    costCadence,
    costCadenceCount,
    costCadenceUnit,
    hasMembershipPricing,
    membershipPricing,
  } = data;

  const costValue = isEach ? costEach : cost;
  const costField = isEach ? "costEach" : "cost";

  return (
    <Panel title="Pricing">
      <Form>
        <NumberField
          label="Cost"
          value={costValue}
          disabled={isBusy}
          prefix="£"
          onChange={onUpdateField(costField)}
        />
        {isRecurringCost && (
          <>
            <SideBySide>
              <NumberField
                label="Period"
                prefix="Every"
                value={costCadence}
                disabled={isBusy}
                onChange={onUpdateField("costCadence")}
                required
              />
              <SelectionField
                label="Unit"
                options={CadenceOptions}
                value={costCadenceUnit}
                disabled={isBusy}
                onChange={onUpdateField("costCadenceUnit")}
                required
              />
            </SideBySide>
            <NumberField
              prefix="For"
              value={costCadenceCount}
              disabled={isBusy}
              onChange={onUpdateField("costCadenceCount")}
              required
              suffix={costCadenceUnit}
            />
          </>
        )}
        {allowMembershipPricing && (
          <>
            <ToggleField
              label="Apply membership pricing"
              value={hasMembershipPricing}
              disabled={isBusy}
              onChange={onUpdateField("hasMembershipPricing")}
              required
            />
            {hasMembershipPricing && (
              <MembershipPricingPanel
                value={membershipPricing}
                onChange={onUpdateField("membershipPricing")}
              />
            )}
          </>
        )}
      </Form>
    </Panel>
  );
};

export default PricingPanel;
