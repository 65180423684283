import { NoContent, Panel } from '../library-components';
import EntityRow from './EntityRow';
import useEntity from '../hooks/useEntity';

const EntityPicker = () => {
  const { current, entities } = useEntity();

  return current ? null : (
    <Panel
      title="Select Your Gym"
      isCentered
    >
      {entities.length
        ? entities.map(entity => (
          <EntityRow
            data={entity}
            key={entity.id}
          />
        ))
        : <NoContent message="No gyms to show" />}
    </Panel>
  );
};

export default EntityPicker;
