import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useContext, useMemo, useState } from "react";
import styled, { css, ThemeContext } from "styled-components";

import { Collections, OrderStatus } from "../../constants";
import {
  useAppState,
  useBasket,
  useConfig,
  useEntity,
  useNavigation,
  useUser,
} from "../../hooks";
import { Button, Panel, Spinner } from "../../library-components";
import { calculateComplexCost } from "../../models/common";
import { hydrateUserOrder } from "../../models/user";
import { payWithStripe } from "../../userRequests";

const ThemedCardElement = styled(CardElement)(
  ({ theme }) => css`
    padding: ${theme.paddingDefault}px;
  `
);

const ThemedForm = styled.form(
  ({ isHidden }) => css`
    display: ${isHidden ? "none" : "block"};
  `
);

const WaitContent = styled.div(
  ({ theme }) => css`
    align-items: center;
    color: ${theme.color0};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.paddingXLarge}px;

    & > * {
      margin: ${theme.marginDefault}px;
    }
  `
);

export const StripeWrapper = ({ children }) => {
  const { stripe } = useConfig();

  const stripePromise = useMemo(
    () => loadStripe(stripe.publishableKey),
    [stripe.publishableKey]
  );
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

const PaymentMethodsStripe = () => {
  const elements = useElements();
  const stripe = useStripe();

  const theme = useContext(ThemeContext);
  const [cardEvent, setCardEvent] = useState({ empty: true });
  const [orderStatus, setOrderStatus] = useState({});

  const { isBusy, setIsBusy, notifyError } = useAppState();
  const { current, entityId, sessions } = useEntity();
  const { navigate } = useNavigation();
  const { basketItems, incompleteOrders } = useBasket();
  const { userId } = useUser(sessions);
  const [isLocalBusy, setIsLocalBusy] = useState(false);

  const { integrations: { stripe: { connectedAccountId } = {} } = {} } =
    current || {};

  const chargableOrders = incompleteOrders.filter(
    ({ status }) => status !== OrderStatus.AwaitingPayment
  );

  const { paynow } = calculateComplexCost(
    basketItems.filter(({ isAvailable = () => true }) => isAvailable()),
    chargableOrders
  );

  const canPay = stripe && connectedAccountId && !cardEvent.empty && paynow > 0;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLocalBusy(true);

    if (!stripe || !elements) return;

    try {
      const {
        isSuccessful,
        resultData: {
          intentId,
          intentClientSecret,
          primaryOrderId,
          secondaryOrderIds,
        } = {},
      } = await payWithStripe({ includeIncompleteOrders: true })(
        userId,
        entityId
      );

      if (!isSuccessful || !intentId || !intentClientSecret) {
        notifyError(
          `Stripe intent was not returned or does not contain the required information`
        );
      }

      const cardElement = elements.getElement(CardElement);

      const busyMessage = "Processing orders, please wait...";
      setIsBusy(true, busyMessage);

      const { error } = await stripe.confirmCardPayment(intentClientSecret, {
        payment_method: { type: "card", card: cardElement },
      });

      if (error) {
        notifyError(error);
        setIsLocalBusy(false);
        setIsBusy(false);
      } else {
        const orderIds = [...secondaryOrderIds, primaryOrderId].filter(
          (o) => o != null
        );

        const ordersRef = query(
          collection(
            getFirestore(),
            Collections.Users,
            userId,
            Collections.Orders
          ),
          where("id", "in", orderIds)
        );

        const updateOrderStatus = (snapshot) => {
          const newStatus = snapshot.docs
            .map((doc) => hydrateUserOrder(doc.data()))
            .reduce(
              (acc, order) => ({ ...acc, [order.id]: order.status }),
              orderStatus
            );

          setOrderStatus(newStatus);

          const isWaiting = Object.values(newStatus).reduce(
            (acc, status) => acc | (status === OrderStatus.AwaitingPayment),
            false
          );
          setIsBusy(isWaiting, busyMessage);
          setIsLocalBusy(isWaiting);

          if (!isWaiting) navigate("/");
        };

        const ordersSnapshot = await getDocs(ordersRef);
        updateOrderStatus(ordersSnapshot);

        onSnapshot(ordersRef, (snapshot) => {
          updateOrderStatus(snapshot);
        });
      }
    } catch (error) {
      notifyError(error);
      setIsLocalBusy(false);
    }
  };

  return (
    <ThemedForm
      onSubmit={handleSubmit}
      isHidden={!connectedAccountId || paynow <= 0}
    >
      <Panel title="Card Payment" isHidden={isLocalBusy}>
        <ThemedCardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                color: theme.color0,
              },
            },
          }}
          onChange={setCardEvent}
        />
      </Panel>
      <Panel isBorderless isHidden={isLocalBusy}>
        <Button
          disabled={isLocalBusy || isBusy || !canPay}
          isPrimary
          label="Pay Now"
          type="submit"
        />
      </Panel>
      {isLocalBusy ? (
        <WaitContent>
          <Spinner isTiny />
          <p>Processing payment...</p>
        </WaitContent>
      ) : null}
    </ThemedForm>
  );
};

export default PaymentMethodsStripe;
