const validateName = name => {
  const parts = name.trim().split(' ');

  const errors = [];
  const partCount = parts.length;

  if (partCount < 2) {
    errors.push('Please enter a full name, which includes at least one first name and a family name or surname.');
  } else {
    if (parts[0].length < 2) {
      errors.push('Please enter a first name, rather than an initial.');
    }
    if (parts[partCount - 1].length < 2) {
      errors.push('Please enter a family name or surname, rather than an initial.');
    }
  }

  return { errors };
};

export default validateName;
