import { useState } from 'react';
import styled, { css } from 'styled-components';
import FieldDescription from './FieldDescription';
import { DefaultRequiredCharacter } from '../constants';

const colorFromScore = ({ disabled, hasError, score, theme }) => {
  let color = theme.colorPrimary;

  if (disabled) color = theme.colorDisabled;
  else if (hasError && score == null) color = theme.colorWarn;
  else if (score <= 1) color = theme.colorError;
  else if (score <= 2) color = theme.colorWarn;
  else if (score > 2) color = theme.colorOk;

  return color;
};

const OuterContainer = styled.div(props => css`
  flex: auto;

  ${InnerContainer} {
    border-bottom-color: ${() => colorFromScore(props)};
  }

  ${ValidationError} {
    color: ${() => colorFromScore(props)};
  }
`);

const InnerContainer = styled.div(({ theme }) => css`
  background: ${theme.background1};
  border-bottom: ${theme.borderDefault};
  display: flex;
`);

const InputContainer = styled.div(({ theme }) => css`
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 0 ${theme.paddingDefault}px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`);

const Prefix = styled.div(({ theme }) => css`
  align-items: center;
  background: ${theme.background2};
  display: flex;
  flex: content 0 1;
  font-size: ${theme.fontSizeSmall};
  padding: 0 ${theme.paddingDefault}px;
`);

const Suffix = styled(Prefix)``;

const SubText = styled(FieldDescription)(({ theme }) => css`
  margin-bottom: ${theme.marginDefault}px;
`);

const Label = styled.label(({ theme }) => css`
  color: ${({ disabled, theme }) => disabled ? theme.colorDisabled : 'inherit'};
  font-size: ${theme.fontSizeXSmall};
  margin-top: ${theme.marginSmall}px;
`);

const Input = styled.input(({ theme }) => css`
  background: transparent;
  border: none;
  color: ${theme.colorPrimary};
  font-size: ${theme.fontSizeDefault};
  margin: ${theme.marginDefault}px 0;

  :disabled {
    color: ${theme.colorDisabled};
    cursor: not-allowed;
  }
`);

const Select = styled.select(({ theme }) => css`
  background: transparent;
  border: none;
  color: ${theme.colorPrimary};
  font-size: ${theme.fontSizeDefault};
  margin: ${theme.marginDefault}px 0;
  outline: none;

  :disabled {
    color: ${theme.colorDisabled};
    cursor: not-allowed;
  }
`);

const Option = styled.option(({ theme }) => css`
`);

const Required = styled.span(({ theme }) => css`
  color: ${theme.colorSecondary};
  
  ::before {
    content: '${DefaultRequiredCharacter}';
    padding-right: ${theme.paddingXSmall}px;
  }
`);

const ValidationError = styled.p(({ theme }) => css`
  font-size: ${theme.fontSizeXSmall};
  margin-top: ${theme.marginXSmall}px;
`);

export const RawFieldType = {
  Date: 'date',
  Email: 'email',
  Number: 'number',
  Password: 'password',
  Select: 'select',
  Time: 'time'
};

const SingleLineField = ({
  className,
  disabled = false,
  label,
  max,
  min,
  onChange = () => null,
  options = {},
  prefix,
  rawType,
  required = false,
  step,
  subText,
  suffix,
  validator,
  value: rawValue
}) => {
  const [errors, setErrors] = useState([]);
  const [score, setScore] = useState();

  const onInputChange = ev => {
    const val = ev.target.value;

    let result = {};
    if (validator && val && val.length) {
      result = validator(val);
    }

    setErrors(result.errors || []);
    setScore(result.score);
    onChange(val, result);
  };

  const optionsSorter = (a, b) => a.localeCompare(b);

  let value = rawValue;
  if (value == null) value = '';

  const rawControl = rawType === RawFieldType.Select
    ? <Select
      disabled={disabled}
      onChange={onInputChange}
      step={step}
      type={rawType}
      value={value}
    >
      {Object.entries(options)
        .sort(([, textA], [, textB]) => optionsSorter(textA, textB))
        .map(([val, text]) => <Option key={val} value={val}>{text}</Option>)}
    </Select>
    : <Input
      disabled={disabled}
      max={max}
      min={min}
      onChange={onInputChange}
      step={step}
      type={rawType}
      value={value}
    />;

  return (
    <OuterContainer
      className={className}
      hasError={!!errors.length}
      id="single-line-field"
      disabled={disabled}
      score={score}
    >
      {label && (
        <Label disabled={disabled}>
          {required && <Required />}
          {label}
        </Label>
      )}
      <InnerContainer>
        {prefix ? <Prefix>{prefix}</Prefix> : null}
        <InputContainer>
          {rawControl}
        </InputContainer>
        {suffix ? <Suffix>{suffix}</Suffix> : null}
      </InnerContainer>
      {errors.map((error, key) => (
        <ValidationError key={key}>{error}</ValidationError>
      ))}
      {subText && <SubText description={subText} />}
    </OuterContainer>
  );
}

export default SingleLineField;
