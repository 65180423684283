import { NoContent, RowStatus } from "../../library-components";
import UserRow from "./UserRow";

const UserList = ({
  users = [],
  getStatus = () => RowStatus.Undefined,
  renderIcon = () => null,
  renderMore = () => null,
}) =>
  users.length ? (
    users
      .map((user) => ({
        ...user,
        icon: renderIcon(user),
        status: getStatus(user),
      }))
      .map((user, key) => (
        <UserRow data={user} renderMore={renderMore} key={key} />
      ))
  ) : (
    <NoContent message="No users to show" />
  );

export default UserList;
