import { DateTime } from "luxon";
import { Row, RowCell, RowStatus } from "../../library-components";

const OrderRow = ({ data = {}, isSummary = false }) => {
  const { created: createdISO, status } = data;

  const rowStatus = status === "complete" ? RowStatus.None : RowStatus.Disabled;
  const created = DateTime.fromISO(createdISO);

  return (
    <Row status={rowStatus}>
      <RowCell primary={status} />
      <RowCell
        alignRight
        primary={created.toLocaleString(DateTime.TIME_24_WITH_SECONDS)}
        secondary={created.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
      />
    </Row>
  );
};

export default OrderRow;
