import { DateTime } from 'luxon';
import SingleLineField, { RawFieldType } from './SingleLineField';

const DateField = (props) => {
  const {
    defaultValue,
    maxDate,
    minDate,
    onChange = () => null
  } = props;

  const defaultDate = defaultValue && defaultValue.toISODate();
  const onDateChange = (newValue, result) => {
    const dt = DateTime.fromISO(newValue);
    onChange(dt, result);
  };

  return (
    <SingleLineField
      {...props}
      defaultValue={defaultDate}
      max={maxDate ? maxDate.toISODate() : null}
      min={minDate ? minDate.toISODate() : null}
      onChange={onDateChange}
      rawType={RawFieldType.Date}
    />
  );
};
export default DateField;
