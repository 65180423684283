import styled, { css } from "styled-components";

const Image = styled.div(
  ({ theme, bgSrc, isRounded = false }) => css`
    background-image: url(${bgSrc});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: ${isRounded ? `${theme.iconSmall / 2}px` : "none"};
    height: ${theme.iconSmall}px;
    width: ${theme.iconSmall}px;
  `
);

export default Image;
