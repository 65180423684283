import { useState } from 'react';

const useUpdatableFields = (
  initialState,
  setErrors = () => null
) => {
  const [updates, setUpdates] = useState(initialState);

  const updateField = (fieldName, transform = val => val) => (newValue, err) => {
    setErrors(err.errors);
    try {
      setUpdates({
        ...updates,
        [fieldName]: transform(newValue)
      });
    } catch (e) {
      console.warn(e);
    };
  };

  const resetUpdates = () => setUpdates(initialState);

  return [
    updates,
    updateField,
    resetUpdates
  ];
};

export default useUpdatableFields;