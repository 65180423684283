import { Routes, Route } from "react-router-dom";
import { EntityFooterMenu, Notifications, SignUpPanel } from './components';

const Footer = () => (
  <>
    <Routes>
      <Route path="/*" element={<SignUpPanel />} />
    </Routes>
    <Notifications />
    <EntityFooterMenu />
  </>
);

export default Footer;
