import styled, { css } from 'styled-components';

const Container = styled.h1`
  font-weight: normal;

  ${({ theme }) => css`
    font-size: ${theme.fontSizeXLarge}
  `}
`;

const DetailTitle = ({ title }) => (
  <Container>
    {title}
  </Container>
);

export default DetailTitle;
