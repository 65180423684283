import styled, { css } from "styled-components";
import { BookingStatus } from "../constants";
import useEntity, { useEntityUser } from "../hooks/useEntity";
import { calculateAge, formatDate, formatTime } from "../library-common";
import { Row, RowCell, RowOptions, RowStatus } from "../library-components";

const ContainerRow = styled(Row)(({ theme, isCancelled }) => {
  const color = isCancelled ? theme.colorDisabled : "";

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const SessionBasketRow = ({ data: basketItem = {} }) => {
  const {
    created,
    id: bookingId,
    inBasketOf,
    status: bookingStatus = BookingStatus.PendingPayment,
    onBehalfOf: { dateOfBirth, name: bookeeName } = {},
    updated,
  } = basketItem;

  const options = [];
  const dt = updated || created;

  const { entityId } = useEntity();
  const { name: bookedByName } = useEntityUser(inBasketOf);

  const bookerName =
    bookeeName && bookedByName && bookedByName !== bookeeName
      ? bookedByName
      : null;

  return (
    <ContainerRow
      id={bookingId}
      isCancelled={bookingStatus === BookingStatus.Cancelled}
      status={RowStatus.Warn}
      noMargins
    >
      <RowCell
        primary={bookeeName || bookedByName}
        secondary={dateOfBirth ? `Age: ${calculateAge(dateOfBirth)}` : null}
        warning={bookerName && `Booked by ${bookerName}`}
        flexWidth={2}
      />
      <RowCell
        alignRight
        primary="Unpaid"
        secondary={formatDate(dt, true)}
        tertiary={`@ ${formatTime(dt)}`}
      />
      <RowOptions entityId={entityId} options={options} />
    </ContainerRow>
  );
};

export default SessionBasketRow;
