import asLookup from "../../tools/asLookup";
import log from "../../tools/log";

export const Actions = {
  UpdateBasketItems: "update-basketitems",
  UpdateBundleAssignments: "update-bundleassignments",
  UpdateBundles: "update-bundles",
  UpdateClasses: "update-classes",
  UpdateEntities: "update-entities",
  UpdateEntity: "update-entity",
  UpdateOrders: "update-orders",
  UpdateSessions: "update-sessions",
  UpdateEntityUsers: "update-entityusers",
  UpdateReady: "update-ready",
};

export const initialState = {
  basketItems: [],
  bundles: [],
  bundleAssignments: [],
  classes: [],
  entities: [],
  orders: [],
  sessions: [],
  entityUsers: {},
  isInitialised: false,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  // console.log("entity", action);

  switch (type) {
    case Actions.UpdateBasketItems:
      return { ...state, basketItems: payload };
    case Actions.UpdateBundles:
      return { ...state, bundles: payload };
    case Actions.UpdateBundleAssignments:
      return { ...state, bundleAssignments: payload };
    case Actions.UpdateClasses:
      return { ...state, classes: payload };
    case Actions.UpdateEntities:
      return { ...state, entities: payload };
    case Actions.UpdateEntity:
      return { ...state, current: payload };
    case Actions.UpdateOrders:
      return { ...state, orders: payload };
    case Actions.UpdateSessions:
      return { ...state, sessions: payload };
    case Actions.UpdateEntityUsers:
      return { ...state, entityUsers: asLookup(payload) };
    case Actions.UpdateReady:
      return { ...state, isInitialised: true };
    default:
      log.warn(`Invalid Entity action dispatched`, type);
      return state;
  }
};

export default reducer;
