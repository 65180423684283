import { Form, NumberField, Panel, SelectionField, SideBySide, SingleLineField } from '../../library-components';
import { validateString } from '../../library-common';
import { BundleType, BundleTypeLabels } from '../../models/bundle';
import { CadenceOptions } from '../../constants';

const BundleInformationPanel = ({
  bundle = {},
  isBusy = false,
  onUpdateField = () => null
}) => {
  const { duration, durationUnit, name, type } = bundle;

  return (
    <Panel title="Information">
      <Form>
        <SelectionField
          label="Bundle Type"
          options={BundleTypeLabels}
          value={type}
          disabled={isBusy}
          onChange={onUpdateField('type')}
          required
        />
        <SingleLineField
          label="Name"
          validator={validateString(2)}
          value={name}
          disabled={isBusy}
          onChange={onUpdateField('name')}
          required
        />
        {type !== BundleType.BlockBooking && <SideBySide>
          <NumberField
            label="Period"
            prefix="Expires after"
            value={duration}
            disabled={isBusy}
            onChange={onUpdateField('duration')}
            required
          />
          <SelectionField
            label="Unit"
            options={CadenceOptions}
            value={durationUnit}
            disabled={isBusy}
            onChange={onUpdateField('durationUnit')}
            required
          />
        </SideBySide>}
      </Form>
    </Panel>
  );
};

export default BundleInformationPanel;
