import { DateTime } from "luxon";
import {
  AgeOfAdult,
  HistoricCutOffDays,
  ReportingCutOffDays,
} from "../constants";
import { calculateAge } from "../library-common";

export const commonHydration = (raw) => {
  if (!raw) return raw;

  const hydrated = { ...raw };

  hydrated.createdISO = raw.created;
  hydrated.created = DateTime.fromISO(raw.created);
  if (raw.lastUpdated) {
    hydrated.lastUpdatedISO = raw.lastUpdated;
    hydrated.lastUpdated = DateTime.fromISO(raw.lastUpdated);
  }

  return hydrated;
};

export const getFutureCutOff = (cutOffDays = HistoricCutOffDays) =>
  DateTime.local().plus({ days: cutOffDays });
export const getPastCutOff = (cutOffDays = HistoricCutOffDays) =>
  DateTime.local().minus({ days: cutOffDays });
export const getReportingCutOff = (cutOffDays = ReportingCutOffDays) =>
  DateTime.local().minus({ days: cutOffDays });

export const qualificationMessage = ({ isAgeRestricted, maxAge, minAge }) => {
  let qualification = null;

  if (isAgeRestricted) {
    const ageMessage = maxAge
      ? `${minAge}-${maxAge} years`
      : `${minAge}+ years`;
    qualification = `Restricted, ${ageMessage}`;
  }

  return qualification;
};

export const isAdult = (user) => {
  const { age, dateOfBirth } = user;

  return dateOfBirth && age >= AgeOfAdult;
};

export const isAgeSuitable = (data, user) => {
  const { isAgeRestricted, maxAge, minAge } = data;
  const { dateOfBirth } = user;

  const age = calculateAge(dateOfBirth);

  if (isAgeRestricted) {
    if (!dateOfBirth || (maxAge && age > maxAge) || (minAge && age < minAge))
      return false;
  }

  return true;
};

export const isGenderSuitable = () => true;

export const statusFromExpiry = (expiry, defaultStatus) => {
  let rowStatus = defaultStatus;

  if (expiry) {
    const now = DateTime.local();
    const futureCutOff = getFutureCutOff();

    if (expiry >= futureCutOff) {
      rowStatus = defaultStatus || "ok";
    } else if (expiry >= now) {
      rowStatus = "warn";
    } else if (expiry < now) {
      rowStatus = "error";
    }
  }

  return rowStatus;
};

export const hasExpiredRecently = ({ expires }) => {
  const now = DateTime.local();
  return expires < now && expires > getPastCutOff();
};

export const isExpiringSoon = ({ expires }) => {
  const now = DateTime.local();
  return expires > now && expires < getFutureCutOff();
};

const complexCostReducer = (acc, basketItem) => {
  const {
    cost,
    costCadence,
    costCadenceUnit,
    costCadenceCount,
    isCredit,
    quantity,
  } = basketItem;

  let newComplexCost = acc;
  if (costCadence) {
    // This is a subscription
    newComplexCost.paynow = acc.paynow + cost;
    newComplexCost.futurePayments = [
      ...acc.futurePayments,
      {
        cost,
        costCadence,
        costCadenceUnit,
        costCadenceCount: costCadenceCount - 1,
      },
    ];
  } else {
    // This is a one off
    newComplexCost.paynow = acc.paynow + (isCredit ? -cost : cost);
  }

  if (quantity) {
    newComplexCost.lineCount++;
    newComplexCost.itemCount += quantity || 1;
  }

  return newComplexCost;
};

export const calculateComplexCost = (items = [], orders = []) => {
  const mapItemToCost = ({
    cost,
    costCadence,
    costCadenceUnit,
    costCadenceCount,
    isCredit,
    quantity,
  }) => ({
    cost,
    costCadence,
    costCadenceUnit,
    costCadenceCount,
    isCredit,
    quantity,
  });

  const mapOrdersToCost = ({ net }) => ({
    cost: net,
    quantity: 1,
  });

  const costs = [...items.map(mapItemToCost), ...orders.map(mapOrdersToCost)];

  return costs.reduce(complexCostReducer, {
    paynow: 0,
    itemCount: 0,
    lineCount: 0,
    futurePayments: [],
  });
};
