import styled from "styled-components";
import { useEntity, useUserActivity } from "../../hooks";
import { Panel } from "../../library-components";
import ActivityRow from "./ActivityRow";

const StyledPanel = styled(Panel)`
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ActivityInfo = ({ data = {}, isSummary = false }) => {
  const { id } = data;

  const { entityId } = useEntity();
  const activity = useUserActivity(id, entityId);

  return (
    <StyledPanel
      title={isSummary ? "" : "Activity"}
      isBorderless={isSummary}
      isMarginless={isSummary}
      isExpandable
    >
      {activity.map((activityItem, key) => (
        <ActivityRow data={activityItem} isSummary={isSummary} key={key} />
      ))}
    </StyledPanel>
  );
};

export default ActivityInfo;
