import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { useState } from 'react';

import {
  Button,
  EmailField,
  Form,
  ModalMessage,
  Panel,
  Paragraph,
  PasswordField,
  SideBySide,
  ToggleField
} from '../../library-components';
import { NavigationButton } from '../../components';
import { validateEmail } from '../../library-common';
import useAppState from '../../hooks/useAppState';
import useNavigation from '../../hooks/useNavigation';

const defaultState = {
  emailSent: false,
  errors: [],
  noPassword: false
};

const SignInWithPasswordPanel = () => {
  const [state, setState] = useState(defaultState);

  const { isBusy, setIsBusy, notifyError } = useAppState();
  const { navigate } = useNavigation();

  const { email, errors = [], emailSent, noPassword, password } = state;

  const updateState = fieldName => (newValue, err = []) => setState({
    ...state,
    [fieldName]: newValue,
    errors: err.errors
  });

  const clearState = () => setState(defaultState);
  const auth = getAuth();

  const resetPassword = () => {
    setIsBusy(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        updateState('emailSent')(true);
        setIsBusy(false);
      })
      .catch(error => {
        notifyError(error);
        setIsBusy(false);
      });
  };

  const signInWithPassword = () => {
    setIsBusy(true);
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsBusy(false);
        navigate('/');
      })
      .catch(error => {
        notifyError(error);
        setIsBusy(false);
      });
  };

  return (
    <Panel title="Sign In">
      <Paragraph>
        You're not currently signed in. You can browse,
        but you'll need to sign in to book sessions.
      </Paragraph>
      <Form>
        <ModalMessage
          visible={emailSent}
          lines={[
            `An email has been sent to ${email}.`,
            `Please follow the instructions in the email to set your password.`
          ]}
          onClose={clearState}
          title="Email Sent"
        />
        <EmailField
          label="Email Address"
          validator={validateEmail}
          value={email}
          disabled={isBusy}
          onChange={updateState('email')}
        />
        <ToggleField
          value={noPassword}
          label="I don't have or have forgotten my password"
          onChange={updateState('noPassword')}
        />
        {noPassword ? null : <PasswordField
          label="Password"
          value={password}
          disabled={isBusy}
          onChange={updateState('password')}
        />}
        <SideBySide>
          {noPassword
            ? <Button
              isPrimary
              label="Set Password"
              onClick={resetPassword}
              disabled={isBusy || !email || errors.length}
            />
            : <Button
              isPrimary
              label="Sign In"
              onClick={signInWithPassword}
              disabled={isBusy || !email || !password}
            />}
          <NavigationButton
            label="Cancel"
            link="/"
            disabled={isBusy}
          />
        </SideBySide>
      </Form>
    </Panel>
  );
};

export default SignInWithPasswordPanel;
