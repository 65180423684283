import { FaTimes } from "react-icons/fa";
import useUser from "../../hooks/useUser";
import { addOptionBuilder, Icon } from "../../library-components";
import { cancelPurchase } from "../../userRequests";
import BasketItemRow from "./BasketItemRow";

const BasketItemSessionRow = (props) => {
  const user = useUser();

  const { data = {} } = props;
  const { id: orderItemId, isAvailable, onBehalfOf } = data;
  const { name: friendName } = onBehalfOf || {};

  const identityPayload = { orderItemId };

  const options = [];
  const addOption = addOptionBuilder(data, options, user);
  addOption(
    () => true,
    <Icon primary={FaTimes} />,
    cancelPurchase(identityPayload)
  );

  const onBehalfOfMessage = onBehalfOf ? `Booking for ${friendName}` : null;

  return (
    <BasketItemRow
      {...props}
      notes={onBehalfOfMessage}
      options={options}
      unavailable={!isAvailable()}
    />
  );
};

export default BasketItemSessionRow;
