/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { DateTime } from "luxon";
import { Fragment, useEffect, useRef, useState } from "react";
import { FullPageError, SessionDateRow, SessionRow } from ".";
import { NoContent, Panel } from "../library-components";

const SessionBrowser = ({ allowAdminActions = false, sessions = [] }) => {
  const refToday = useRef();
  const [error, setError] = useState();

  useEffect(() => {
    if (refToday.current) {
      refToday.current.scrollIntoView();
    }
  }, []);

  let date;
  let inTheFuture = false;
  const now = DateTime.local();

  const { name: errorType, message: errorMessage } = error || {};

  const errorContent = error ? (
    <FullPageError
      onCancel={() => setError()}
      message={errorMessage}
      type={errorType}
    />
  ) : null;

  return sessions.length > 0 ? (
    <Panel isCentered isBorderless>
      {errorContent}
      {sessions.map((session) => {
        const { id, starts } = session;

        const dateDiff = date
          ? Math.floor(
              date.startOf("day").diff(starts.startOf("day")).as("days")
            )
          : 1;

        date = starts;

        const futureDate = starts > now.startOf("day");
        const anchorToday = futureDate && !inTheFuture;
        inTheFuture = futureDate;

        return (
          <Fragment key={id || starts.toISO()}>
            {anchorToday ? <a name="today" ref={refToday} /> : null}
            <SessionDateRow
              anchorToday={anchorToday}
              date={starts}
              lastOffset={dateDiff}
            />
            <SessionRow
              allowAdminActions={allowAdminActions}
              data={session}
              key={id}
            />
          </Fragment>
        );
      })}
    </Panel>
  ) : (
    <NoContent message="There aren't any sessions to view." />
  );
};

export default SessionBrowser;
