const groupBy = (arr = [], propName, includeProps = []) =>
  arr.reduce((acc, item) => {
    const key = item[propName];
    const collection = acc[key] || {
      key,
      children: [],
      ...includeProps.reduce((all, p) => ({ ...all, [p]: item[p] }), {}),
    };

    collection.children = [...collection.children, item];

    return { ...acc, [key]: collection };
  }, {});

export default groupBy;
