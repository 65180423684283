import styled, { css } from 'styled-components';

const Container = styled.div(({ theme, state }) => css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${theme.marginDefault}px 0;
`);

const Label = styled.label(({ theme }) => css`
  font-size: ${theme.fontSizeXSmall};
`);

const Toggle = styled.div`
  align-items: center;
  display: flex;
`;

const ToggleTrack = styled.div(({ theme, state }) => css`
  background: ${state ? theme.colorPrimary : theme.background2};
  border-radius: ${theme.radiusDefault + theme.paddingXSmall}px;
  display: flex;
  justify-content: ${state ? 'flex-end' : 'flex-start'};
  padding: ${theme.paddingXSmall}px;
  width: ${theme.radiusDefault * 5}px;

  & + ${Label} {
    margin-left: ${theme.marginXSmall}px;
  }

  ${Label} + & {
    margin-left: ${theme.marginXSmall}px;
  }
`);

const ToggleButton = styled.div(({ theme }) => css`
  background: ${theme.background0};
  border-radius: ${theme.radiusDefault}px;
  height: ${theme.radiusDefault * 2}px;
  width: ${theme.radiusDefault * 2}px;
`);

const ToggleField = ({
  value = false,
  label,
  onChange = () => null,
  options = []
}) => {

  const toggle = () => {
    onChange(!value, {});
  };

  return (
    <Container onClick={toggle}>
      <Label>{label}</Label>
      <Toggle>
        <Label>{options && options[1]}</Label>
        <ToggleTrack state={value}>
          <ToggleButton state={value} />
        </ToggleTrack>
        <Label>{options && options[0]}</Label>
      </Toggle>
    </Container>
  );
};

export default ToggleField;
