import { Page } from '../../library-components';
import { SessionBrowser } from '../../components';
import useEntity from '../../hooks/useEntity';

const Sessions = () => {
  const { futureSessions } = useEntity();

  // do not show published sessions
  const sessions = futureSessions.filter(({ isPublished }) => isPublished);

  return (
    <Page>
      <SessionBrowser sessions={sessions} />
    </Page>
  );
};

export default Sessions;
