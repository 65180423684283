import log from "../../tools/log";

export const Actions = {
  SignIn: "sign-in",
  SignOut: "sign-out",
  UpdateAccount: "update-account",
  UpdateBasket: "update-basket",
  UpdateBookings: "update-bookings",
  UpdateBundleAssigments: "update-bundle-assignments",
  UpdateFriends: "update-friends",
  UpdateOrders: "update-orders",
  UpdateProfile: "update-profile",
};

export const initialState = {
  account: [],
  basket: [],
  bookings: [],
  bundleAssignments: [],
  claims: {},
  friends: {},
  orders: [],
  profile: {},
  isInitialised: false,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  // console.log("user", action);

  switch (type) {
    case Actions.SignIn:
      return { ...state, ...payload, isInitialised: true };
    case Actions.SignOut:
      return { ...initialState, isInitialised: true };
    case Actions.UpdateAccount:
      return { ...state, account: payload };
    case Actions.UpdateBasket:
      return { ...state, basket: payload };
    case Actions.UpdateBookings:
      return { ...state, bookings: payload };
    case Actions.UpdateBundleAssigments:
      return { ...state, bundleAssignments: payload };
    case Actions.UpdateFriends:
      return { ...state, friends: payload };
    case Actions.UpdateOrders:
      return { ...state, orders: payload };
    case Actions.UpdateProfile:
      return { ...state, ...payload };
    default:
      log.warn(`Invalid user action dispatched`, type);
      return state;
  }
};

export default reducer;
