import { Icon } from "./library-components";
import { EntityName, IconButton, IconNavigationButton } from "./components";
import { FaBars, FaUser } from "react-icons/fa";
import styled, { css } from 'styled-components';
import useAppState from './hooks/useAppState';
import useEntity from './hooks/useEntity';
import useUser, { isUserAdmin } from './hooks/useUser';

const Container = styled.div(({ theme }) => css`
  display: flex;
  justify-content: space-between;
  padding: ${theme.paddingSmall}px ${theme.paddingDefault}px;
`);

const Blank = styled.div`
  flex: 1;
`;

const Header = () => {
  const { entityId } = useEntity();
  const user = useUser();
  const { isMenuShown, setIsMenuShown } = useAppState();

  const { userId } = user;

  const isAdmin = isUserAdmin(user, entityId);

  const userMenu = (
    <IconNavigationButton
      icon={<Icon primary={FaUser} />}
      link="/profile"
    />
  );

  const adminMenu = (
    <IconButton
      icon={<Icon primary={FaBars} />}
      onClick={() => setIsMenuShown(!isMenuShown)}
    />
  );

  return (
    <Container>
      {userId ? userMenu : <Blank />}
      <EntityName />
      {isAdmin ? adminMenu : <Blank />}
    </Container>
  );
}

export default Header;
