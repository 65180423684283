import { DateTime } from "luxon";
import {
  FaBaby,
  FaCalendarAlt,
  FaCartPlus,
  FaChild,
  FaEdit,
  FaRegEye,
  FaRegEyeSlash,
  FaShoppingCart,
  FaUserPlus,
} from "react-icons/fa";
import styled, { css } from "styled-components";
import useAppState from "../hooks/useAppState";
import useEntity from "../hooks/useEntity";
import useNavigation from "../hooks/useNavigation";
import useUser, { isUserAdmin } from "../hooks/useUser";
import { formatDate, formatDuration, formatTime } from "../library-common";
import {
  Icon,
  Row,
  RowCell,
  RowOptions,
  RowStatus,
  addOptionBuilder,
} from "../library-components";
import { isAgeSuitable, qualificationMessage } from "../models/common";
import {
  canBookForChild,
  canBookForSelf,
  costForUser as funcCostForUser,
  isInBasket as funcIsInBasket,
  isBooked,
} from "../models/session";
import { purchaseSession, updateSession } from "../userRequests";
import SessionBookedForRow from "./SessionBookedForRow";
import SessionBookingsStatus from "./SessionBookingsStatus";
import SessionRowCost from "./SessionRowCost";

const ContainerRow = styled(Row)(({ theme, isInPast, isPublished }) => {
  const color = isPublished
    ? isInPast
      ? theme.colorDimmed0
      : ""
    : theme.colorDisabled;

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const Full = styled.span(
  ({ theme }) => css`
    color: ${theme.colorError};
    margin-left: ${theme.marginXSmall}px;

    :before {
      content: "|";
    }
  `
);

const SessionRow = ({
  allowAdminActions = false,
  data: session = {},
  showDate = false,
}) => {
  const { entityId } = useEntity();
  const { navigate } = useNavigation();
  const { wrappedNotifyError } = useAppState();
  const user = useUser();

  const {
    coach,
    duration,
    id: sessionId,
    isFull,
    isInPast: funcIsInPast,
    isPublished,
    starts,
    name,
    type,
  } = session;

  const { basket, dateOfBirth, friends = {} } = user;

  const isAdmin = isUserAdmin(user, entityId) && allowAdminActions;
  const isInPast = funcIsInPast();
  const isInBasket = funcIsInBasket(session, basket);

  const costForUser = funcCostForUser(session, user);

  const identityPayload = { sessionId };

  const options = [];
  const now = DateTime.utc();

  const addOption = addOptionBuilder(session, options, user);

  const publish = (published) =>
    updateSession({
      ...identityPayload,
      updates: { isPublished: published },
    });

  const navigateToProfileEdit = () => navigate(`/profile-edit`);
  const navigateToSession = () => navigate(`/sessions/${sessionId}`);

  const canEditOrDelete = () => isAdmin && starts > now;
  const canPublish = () => isAdmin && !isPublished && !isInPast;
  const canUnPublish = () => isAdmin && isPublished && !isInPast;
  const canGoToBasket = () => isInBasket;
  const canSetDoB = () => !isAgeSuitable(session, user) && !dateOfBirth;

  addOption(
    canBookForSelf(basket),
    costForUser ? <Icon primary={FaCartPlus} /> : <Icon primary={FaUserPlus} />,
    purchaseSession(identityPayload)
  );
  // addOption(canBookForFriend, <Icon primary={FaUserFriends} />, null);
  addOption(
    canBookForChild,
    <Icon primary={FaChild} />,
    null,
    `/sessions/${sessionId}/bookOnBehalf`
  );
  /*addOption(
    canCancelBooking,
    <Icon primary={FaTimes} />,
    cancelBooking(identityPayload)
  );*/
  addOption(
    canGoToBasket,
    <Icon primary={FaShoppingCart} />,
    null,
    "/basket",
    RowStatus.Warn
  );

  addOption(
    canEditOrDelete,
    <Icon primary={FaEdit} />,
    null,
    `/sessionmanager/edit/${sessionId}`
  );
  /*addOption(
    canEditOrDelete,
    <Icon primary={FaTrash} />,
    removeSession(identityPayload)
  );*/

  addOption(canPublish, <Icon primary={FaRegEye} />, publish(true));
  addOption(canUnPublish, <Icon primary={FaRegEyeSlash} />, publish(false));

  addOption(
    canSetDoB,
    <Icon primary={FaCalendarAlt} secondary={FaBaby} />,
    navigateToProfileEdit
  );

  let qualification;
  if (!isAgeSuitable(session, user)) {
    qualification = qualificationMessage(session);
  }

  const isUnavailable = isInPast || isFull;

  let status = RowStatus.None;
  if (isBooked(session, user, true))
    status = isUnavailable ? RowStatus.OkDimmed : RowStatus.Ok;
  else if (isInBasket)
    status = isUnavailable ? RowStatus.WarnDimmed : RowStatus.Warn;
  else if (isUnavailable) status = RowStatus.Disabled;

  if (!isPublished) status = RowStatus.Disabled;

  const bookedFor = Object.values(friends).filter((friend) =>
    isBooked(session, friend)
  );

  return (
    <>
      <ContainerRow
        id={sessionId}
        isPublished={isPublished}
        isInPast={isInPast}
        status={status}
        onClick={navigateToSession}
        noMargins
      >
        <RowCell
          pixelWidth={60}
          primary={showDate ? formatDate(starts, true) : formatTime(starts)}
          secondary={showDate ? formatTime(starts) : formatDuration(duration)}
        />
        <RowCell
          primary={() => (
            <>
              {name || type}
              {isFull && <Full>Full</Full>}
            </>
          )}
          secondary={`Coach: ${coach || "TBC"}`}
          warning={qualification}
        />
        <RowCell
          alignRight
          pixelWidth={80}
          primary={() => <SessionRowCost data={session} />}
          secondary={() => <SessionBookingsStatus data={session} />}
        />
        <RowOptions
          entityId={entityId}
          options={options}
          onError={wrappedNotifyError("SessionRowOptions")}
        />
      </ContainerRow>
      {bookedFor.map((friend) => (
        <SessionBookedForRow
          key={friend.id}
          data={friend}
          session={session}
          status={status}
        />
      ))}
    </>
  );
};

export default SessionRow;
