import { DateField, Form, NumberField, Panel, SideBySide, TimeField } from '../../library-components';
import { transformDate, transformTime } from '../../tools/conversions';

const SessionSchedulePanel = ({
  session = {},
  isBusy = false,
  onUpdateField = () => null
}) => {
  const { duration, starts } = session;

  return (
    <Panel title="Schedule">
      <Form>
        <DateField
          label="Date"
          value={starts.toISODate()}
          disabled={isBusy}
          onChange={onUpdateField('starts', transformDate(starts))}
          required
        />
        <SideBySide>
          <TimeField
            label="Time"
            step="600"
            value={starts.toFormat('HH:mm')}
            disabled={isBusy}
            onChange={onUpdateField('starts', transformTime(starts))}
            required
          />
          <NumberField
            label="Duration"
            min={15}
            suffix="mins"
            value={duration}
            disabled={isBusy}
            onChange={onUpdateField('duration')}
            required
          />
        </SideBySide>
      </Form>
    </Panel>
  );
};

export default SessionSchedulePanel;
