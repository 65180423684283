import { DateTime } from "luxon";

export const hydrateClass = (raw) => {
  const hydrated = { ...raw };

  hydrated.createdISO = raw.created;
  hydrated.created = DateTime.fromISO(raw.created);

  hydrated.lastUpdatedISO = raw.lastUpdated;
  hydrated.lastUpdated = DateTime.fromISO(raw.lastUpdated);

  hydrated.startsISO = raw.starts;
  hydrated.starts = DateTime.fromISO(raw.starts);

  hydrated.sortOn = raw.dayOfTheWeek + raw.starts + raw.type;

  return hydrated;
};

export const dehydrateClass = (hydrated) => {
  const raw = { ...hydrated };

  raw.created = hydrated.created.toISO();
  raw.lastUpdated = hydrated.lastUpdated.toISO();
  raw.starts = hydrated.starts.toFormat("HH:mm:ss");

  delete raw.createdISO;
  delete raw.lastUpdatedISO;
  delete raw.startsISO;

  delete raw.sortOn;

  return raw;
};

export const defaultModel = hydrateClass({
  capacity: 20,
  dayOfTheWeek: 1,
  duration: 60,
  cost: 0,
  starts: DateTime.local().plus({ hours: 1 }).startOf("hour"),
});
