import { Page } from '../../library-components';
import { UserPanel, EntitiesPanel } from '../../components';

const Profile = () => (
  <Page>
    <UserPanel />
    <EntitiesPanel />
  </Page>
);

export default Profile;
