import { useState } from "react";
import { FriendType, MaxFriendCount } from "../constants";
import useAppState from "../hooks/useAppState";
import useEntity from "../hooks/useEntity";
import useUpdatableFields from "../hooks/useUpdatableFields";
import useUser from "../hooks/useUser";
import { validateDateOfBirth, validateName } from "../library-common";
import {
  Button,
  DateField,
  Form,
  Panel,
  SideBySide,
  SingleLineField,
} from "../library-components";
import { dehydrateUserProfile } from "../models/user";
import { transformDate } from "../tools/conversions";
import { addFriend } from "../userRequests";
import FriendRow from "./FriendRow";

const FriendList = ({
  friends: friendsParam = {},
  isChild = true,
  onConfigureOptions = () => [],
  onSelectFriend = () => null,
}) => {
  const { isBusy } = useAppState();
  const [errors, setErrors] = useState([]);
  const [friend, updateField] = useUpdatableFields({}, setErrors);
  const { friends: friendsSaved, userId } = useUser();
  const { entityId } = useEntity();

  const friends = { ...friendsSaved, ...friendsParam };
  const friendsList = Object.values(friends);

  const title = isChild
    ? "Previously added Children"
    : "Previously added Friends";

  const buttonLabel = isChild ? "Add Child" : "Add Friend";
  const friendType = isChild ? FriendType.Child : FriendType.Guest;

  const dateOfBirthSubtext = `
    We'll never share this date of birth, we use it to calculate
    age so we know which sessions can be joined.
  `;

  const { name, dateOfBirth } = friend;

  const hasErrors = !name || !dateOfBirth || (errors && errors.length);
  const noUpdates =
    Object.entries(friend).filter(([, val]) => val != null).length === 0;

  const friendCount = friendsList.length;

  return (
    <>
      {friendCount ? (
        <Panel title={title}>
          {friendsList.map((friend) => (
            <FriendRow
              data={friend}
              key={friend.id}
              options={onConfigureOptions(friend)}
            />
          ))}
        </Panel>
      ) : null}
      {friendCount <= MaxFriendCount ? (
        <>
          <Panel title={buttonLabel}>
            <Form>
              <SingleLineField
                label="Name"
                validator={validateName}
                value={name}
                disabled={isBusy}
                onChange={updateField("name")}
                required
              />
              <DateField
                label="Date of Birth"
                validator={validateDateOfBirth}
                value={dateOfBirth && dateOfBirth.toISODate()}
                disabled={isBusy}
                onChange={updateField(
                  "dateOfBirth",
                  transformDate(dateOfBirth)
                )}
                subText={dateOfBirthSubtext}
              />
            </Form>
          </Panel>
          <Panel isBorderless>
            <SideBySide fitContent>
              <Button
                asyncAction={addFriend({
                  details: dehydrateUserProfile(friend),
                  type: friendType,
                })}
                disabled={noUpdates || hasErrors || isBusy}
                entityId={entityId}
                label={buttonLabel}
                userId={userId}
                onComplete={({ resultData: { friend } = {} }) =>
                  onSelectFriend(friend)
                }
                isPrimary
              />
            </SideBySide>
          </Panel>
        </>
      ) : null}
    </>
  );
};

export default FriendList;
