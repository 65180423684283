import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  & > h1 {
    color: ${({ theme }) => theme.colorPrimary};
    font-size: ${({ theme }) => theme.fontSizeLarge};
    font-weight: normal;
  }
`;

const Heading = ({
  children,
  className,
  icon = null,
  onClick = () => null
}) => (
  <Container className={className} id="heading" onClick={onClick}>
    <h1>{children}</h1>
    {icon}
  </Container>
)

export default Heading;
