import { DateTime } from 'luxon';

import { formatCurrency } from '../../library-common';
import { HistoricCutOffDays } from '../../constants';

const hydratePayment = cost => raw => {
    const hydrated = { ...raw };

    hydrated.cost = cost;
    hydrated.costFormatted = formatCurrency(cost);

    hydrated.dueISO = raw.due;
    hydrated.due = DateTime.fromISO(raw.due);

    const { due } = hydrated;
    hydrated.dueFormatted = due.toLocaleString(DateTime.DATE_MED);

    const { outstanding } = hydrated;
    const now = DateTime.local().startOf('day');
    const soon = now.plus({ days: HistoricCutOffDays }).endOf('day');

    hydrated.isDueSoon = outstanding > 0 && due < soon;
    hydrated.isOverdue = outstanding > 0 && due < now;

    const { dueFormatted, isDueSoon, isOverdue } = hydrated;
    const dueWord = isOverdue ? 'Overdue' : (isDueSoon ? 'Due Soon' : 'Due');
    hydrated.dueWord = dueWord;
    hydrated.dueMessage = `${outstanding > 0 ? dueWord : 'Paid'} : ${dueFormatted}`;
    hydrated.outstandingFormatted = formatCurrency(outstanding);
    hydrated.paid = cost - outstanding;
    hydrated.paidFormatted = formatCurrency(cost - outstanding);

    return hydrated;
};

export default hydratePayment;