
const dehydratePayment = hydrated => {
    const raw = { ...hydrated };

    delete raw.cost;
    delete raw.costFormatted;

    const { dueISO } = hydrated;
    if (dueISO) {
        raw.due = dueISO;
        delete raw.dueISO;
        delete raw.dueFormatted;
        delete raw.dueMessage;
        delete raw.dueWord;
    }

    delete raw.isDueSoon;
    delete raw.isOverdue;
    delete raw.outstandingFormatted;
    delete raw.paid;
    delete raw.paidFormatted;

    return raw;
};

export default dehydratePayment;