import { DateTime } from "luxon";
import { useContext } from "react";
import { Roles } from "../constants";
import { calculateAge } from "../library-common";
import { UserContext } from "../providers/userProvider";

const GLOBAL_BALANCE = "_global";

const useUser = () => {
  const userContext = useContext(UserContext);

  if (!userContext) return {};
  const [state] = userContext;

  const { account, dateOfBirth } = state;

  const dtDateOfBirth = dateOfBirth && DateTime.fromISO(dateOfBirth);

  const accountBalances = account.reduce(
    (acc, { entityId, value }) => ({
      ...acc,
      [entityId || GLOBAL_BALANCE]:
        (acc[entityId || GLOBAL_BALANCE] || 0) + value,
    }),
    {}
  );

  const user = {
    ...state,
    age: dtDateOfBirth && calculateAge(dtDateOfBirth),
    balance: accountBalances,
    dateOfBirth: dtDateOfBirth,
  };

  return user;
};

export const hasEntityRole = (user, entityId, role) => {
  const { entityRoles = [] } = user || {};

  return (
    entityId &&
    entityRoles[entityId] &&
    entityRoles[entityId].indexOf(role) >= 0
  );
};

export const hasRole = (user, role) => {
  const { roles = [] } = user || {};

  return roles.indexOf(role) >= 0;
};

export const isUserAdmin = (user, entityId) =>
  hasRole(user, Roles.SystemAdmin) ||
  hasEntityRole(user, entityId, Roles.EntityAdmin);

export default useUser;
