import { Page } from '../../library-components';
import { EntityTitlePanel } from '../../components';
import RegistrationPanel from './RegistrationPanel';
import useNavigation from '../../hooks/useNavigation';
import useUser from '../../hooks/useUser';

const Register = () => {
  const { userId } = useUser();
  const { back } = useNavigation();

  if (userId) back();

  return (
    <Page>
      <EntityTitlePanel />
      <RegistrationPanel />
    </Page>
  );
};

export default Register;
