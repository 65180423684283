import styled, { css } from "styled-components";

import Row from "./Row";

const SpacedRow = styled(Row)(
  ({ theme }) => css`
    & + & {
      border-top: ${theme.borderDefault};
      margin-top: ${theme.marginSmall}px;
      padding-top: ${theme.marginSmall}px;
    }
  `
);

export default SpacedRow;
