import { useState } from 'react';
import { Button, Form, SelectionField, SideBySide, SingleLineField, ToggleField } from '../library-components';
import { registerEntity } from '../userRequests';
import { FeatureSwitches } from '../constants';
import { validateString } from '../library-common';
import useEntity from '../hooks/useEntity';
import useReferenceData from '../hooks/useReferenceData';
import useUser from '../hooks/useUser';
import { RequireFeaturePanel } from '.';

const initalState = {
  country: 'GB'
};

const RegisterEntityPanel = () => {
  const { entityId } = useEntity();
  const { userId } = useUser();
  const [showForm, setShowForm] = useState(false);
  const [entitySettings, setEntitySettings] = useState(initalState);
  const { countries } = useReferenceData();

  const { name, city, country, isDarkTheme } = entitySettings;

  const clearPanel = () => {
    setShowForm(false);
    setEntitySettings(initalState);
  };

  const onUpdateField = field => value => setEntitySettings({
    ...entitySettings,
    [field]: value
  });

  const countryOptions = countries
    .reduce((acc, { cca2, name: { common } }) => ({
      ...acc,
      [cca2]: `${common} (${cca2})`
    }), {});

  return entityId ? null : (
    <RequireFeaturePanel
      feature={FeatureSwitches.RegisterEntity}
      isBorderless={!showForm}
      title={showForm ? `Register your Gym` : null}
    >
      {showForm
        ? <Form>
          <SingleLineField
            label="Name of Gym"
            validator={validateString(4)}
            value={name}
            onChange={onUpdateField('name')}
            required
          />
          <SingleLineField
            label="City"
            validator={validateString(2)}
            value={city}
            onChange={onUpdateField('city')}
            required
          />
          <SelectionField
            label="Country"
            options={countryOptions}
            value={country}
            onChange={onUpdateField('country')}
            required
          />
          <ToggleField
            label="Use Dark Theme"
            value={isDarkTheme}
            onChange={onUpdateField('isDarkTheme')}
            required
          />
          <SideBySide>
            <Button
              asyncAction={registerEntity(entitySettings)}
              label="Register Now"
              onComplete={() => clearPanel()}
              userId={userId}
              isPrimary
            />
          </SideBySide>
        </Form>
        : <Button
          onClick={() => setShowForm(true)}
          label="Register your Gym"
        />}
    </RequireFeaturePanel>
  );
};

export default RegisterEntityPanel;
