import isDeepEqual from "fast-deep-equal/react";
import { getFirestore, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import useAppState from "./useAppState";

const useFirestoreCollection = (
  refProvider,
  hydrator = (data) => data,
  context = "useFirestoreCollection",
  pathMappings
) => {
  const { wrappedNotifyError } = useAppState();
  const [data, setData] = useState([]);
  const pathMappingsRef = useRef(pathMappings);

  const notifyError = useCallback(
    () => wrappedNotifyError(context),
    [wrappedNotifyError, context]
  );

  if (!isDeepEqual(pathMappingsRef.current, pathMappings)) {
    pathMappingsRef.current = pathMappings;
  }

  useEffect(() => {
    if (!refProvider) return () => null;

    const db = getFirestore();

    return onSnapshot(
      refProvider(db),
      ({ docs }) => {
        setData(
          docs.map((doc) => {
            const raw = { ...doc.data(), id: doc.id };

            if (pathMappingsRef.current) {
              const pathParts = doc.ref.path.split("/");
              Object.entries(pathMappingsRef.current).forEach(
                ([fieldName, index]) => {
                  raw[fieldName] = pathParts[index];
                }
              );
            }

            return hydrator(raw);
          })
        );
      },
      notifyError
    );
  }, [hydrator, notifyError, refProvider]);

  return data;
};

export default useFirestoreCollection;
