import { DefaultFieldMessage } from '../constants';
import Paragraph from './Paragraph';
import styled, { css } from 'styled-components';
import { useState } from 'react';

const DescriptionText = styled(Paragraph)(({ theme }) => css`
  color: ${theme.colorDimmed0};
  font-size: ${theme.fontSizeXSmall};
  padding: ${theme.paddingXSmall}px 0;
`);

const MessageText = styled(DescriptionText)(({ theme }) => css`
  color: ${theme.colorPrimary};
`);

const FieldDescription = ({ className, description, message = DefaultFieldMessage }) => {
  const [showMessageText, setShowMessageText] = useState(false);

  const onToggle = () => setShowMessageText(!showMessageText);

  return showMessageText
    ? <DescriptionText className={className} onClick={onToggle}>{description}</DescriptionText>
    : <MessageText className={className} onClick={onToggle}>{message}</MessageText>
};

export default FieldDescription;