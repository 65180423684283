import { Panel } from '../../library-components';

const Settings = () => {

  return (
    <Panel
      isCentered
      isBorderless
    >
      Settings
    </Panel>
  );
};

export default Settings;
