import { DateTime } from 'luxon';

export const transformDate = initialValue => date => DateTime
  .fromObject({
    ...(initialValue ? initialValue.toObject() : {}),
    year: date.year,
    month: date.month,
    day: date.day
  });


export const transformTime = initialValue => time => DateTime
  .fromObject({
    ...(initialValue ? initialValue.toObject() : {}),
    hour: time.hour,
    minute: time.minute
  })
  .startOf('minute');
