import { useContext } from "react";
import { ConfigContext } from "../providers/configProvider";

const useConfig = () => {
  const [state] = useContext(ConfigContext);

  return state.config;
};

export default useConfig;
