import { DateTime, Info } from 'luxon';
import { Form, NumberField, Panel, SelectionField, SideBySide, TimeField } from '../../library-components';

const ClassSchedulePanel = ({
  timetableClass = {},
  isBusy = false,
  onUpdateField = () => null
}) => {
  const { dayOfTheWeek, duration, starts } = timetableClass;

  const transformTime = time => DateTime
    .fromObject({
      ...starts.toObject(),
      hour: time.hour,
      minute: time.minute
    })
    .startOf('minute');

  const daysOfTheWeek = Info.weekdays();

  return (
    <Panel title="Schedule">
      <Form>
        <SelectionField
          label="Day of the Week"
          options={daysOfTheWeek}
          value={dayOfTheWeek}
          disabled={isBusy}
          onChange={onUpdateField('dayOfTheWeek', val => Number(val))}
          required
        />
        <SideBySide>
          <TimeField
            label="Time"
            step="600"
            value={starts.toFormat('HH:mm')}
            disabled={isBusy}
            onChange={onUpdateField('starts', transformTime)}
            required
          />
          <NumberField
            label="Duration"
            min={15}
            suffix="mins"
            value={duration}
            disabled={isBusy}
            onChange={onUpdateField('duration')}
            required
          />
        </SideBySide>
      </Form>
    </Panel>
  );
};

export default ClassSchedulePanel;
