import { FaTimes } from "react-icons/fa";
import styled, { css } from "styled-components";
import useEntity from "../hooks/useEntity";
import useUser from "../hooks/useUser";
import { calculateAge } from "../library-common";
import {
  Icon,
  Row,
  RowCell,
  RowOptions,
  RowStatus,
} from "../library-components";
import { canCancelBooking } from "../models/session";
import { cancelBooking } from "../userRequests";

const ContainerRow = styled(Row)(({ theme, isCancelled }) => {
  const color = isCancelled ? theme.colorDisabled : "";

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const SessionBookedForRow = ({
  data: friend = {},
  session = {},
  status = RowStatus.None,
}) => {
  const { entityId } = useEntity();
  const { userId } = useUser();

  const { dateOfBirth, id: friendId, name } = friend;
  const { id: sessionId } = session;

  const identityPayload = {
    sessionId,
    onBehalfOfId: friendId,
  };

  const options = canCancelBooking(session, friend)
    ? [
        {
          action: cancelBooking(identityPayload),
          icon: <Icon primary={FaTimes} />,
          userId,
        },
      ]
    : [];

  return (
    <ContainerRow id={friendId} isSlim noMargins status={status}>
      <RowCell alignRight pixelWidth={60} secondary="for:" />
      <RowCell
        primary={name}
        warning={dateOfBirth ? `Aged ${calculateAge(dateOfBirth)} years` : null}
      />
      <RowOptions entityId={entityId} options={options} />
    </ContainerRow>
  );
};

export default SessionBookedForRow;
