import commonModel from '../common2';

import dehydratePayment from './dehydratePayment';

const dehydrate = hydrated => {
    const raw = commonModel.dehydrate(hydrated);

    if (hydrated.expiresISO) {
        raw.expires = hydrated.expires.toISO();
        delete raw.expiresISO;
        delete raw.hasExpired;
        delete raw.expiresSoon;
        delete raw.expiresFormatted;
        delete raw.expiresMessage;
    }

    delete raw.durationMessage;

    const { paymentSchedule } = hydrated;
    if (paymentSchedule) {
        raw.paymentSchedule = paymentSchedule.map(dehydratePayment);

        delete raw.overdueAmount;
        delete raw.overdueAmountFormatted;
        delete raw.hasOverduePayments;
    }

    return raw;
};

export default dehydrate;