import { getFirestore, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

import useAppState from "./useAppState";

const useFirestoreDocument = (
  refProvider = () => null,
  hydrator = (data) => data,
  context = "useFirestoreDocument"
) => {
  const { wrappedNotifyError } = useAppState();
  const [data, setData] = useState(null);

  const notifyError = useCallback(
    () => wrappedNotifyError(context),
    [wrappedNotifyError, context]
  );

  useEffect(() => {
    const db = getFirestore();
    const ref = refProvider(db);

    if (!ref) return;

    return onSnapshot(
      ref,
      (doc) => {
        if (!doc.exists) setData(null);

        const data = { ...doc.data(), id: doc.id };
        const hydrated = hydrator(data);

        setData(hydrated);
      },
      notifyError
    );
  }, [hydrator, notifyError, refProvider]);

  return data;
};

export default useFirestoreDocument;
