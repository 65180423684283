import { FaTimes } from "react-icons/fa";
import styled, { css } from "styled-components";
import { IconButton } from "../components";
import { NotificationTypes } from "../constants";
import useAppState from "../hooks/useAppState";
import { Panel, Row, RowCell } from "../library-components";

const colorsFromType = (theme, type) => {
  let colors = {
    background: theme.colorBackground1,
    foreground: theme.color1,
  };

  if (type === NotificationTypes.Warning)
    colors = {
      background: theme.colorWarnBg,
      foreground: theme.colorWarn,
    };
  else if (type === NotificationTypes.Error)
    colors = {
      background: theme.colorErrorBg,
      foreground: theme.colorError,
    };

  return colors;
};

const Container = styled.div(
  ({ theme }) => css`
    background: ${theme.background0};
    display: flex;
    flex-direction: column;
  `
);

const Notification = styled(Panel)(({ theme, type }) => {
  const { background, foreground } = colorsFromType(theme, type);

  return css`
    background-color: ${background};
    color: ${foreground};
    margin-bottom: ${theme.marginSmall}px;
  `;
});

const NotificationIconButton = styled(IconButton)(({ theme, type }) => {
  const { background, foreground } = colorsFromType(theme, type);

  return css`
    background-color: ${background} !important;
    color: ${foreground} !important;
  `;
});

const FailureCodeMessages = {
  card_declined: "Card Declined",
  expired_card: "Card Expired",
  incorrect_cvc: "Incorrect CVC",
  payment_intent_authentication_failure: "Payment Authentication Failed",
  processing_error: "Processing Error",
};

const getFailureCodeMessage = (code) => {
  return FailureCodeMessages[code] || code;
};

const Notifications = () => {
  const { clearNotification, notifications } = useAppState();

  return (
    <Container>
      {notifications.map((notification, key) => {
        const { type, payload } = notification;

        let primaryMessage;
        let secondaryMessage;
        if (payload.error?.message && payload.errorContext) {
          primaryMessage = payload.error.message.message;
          secondaryMessage = "Stripe Error";
        } else if (payload.code && payload.message) {
          primaryMessage = getFailureCodeMessage(payload.code);
          secondaryMessage = payload.message;
        } else if (payload.error && payload.errorContext) {
          primaryMessage = payload.error.message;
          secondaryMessage = payload.errorContext;
        } else if (payload.error) {
          primaryMessage = payload.error;
        } else {
          primaryMessage = payload;
        }

        if (!primaryMessage) console.error(notification);

        return (
          <Notification isMarginless key={key} type={type}>
            <Row onClick={() => console.error(notification)}>
              {primaryMessage ? (
                <RowCell
                  primary={primaryMessage?.toString()}
                  secondary={secondaryMessage?.toString()}
                />
              ) : (
                <RowCell primary={JSON.stringify(notification)} />
              )}
              <NotificationIconButton
                icon={<FaTimes />}
                onClick={() => clearNotification(key)}
                type={type}
              />
            </Row>
          </Notification>
        );
      })}
    </Container>
  );
};

export default Notifications;
