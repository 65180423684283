import { Page } from '../../library-components';
import { SessionAdminInfo, SessionInfo } from '.';

const SessionPage = () => (
  <Page>
    <SessionInfo />
    <SessionAdminInfo />
  </Page>
);

export default SessionPage;
