import { useParams } from "react-router";
import styled, { css } from "styled-components";
import useEntity from "../../hooks/useEntity";
import { formatDate, formatDuration, formatTime } from "../../library-common";
import { Panel, Row, RowCell, Spinner } from "../../library-components";
import { qualificationMessage } from "../../models/common";
import asLookup from "../../tools/asLookup";

const Name = styled.h2(
  ({ theme }) => css`
    color: ${theme.colorPrimary};
  `
);

const Coach = styled.h3(({ theme }) => css``);

const Qualification = styled.p(
  ({ theme }) => css`
    padding: ${theme.paddingDefault}px 0;
    font-size: ${theme.fontSizeSmall};
    color: ${theme.colorSecondary};
  `
);

const SessionInfo = () => {
  const { sessions } = useEntity();
  const { sessionId } = useParams();

  const sessionLookup = asLookup(sessions);
  const session = sessionLookup[sessionId];

  if (!session) return <Spinner />;

  const { coach, duration, starts, name } = session;

  let qualification = qualificationMessage(session);

  return (
    <>
      <Panel title="Session Details">
        <Name>{name}</Name>
        <Coach>{`Coach: ${coach || "TBC"}`}</Coach>
        <Qualification>{qualification}</Qualification>
        <Row>
          <RowCell invert primary={formatDate(starts)} secondary="Date" />
          <RowCell invert primary={formatTime(starts)} secondary="Time" />
          <RowCell
            invert
            primary={formatDuration(duration)}
            secondary="Duration"
          />
        </Row>
      </Panel>
    </>
  );
};

export default SessionInfo;
