import styled, { css } from "styled-components";
import useUser from "../hooks/useUser";
import { formatCurrency } from "../library-common";
import { costForUser as functionCostForUser } from "../models/session";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  justify-content: "space-between";
`;

const StrikeThrough = styled.span(
  ({ theme }) => css`
    color: ${theme.colorDimmed1};
    margin-right: ${theme.marginSmall}px;
    text-decoration: line-through;
  `
);

const SubStatus = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;

    & > span {
      padding-left: ${theme.paddingXSmall}px;
    }
  `
);

const SessionRowCost = ({ data: session = {} }) => {
  const user = useUser();
  const { cost: rrp } = session;

  const costForUser = functionCostForUser(session, user);

  return (
    <Container>
      <SubStatus>
        {costForUser !== rrp ? (
          <StrikeThrough>{formatCurrency(rrp)}</StrikeThrough>
        ) : null}
        {formatCurrency(costForUser, true)}
      </SubStatus>
    </Container>
  );
};

export default SessionRowCost;
