import { useNavigate } from "react-router-dom";

const useNavigation = () => {
  const navigate = useNavigate();

  const doNavigate = (link, search, hash) => {
    const newLink = link || window.location.pathname;
    const newSearch = search || window.location.search;
    const newHash = hash || window.location.hash;

    navigate(newLink + newSearch + newHash);
  };

  return {
    back: () => navigate(-1),
    navigate: doNavigate
  };
};

export default useNavigation;