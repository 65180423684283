import { RowCell, RowStatus, SpacedRow } from "../../library-components";

const MembershipSummaryRow = ({ data }) => {
  const {
    durationMessage,
    expiresMessage,
    expiresSoon,
    hasExpired,
    id,
    isSuspended,
    name,
    suspendedReason,
  } = data;

  const rowStatus =
    isSuspended || hasExpired
      ? RowStatus.Error
      : expiresSoon
      ? RowStatus.Warn
      : RowStatus.Ok;

  const warningMessage = !hasExpired && expiresSoon ? expiresMessage : null;
  let errorMessage = null;

  if (hasExpired) errorMessage = expiresMessage;
  else if (isSuspended) errorMessage = suspendedReason;

  return (
    <SpacedRow key={id} status={rowStatus}>
      <RowCell primary={name} secondary={durationMessage} flexWidth={2} />
      <RowCell error={errorMessage} warning={warningMessage} />
    </SpacedRow>
  );
};

export default MembershipSummaryRow;
