import { FaCalendarPlus, FaPlus } from "react-icons/fa";
import { ActionBar, ClassBrowser } from "../../components";
import useEntity from "../../hooks/useEntity";
import { Page } from "../../library-components";
import { createClassSessions } from "../../userRequests";

const TimetableManager = () => {
  const { classes } = useEntity();

  const actions = [
    {
      label: "Add Class",
      icon: <FaPlus />,
      link: "/timetablemanager/add",
    },
    {
      label: "Publish Sessions",
      icon: <FaCalendarPlus />,
      action: createClassSessions({ isPublished: true }),
    },
  ];

  return (
    <Page renderHeader={() => <ActionBar actions={actions} />}>
      <ClassBrowser classes={classes} allowAdminActions />
    </Page>
  );
};

export default TimetableManager;
