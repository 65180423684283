import { useParams } from 'react-router';

import { Panel } from '../../library-components';
import { BundleAssignmentRow } from '../../components';

import useEntity from '../../hooks/useEntity';
import useUser from '../../hooks/useUser';
import asLookup from '../../tools/asLookup';

import { BundleTypeNames } from '../../constants';

const BundleAssignmentInfo = () => {
  const { bundles, entityId } = useEntity();
  const { bundleId } = useParams();
  const { bundleAssignments } = useUser();

  const bundle = asLookup(bundles)[bundleId];

  const assignments = bundleAssignments
    .filter(assignment => assignment.entityId === entityId)
    .filter(assignment => assignment.bundleId === bundleId);

  const hasAssignments = assignments.length > 0;

  return hasAssignments ? (
    <Panel title={`Your ${BundleTypeNames[bundle.type]}s`}>
      {assignments.map(assignment => <BundleAssignmentRow
        data={assignment}
        key={assignment.id}
        showPayments
      />)}
    </Panel>
  ) : null;
};

export default BundleAssignmentInfo;
