import { NotificationTypes } from "../../constants";
import log from "../../tools/log";

export const Actions = {
  ClearNotification: "clear-notification",
  NotifyError: "notify-error",
  SetIsBusy: "set-isBusy",
  SetIsMenuShown: "set-isMenuShown",
};

export const initialState = {
  isBusy: false,
  isMenuShown: false,
  notifications: [],
};

const reducer = (state, action) => {
  const { type, payload } = action;
  const { notifications } = state;

  // console.log("appState", action);

  switch (type) {
    case Actions.NotifyError:
      const notification = {
        type: NotificationTypes.Error,
        payload,
      };

      return {
        ...state,
        notifications: [...notifications, notification],
      };
    case Actions.ClearNotification:
      return {
        ...state,
        notifications: notifications.filter((_, idx) => idx !== payload),
      };
    case Actions.SetIsBusy:
      const { isBusy, busyMessage } = payload;
      return { ...state, isBusy, busyMessage: isBusy ? busyMessage : null };
    case Actions.SetIsMenuShown:
      return { ...state, isMenuShown: payload };
    default:
      log.warn(`Invalid app state action dispatched`, type);
      return state;
  }
};

export default reducer;
