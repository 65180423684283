import { OrderStatus } from "../../constants";

const canCancelOrder = (order = {}, user = {}) => {
  const canCancel = true;

  if (order.status === OrderStatus.Complete) return false;

  return canCancel;
};

const logic = {
  canCancelOrder,
};

export default logic;
