import { DateTime } from "luxon";
import { DefaultUserAvatarUrl } from "../constants";
import { commonHydration } from "./common";

export const hydrateUserClaims = (raw) => {
  const { email, picture, user_id } = raw || {};

  const hydrated = {
    avatar: picture || DefaultUserAvatarUrl,
    claims: raw,
    email,
    userId: user_id,
  };

  return hydrated;
};

export const hydrateUserProfile = (raw) => {
  const { dateOfBirth } = raw || {};
  const hydrated = commonHydration(raw);

  if (dateOfBirth) {
    hydrated.dateOfBirthISO = dateOfBirth;
    hydrated.dateOfBirth = DateTime.fromISO(dateOfBirth);
  }

  return hydrated;
};

export const hydrateUserOrder = (raw) => {
  const hydrated = commonHydration(raw);

  return hydrated;
};

export const hydrateAccountTransaction = (raw) => {
  const hydrated = commonHydration(raw);

  return hydrated;
};

export const hydrateOnBehalfOf = (raw) => {
  const hydrated = commonHydration(raw);

  hydrated.dateOfBirthISO = raw.dateOfBirth;
  hydrated.dateOfBirth = DateTime.fromISO(raw.dateOfBirth);

  return hydrated;
};

export const hydrateBasketItem = (raw) => {
  const hydrated = commonHydration(raw);

  if (raw.availableUntil) {
    hydrated.availableUntilISO = raw.availableUntil;
    hydrated.availableUntil = DateTime.fromISO(raw.availableUntil);
    hydrated.isAvailable = () => hydrated.availableUntil >= DateTime.utc();
  }

  return hydrated;
};

export const hydrateBundleAssignment = (raw) => {
  const hydrated = commonHydration(raw);

  hydrated.expiresISO = raw.expires;
  hydrated.expires = DateTime.fromISO(raw.expires);

  return hydrated;
};

export const hydrateUserAccess = (raw) => {
  const hydrated = { ...raw };

  hydrated.lastAccessISO = raw.lastAccess;
  hydrated.lastAccess = DateTime.fromISO(raw.lastAccess);

  return hydrated;
};

export const dehydrateUserProfile = (hydrated) => {
  const { dateOfBirth } = hydrated || {};
  const raw = { ...hydrated };

  if (dateOfBirth) {
    delete raw.dateOfBirthISO;
    raw.dateOfBirth = dateOfBirth.toISODate();
  }

  return raw;
};
