import commonModel from "../common2";

const dehydrate = (hydrated) => {
  const raw = commonModel.dehydrate(hydrated);

  delete raw.feesFormatted;
  delete raw.paymentsFormatted;
  delete raw.subTotalFormatted;

  delete raw.isComplete;
  delete raw.isScheduled;
  delete raw.accountingDate;
  delete raw.accountingDateISO;

  return raw;
};

export default dehydrate;
