import { DateTime } from 'luxon';

const LogLevel = {
    Debug: 'debug',
    Info: 'info',
    Warn: 'warn',
    Error: 'error'
}

const record = (level, payload) => {
    const entry = {
        level,
        time: DateTime.utc(),
        payload
    };

    console.log(entry);
};

const log = {
    debug: (...args) => record(LogLevel.Debug, args),
    info: (...args) => record(LogLevel.Info, args),
    warn: (...args) => record(LogLevel.Warn, args),
    error: (...args) => record(LogLevel.Error, args)
};

export default log;