import log from "../../tools/log";

export const Actions = {
  UpdateConfig: "update-config",
};

export const initialState = {
  isInitialised: false,
};

const reducer = (state, action) => {
  const { type, payload } = action;

  // console.log("config", action);

  switch (type) {
    case Actions.UpdateConfig:
      return { ...state, config: payload, isInitialised: true };
    default:
      log.warn(`Invalid config action dispatched`, type);
      return state;
  }
};

export default reducer;
