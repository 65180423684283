import styled, { css } from 'styled-components';
import { Form, NumberField, Row, RowCell, RowStatus } from '../../library-components';
import useEntity from '../../hooks/useEntity';

const ContainerRow = styled(Row)(({ theme, isPublished }) => {

  const color = isPublished ? '' : theme.colorDisabled;

  return css`
    color: ${color};
    justify-content: space-between;

    & div {
      color: ${color};
    }
  `;
});

const MembershipPricingPanel = ({
  value: membershipPricing = {},
  isBusy = false,
  onChange = () => null
}) => {
  const { bundles } = useEntity();

  const onUpdateField = bundleId => val => {
    onChange({
      ...membershipPricing,
      [bundleId]: val
    }, {});
  };

  return (
    <Form>
      {bundles
        .filter(({ isDeleted }) => !isDeleted)
        .map((bundle) => {
          const {
            durationMessage,
            id,
            isPublished,
            name,
            recurranceMessage
          } = bundle;

          const rowStatus = isPublished ? RowStatus.None : RowStatus.Disabled;

          return (
            <ContainerRow
              key={id}
              isPublished={isPublished}
              status={rowStatus}
            >
              <RowCell
                primary={name}
                secondary={`${durationMessage} : ${recurranceMessage}`}
              />
              <RowCell
                pixelWidth={100}
                primary={() => (
                  <NumberField
                    prefix="£"
                    value={membershipPricing[id]}
                    disabled={isBusy}
                    onChange={onUpdateField(id)}
                  />
                )}
              />
            </ContainerRow>
          );
        })}
    </Form>
  );
};

export default MembershipPricingPanel;
