import { FaCreditCard } from "react-icons/fa";
import styled, { css } from "styled-components";
import { PaymentStatus } from "../../constants";
import { useAppState, useEntity, useUser } from "../../hooks";
import { formatCurrency } from "../../library-common";
import {
  Icon,
  Row,
  RowCell,
  RowOptions,
  addOptionBuilder,
} from "../../library-components";
import { takeScheduledPayment } from "../../userRequests";

const StatusRow = styled(Row)(
  ({ status, theme }) => css`
    > div > div {
      color: ${status === PaymentStatus.Failed
        ? theme.colorError
        : theme.colorDimmed} !important;
    }
  `
);
const WarnRowCell = styled(RowCell)(
  ({ theme }) => css`
    color: ${theme.colorWarn};
    font-weight: bold;
  `
);

const OverduePayerRow = ({ data }) => {
  const { id, payments, name, outstanding } = data;
  const { notifyError } = useAppState();
  const { entityId } = useEntity();
  const user = useUser();

  return (
    <>
      <Row>
        <RowCell primary={name} tertiary={id} flexWidth={2} />
        <RowCell primary={payments.length} alignRight />
        <WarnRowCell primary={formatCurrency(outstanding)} alignRight />
      </Row>
      {payments.map((payment, key) => {
        const { bundleAssignmentId, id, payerId, userId: memberId } = payment;

        const options = [];
        const addOption = addOptionBuilder(data, options, user);

        const isAdmin = true; //isUserAdmin(user, entityId);

        addOption(
          () => isAdmin,
          <Icon primary={FaCreditCard} />,
          takeScheduledPayment({ bundleAssignmentId, id, payerId, memberId })
        );

        return (
          <StatusRow key={key} status={payment.status}>
            <RowCell secondary={payment.status} alignRight />
            <RowCell secondary={payment.dueDate} alignRight />
            <RowCell
              secondary={formatCurrency(payment.outstanding)}
              alignRight
            />
            <RowOptions
              entityId={entityId}
              options={options}
              onError={notifyError}
            />
          </StatusRow>
        );
      })}
    </>
  );
};

export default OverduePayerRow;
