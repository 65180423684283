import { FaCalendarCheck } from "react-icons/fa";

import {
  NavigationButton,
  RequireUserPanel,
  SessionList,
} from "../../components";
import { NoContent } from "../../library-components";

import useEntity from "../../hooks/useEntity";
import useUser from "../../hooks/useUser";
import asLookup from "../../tools/asLookup";

import { BookingStatus } from "../../constants";

const startsSorter = (a, b) => a.starts.diff(b.starts);

const UserBookingPanel = () => {
  const { sessions } = useEntity();
  const { bookings } = useUser();

  const sessionLookup = asLookup(sessions);
  const bookedSessions = bookings
    .filter(({ status }) => status === BookingStatus.Confirmed)
    .map(({ sessionId }) => sessionLookup[sessionId])
    .filter((session) => !!session)
    .filter(({ isInPast }) => !isInPast())
    .sort(startsSorter);

  const hasSessions = bookedSessions.length > 0;

  return (
    <>
      <RequireUserPanel isBorderless>
        {hasSessions ? null : (
          <NavigationButton
            icon={<FaCalendarCheck />}
            isPrimary
            label="Book your Sessions"
            link="/sessions"
          />
        )}
      </RequireUserPanel>
      <RequireUserPanel title="Confirmed Bookings">
        {hasSessions ? (
          <SessionList sessions={bookedSessions} showDates />
        ) : (
          <NoContent message="You have no confirmed bookings" />
        )}
        {hasSessions ? (
          <NavigationButton
            icon={<FaCalendarCheck />}
            isBorderless
            label="Add Sessions"
            link="/sessions"
          />
        ) : null}
      </RequireUserPanel>
    </>
  );
};

export default UserBookingPanel;
