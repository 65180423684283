import { useState } from "react";
import { FaEllipsisV } from "react-icons/fa";
import styled, { css } from "styled-components";
import useNavigation from "../hooks/useNavigation";
import { Icon } from "../library-components";
import Button from "./Button";
import { statusColor } from "./Row";

const Container = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 0 !important;
  position: relative;
`;

const ButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    position: absolute;
    z-index: 1;
  `
);

const RowOptionsButton = styled(Button)(({ theme, status }) => {
  const backgroundColor = statusColor(theme, status);

  return css`
    border: none;
    border-radius: 0;
    background: ${backgroundColor || theme.background1};
    height: ${theme.buttonSizeDefault}px;
    padding: 0;
    width: ${theme.buttonSizeDefault}px;

    & svg {
      margin-left: 0;
    }

    & + & {
      border-left: solid 1px ${theme.background2};
    }
  `;
});

const RowOptionsBlank = styled(RowOptionsButton)(
  ({ theme }) => css`
    background: none;
  `
);

export const addOptionBuilder =
  (data, options, userOrFunc) =>
  (conditionFunc, icon, action, link, status) => {
    const { navigate } = useNavigation();

    const user = typeof userOrFunc === "function" ? userOrFunc() : userOrFunc;
    const { userId } = user;

    if (userId && conditionFunc(data, user)) {
      options.push({
        icon,
        action,
        onClick: () => navigate(link),
        status,
        userId,
      });
    }
  };

const RowOptions = ({ entityId, options = [], onError = () => null }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expandOptions = () => {
    setIsExpanded(!isExpanded);
  };

  const mapButton = ({ action, icon, onClick, status, userId }, key) => (
    <RowOptionsButton
      asyncAction={action}
      entityId={entityId}
      key={key}
      icon={icon}
      onClick={onClick}
      onComplete={() => setIsExpanded(false)}
      onError={onError}
      status={status}
      userId={userId}
    />
  );

  const allOptions = options.map(mapButton);

  const primaryButton = options.length ? (
    options.length > 1 ? (
      <>
        {isExpanded ? allOptions : null}
        <RowOptionsButton
          icon={<Icon primary={FaEllipsisV} />}
          onClick={expandOptions}
        />
      </>
    ) : (
      mapButton(options[0])
    )
  ) : null;

  return (
    <Container>
      <RowOptionsBlank />
      <ButtonContainer>{primaryButton}</ButtonContainer>
    </Container>
  );
};

export default RowOptions;
