import { DateTime } from "luxon";

import { OrderStatus } from "../../constants";
import { formatCurrency } from "../../library-common";
import commonModel from "../common2";

const hydrate = (raw) => {
  const hydrated = commonModel.hydrate(raw);

  const { fees, payments, scheduled, status, subTotal } = raw;
  const now = DateTime.local();

  if (scheduled) {
    hydrated.scheduledISO = scheduled;
    hydrated.scheduled = DateTime.fromISO(scheduled);
  }

  if (fees) hydrated.feesFormatted = formatCurrency(fees);
  if (subTotal) hydrated.subTotalFormatted = formatCurrency(subTotal);
  if (payments) hydrated.paymentsFormatted = formatCurrency(payments);

  hydrated.isComplete = status === OrderStatus.Complete;
  hydrated.isScheduled =
    status === OrderStatus.Scheduled && hydrated.scheduled > now;
  hydrated.isOverdue = hydrated.scheduled < now && payments < subTotal;

  const { isScheduled } = hydrated;
  hydrated.accountingDate = isScheduled
    ? hydrated.scheduled
    : hydrated.lastUpdated || hydrated.created;

  const { accountingDate } = hydrated;
  if (accountingDate) {
    hydrated.accountingDateISO = accountingDate.toISODate();
  }

  return hydrated;
};

export default hydrate;
