import styled from 'styled-components';
import SingleLineField, { RawFieldType } from './SingleLineField';

const Container = styled(SingleLineField)`
  input[type=number] {
    width: 100%;
  }
`;

const NumberField = (props) => {
  const { onChange } = props;

  const onNumberChanged = (val, result) => {
    return onChange(val.length > 0 ? Number(val) : null, result);
  };

  return (
    <Container
      {...props}
      rawType={RawFieldType.Number}
      onChange={onNumberChanged}
    />
  );
};

export default NumberField;
