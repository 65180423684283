import { FaCalendarCheck, FaShoppingCart } from "react-icons/fa";
import styled, { css } from "styled-components";
import { NavigationButton } from "../../components";
import { useBasket } from "../../hooks";
import { Page, Panel } from "../../library-components";
import { BasketItemList } from "./BasketItemList";
import { BasketTotals } from "./BasketTotals";
import { OrderList } from "./OrderList";
import { PaymentMethods } from "./PaymentMethods";

const Centered = styled.div(
  ({ theme }) => css`
    padding: ${theme.paddingDefault}px;
    text-align: center;
  `
);

export const Basket = () => {
  const basket = useBasket();

  const { basketItems = [], incompleteOrders = [] } = basket;

  return (
    <Page>
      <Centered>
        <FaShoppingCart size="40px" />
        <h3>Your basket</h3>
      </Centered>
      {basketItems.length ? (
        <Panel title="In your basket...">
          <BasketItemList items={basketItems} />
        </Panel>
      ) : null}
      {incompleteOrders.length ? (
        <Panel title="Outstanding orders...">
          <OrderList orders={incompleteOrders} />
        </Panel>
      ) : null}
      <Panel isBorderless>
        <NavigationButton
          icon={<FaCalendarCheck />}
          label="Add Sessions"
          link="/sessions"
        />
      </Panel>
      <BasketTotals />
      <PaymentMethods />
    </Page>
  );
};
