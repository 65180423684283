import { FaShoppingCart } from "react-icons/fa";
import {
  EntityPicker,
  HasEntity,
  MembershipInfo,
  NavigationButton,
  NoUserPanel,
  RegisterEntityPanel,
} from "../../components";
import { Page, Panel } from "../../library-components";

import UserAccountPanel from "./UserAccountPanel";
import UserBookingHistoryPanel from "./UserBookingHistoryPanel";
import UserBookingPanel from "./UserBookingPanel";
import UserSummaryPanel from "./UserSummaryPanel";

import { useBasket } from "../../hooks";

const Home = () => {
  const { basketItems, incompleteOrders } = useBasket();

  const itemsInBasket = basketItems.length + incompleteOrders.length;

  return (
    <Page>
      <UserSummaryPanel />
      <EntityPicker />
      <RegisterEntityPanel />
      <HasEntity>
        {itemsInBasket > 0 && (
          <Panel isBorderless>
            <NavigationButton
              icon={<FaShoppingCart />}
              isWarning
              label={`You have ${itemsInBasket} unpaid items in your basket`}
              link="/basket"
            />
          </Panel>
        )}
        <NoUserPanel />
        <MembershipInfo />
        <UserBookingPanel />
        <UserAccountPanel />
        <UserBookingHistoryPanel />
      </HasEntity>
    </Page>
  );
};

export default Home;
