import { DateTime } from "luxon";

import { formatCurrency } from "../../library-common";

import { HistoricCutOffDays } from "../../constants";
import commonModel from "../common2";
import hydratePayment from "./hydratePayment";

const hydrate = (raw) => {
  const hydrated = commonModel.hydrate(raw);

  const now = DateTime.local().startOf("day");
  const soon = now.plus({ days: HistoricCutOffDays }).endOf("day");

  if (raw.expires) {
    hydrated.expiresISO = raw.expires;
    hydrated.expires = DateTime.fromISO(raw.expires);

    const { expires } = hydrated;
    hydrated.expiresSoon = expires < soon;
    hydrated.hasExpired = expires < now;
    hydrated.expiresFormatted = expires.toLocaleString(DateTime.DATE_FULL);

    const { expiresFormatted, hasExpired } = hydrated;
    hydrated.expiresMessage = `Expire${
      hasExpired ? "d" : "s"
    } ${expiresFormatted}`;
  }

  const { duration, durationUnit } = hydrated;
  const durationUnitMessage =
    duration > 1 ? durationUnit : durationUnit.slice(0, -1);
  hydrated.durationMessage = `${duration} ${durationUnitMessage}`;

  const { costEach } = raw;
  if (raw.paymentSchedule) {
    const paymentSchedule = raw.paymentSchedule.map(hydratePayment(costEach));

    hydrated.paymentSchedule = paymentSchedule;
    hydrated.overdueAmount = paymentSchedule.reduce((acc, payment) => {
      const { isOverdue, outstanding } = payment;
      const overdueAmount = isOverdue ? outstanding : 0;
      return acc + overdueAmount;
    }, 0);

    const { overdueAmount } = hydrated;
    hydrated.hasOverduePayments = overdueAmount > 0;
    hydrated.overdueAmountFormatted = formatCurrency(overdueAmount);
  }

  return hydrated;
};

export default hydrate;
