import styled, { css } from "styled-components";
import { useEntity, useUsers } from "../../hooks";
import { formatCurrency } from "../../library-common";
import { Panel, Row, RowCell } from "../../library-components";
import asLookup from "../../tools/asLookup";
import OverduePayerRow from "./OverduePayerRow";

const HeaderRow = styled(Row)`
  margin-bottom: 10px;
`;

const WarnRowCell = styled(RowCell)(
  ({ theme }) => css`
    color: ${theme.colorError};
    font-weight: bold;
  `
);

const nameSorter = ([, a], [, b]) =>
  a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase());

const OverdueScheduledPayments = ({ data }) => {
  const { entityId } = useEntity();
  const users = asLookup(useUsers(entityId));

  const breakdown = data.reduce(
    (acc, payment) => {
      const { payers, paymentCount, total } = acc;

      const name = users[payment.payerId]
        ? users[payment.payerId].name
        : "Unknown";

      const payer = payers[payment.payerId] || {
        outstanding: 0,
        payments: [],
      };
      const outstanding = payer.outstanding + payment.outstanding;

      return {
        payers: {
          ...payers,
          [payment.payerId]: {
            name,
            id: payment.payerId,
            outstanding,
            payments: [...payer.payments, payment],
          },
        },
        paymentCount: paymentCount + 1,
        total: total + payment.outstanding,
      };
    },
    { payers: {}, paymentCount: 0, total: 0 }
  );

  const payerCount = Object.keys(breakdown.payers).length;

  return (
    <Panel title={`Overdue Membership Payments`}>
      <HeaderRow>
        <RowCell primary={payerCount} secondary="Payers" alignRight invert />
        <RowCell
          primary={breakdown.paymentCount}
          secondary="Payment Count"
          invert
          alignRight
        />
        <WarnRowCell
          primary={formatCurrency(breakdown.total)}
          secondary="Total"
          invert
          alignRight
        />
      </HeaderRow>
      {Object.entries(breakdown.payers)
        .sort(nameSorter)
        .map(([key, payer]) => (
          <OverduePayerRow data={payer} key={key} />
        ))}
    </Panel>
  );
};

export default OverdueScheduledPayments;
