import styled, { css } from 'styled-components';

const Container = styled.div(({ theme }) => css`
  color: ${theme.color0};
  display: flex;
  font-size: ${theme.fontSizeSmall};

  * + * {
    margin-left: ${theme.marginSmall}px;
  }
`);

const Label = styled.label(({ theme }) => css`
  color: ${theme.color1};

  ::after {
    content: ':';
  }
`);

const Value = styled.span`
`;

const Prefix = styled.span`
`;

const Suffix = styled(Prefix)`
`;

const Detail = ({
  formatter = val => val.toString(),
  label,
  value,
  prefix,
  suffix
}) => (
  <Container>
    {label && <Label>{label}</Label>}
    {prefix && <Prefix>{prefix}</Prefix>}
    {value && <Value>{formatter(value)}</Value>}
    {suffix && <Suffix>{suffix}</Suffix>}
  </Container>
);

export default Detail;
