import commonModel from "../common2";

const dehydrate = (hydrated) => {
  const raw = commonModel.dehydrate(hydrated);

  delete raw.age;
  delete raw.ageMessage;
  delete raw.birthday;
  delete raw.dateOfBirth;
  raw.dateOfBirth = hydrated.dateOfBirthISO;
  delete raw.dateOfBirthISO;

  raw.lastRequestTime = hydrated.lastRequestTimeISO;
  delete raw.lastRequestTimeISO;
  delete raw.lastSeenMessage;

  return raw;
};

export default dehydrate;
