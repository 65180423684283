import { RequireUserPanel } from '.';
import useUser, { hasRole } from '../hooks/useUser';

const RequireFeaturePanel = (props) => {
  const { children, feature } = props;
  const user = useUser();

  const shouldRender = hasRole(user, feature);

  return shouldRender ? <RequireUserPanel {...props}>{children}</RequireUserPanel> : null;
};

export default RequireFeaturePanel;
