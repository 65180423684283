const validateString = (minLength = 1, maxLength) => (val = '') => {
  const trimmed = val.trim();

  const errors = [];

  if (trimmed.length < minLength) {
    errors.push(`Please enter more characters, this field needs to be at least ${minLength} characters long.`);
  }
  if (maxLength && trimmed.length > maxLength) {
    errors.push(`Please enter fewer characters, this field needs to be fewer than ${maxLength} characters long.`);
  }

  return { errors };
};

export default validateString;
