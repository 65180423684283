import { Info } from "luxon";
import { useState } from "react";
import { ClassRow, FullPageError } from ".";
import { NoContent, Panel } from "../library-components";

const ClassBrowser = ({
  allowAdminActions = false,
  classes: allClasses = [],
}) => {
  const [error, setError] = useState();

  const { name: errorType, message: errorMessage } = error || {};

  const errorContent = error ? (
    <FullPageError
      onCancel={() => setError()}
      message={errorMessage}
      type={errorType}
    />
  ) : null;

  const days = allClasses.reduce(
    (acc, c) => ({
      ...acc,
      [c.dayOfTheWeek]: [...(acc[c.dayOfTheWeek] || []), c],
    }),
    {}
  );

  const weekdays = Info.weekdays();

  return allClasses.length > 0 ? (
    Object.entries(days).map(([day, classes]) => (
      <Panel
        key={day}
        isBorderless
        isCentered
        title={weekdays[day]}
        isCollapsable
      >
        {errorContent}
        {classes.map((timetableClass) => {
          const { id } = timetableClass;

          return (
            <ClassRow
              allowAdminActions={allowAdminActions}
              data={timetableClass}
              key={id}
            />
          );
        })}
      </Panel>
    ))
  ) : (
    <NoContent message="There aren't any classes to view." />
  );
};

export default ClassBrowser;
