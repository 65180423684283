import { Avatar, Image } from '../library-components';
import {
  DefaultEntityAvatarUrl,
  DefaultEntityLogoUrl,
  DefaultEntityLogoUrlAlt
} from '../constants';
import useEntity from '../hooks/useEntity';

const EntityLogo = ({
  entity,
  isAvatar = false,
  isRounded = false
}) => {
  const { current } = useEntity();

  const {
    settings: {
      isDarkTheme = false,
      logoUrl
    } = {}
  } = entity || current || {};

  const defaultLogoUrl = isAvatar
    ? DefaultEntityAvatarUrl
    : (isDarkTheme
      ? DefaultEntityLogoUrlAlt
      : DefaultEntityLogoUrl
    );

  return isAvatar
    ? <Avatar bgSrc={logoUrl || defaultLogoUrl} />
    : <Image bgSrc={logoUrl || defaultLogoUrl} />
}

export default EntityLogo;
