import { ThemeProvider as RawThemeProvider } from 'styled-components';
import useEntity from '../hooks/useEntity';

const defaultTheme = {
  buttonSizeXSmall: 20,
  buttonSizeSmall: 30,
  buttonSizeDefault: 50,
  colorOk: '#3c0',
  colorNeutral: '#09f',
  colorWarn: '#f90',
  colorError: '#f06',
  colorPrimary: '#6ca61f',
  colorPrimaryInverse: '#fff',
  colorPrimaryLight: '#9cd64f',
  colorSecondary: '#cc368f',
  colorSecondaryInverse: '#fff',
  colorSecondaryLight: '#dc469f',
  fontSizeXSmall: '0.6em',
  fontSizeSmall: '0.7em',
  fontSizeDefault: '0.9em',
  fontSizeLarge: '1.1em',
  fontSizeXLarge: '1.25em',
  iconAvatar: 40,
  iconAvatarMedium: 60,
  iconAvatarLarge: 100,
  iconTiny: 30,
  iconSmall: 75,
  marginXSmall: 3,
  marginSmall: 5,
  marginDefault: 10,
  marginLarge: 20,
  marginXLarge: 40,
  maxWidth: 600,
  paddingXSmall: 2,
  paddingSmall: 5,
  paddingDefault: 10,
  paddingLarge: 20,
  paddingXLarge: 40,
  radiusSmall: 2,
  radiusDefault: 6,
  radiusAvatar: 20,
  radiusAvatarMedium: 30,
  radiusAvatarLarge: 50,
  slimRowHeight: 37
};

const darkTheme = {
  background0: '#222',
  background0light: '#2a2a2a',
  background1: '#333',
  background1light: '#3a3a3a',
  background2: '#444',
  background2light: '#4a4a4a',
  borderDefault: 'solid 1px #2c2c2c',
  color0: '#aaa',
  color1: '#ccc',
  color2: '#eee',
  colorDimmed0: '#999',
  colorDimmed1: '#777',
  colorDimmed2: '#555',
  colorMask: 'rgba(0,0,0,0.8)',
  colorDisabled: '#444',
  colorOkBg: '#003311',
  colorNeutralBg: '#110033',
  colorWarnBg: '#332e00',
  colorErrorBg: '#331100'
};

const lightTheme = {
  background0: '#fff',
  background0light: '#f8f8f8',
  background1: '#eee',
  background1light: '#e8e8e8',
  background2: '#ddd',
  background2light: '#d8d8d8',
  borderDefault: 'solid 1px #eee',
  color0: '#666',
  color1: '#333',
  color2: '#111',
  colorDimmed0: '#999',
  colorDimmed1: '#ccc',
  colorDimmed2: '#eee',
  colorMask: 'rgba(0,0,0,0.7)',
  colorDisabled: '#ddd',
  colorOkBg: '#f0ffee',
  colorNeutralBg: '#eef8ff',
  colorWarnBg: '#fff8ee',
  colorErrorBg: '#ffeef0'
};

const ThemeProvider = ({ children }) => {
  const { current } = useEntity();
  const { settings } = current || {};

  const {
    isDarkTheme = false,
    theme: entityTheme = {}
  } = settings || {};

  const theme = {
    ...defaultTheme,
    ...(isDarkTheme ? darkTheme : lightTheme),
    ...entityTheme
  };

  return (
    <RawThemeProvider theme={theme}>
      {children}
    </RawThemeProvider>
  );
};

export default ThemeProvider;
